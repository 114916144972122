import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import styles from './style.scss'
import ToastHelper from 'general/helpers/ToastHelper';
import BaseTextField from 'general/custom-fields/BaseTextField';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import CheckBox from 'components/CheckBox';
import ModalAddMember from 'pages/GroupManager/components/ModalAddMember';
import groupApi from "api/groupApi";
import classnames from "classnames/bind";
import { identity } from 'lodash';
import SelectArea from "components/SelectArea/SelectArea";
import areaApi from "api/areaApi";
import { setAllAreas } from "../../../AreaManager/areaSlice";
const cx = classnames.bind(styles);
ModalManageGroup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    group: PropTypes.object,
    getGroups: PropTypes.func,
};

ModalManageGroup.defaultProps = {
    show: false,
    onClose: null,
    group: null,
    getGroups: null,
};

function ModalManageGroup(props) {
    // MARK: --- Params ---
    const { show, onShow, onClose, group, getGroups } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [modalAddMemberShowing, setModalAddMemberShowing] = useState(false);
    const [editAccount, setEditAccount] = useState()
    const [editProgram, setEditProgram] = useState()
    const [acceptProgram, setAcceptProgram] = useState()
    const [editSpeaker, setEditSpeaker] = useState()
    const [broadcast, setBroadcast] = useState()
    const [name, setName] = useState()
    const [users, setUsers] = useState([])
    const [memberIds, setMemberIds] = useState([])
    const [myId, setMyId] = useState([])
    const [newGroup, setNewGroup] = useState()
    // Area
    const [areasByUser, setAreasByUser] = useState([]);
    const [currentArea, setCurrentArea] = useState();
    const [selectedArea, setSelectedArea] = useState();
    const [currentAreaId, setCurrentAreaId] = useState();
    const currentUser = useSelector((state) => state.auth.current);
    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }
    function handleShow() {
        if (onShow) {
            onShow();
        }
    }
    function handleSubmit() {
        if(name != null && name != "" && memberIds.length > 0){
            const data = {
                id:group.id,
                name: name,
                areaId: selectedArea.AreaId,
                editProgram: editProgram,
                acceptProgram: acceptProgram,
                editSpeaker: editSpeaker,
                broadcast: broadcast,
                editAccount: editAccount, 
                memberIds: memberIds}
            groupApi.editGroup(data).then(res => {  
                const { result } = res.data;
                if (result == "success") {
                    ToastHelper.showSuccess(t('EditGroupSuccessfully'));
                    handleClose();
                    getGroups();
                }
            })
        }else{
            ToastHelper.showError(t('PleaseFillAllFields'));
        }
    }

    function handleDeleteMember(id) {
        const newMemberIds = memberIds.filter(memberId => memberId != id)
        setMemberIds(newMemberIds)
    }

    function getUserList(){
        groupApi.getUserList().then(res => {
            const { result, users } = res.data;
            if (result == "success") {
                setUsers(res?.data?.accounts);
            }
        })
    }
    function getMe(){
        groupApi.getMe().then(res => {
            const { result} = res.data;
            if(result == "success"){
                setMyId(res?.data.user);
            }
        })
    }
    
    async function getAreas() {
        const res = await areaApi.getAreas();
        const { result, areas } = res.data;
        if (result == "success" && areas) {
            setAreasByUser(areas);
            dispatch(setAllAreas(areas));
        }
    }
    // MARK: --- Hooks ---
    useEffect(() => {
        setEditAccount(group?.editAccount);
        setEditProgram(group?.editProgram);
        setAcceptProgram(group?.acceptProgram);
        setEditSpeaker(group?.editSpeaker);
        setBroadcast(group?.broadcast);
        setName(group?.name);
        setNewGroup(group?.members);
    },[group])
    
    useEffect(() => {
        getUserList();
        getMe();
        getAreas();
    } ,[])
    useEffect(()=>{
        setMemberIds(group?.members.map(item => item.id))
        setSelectedArea(group?.area);
    },[show])
    useEffect(()=>{
        if(newGroup != null){
            const leftovers = newGroup.filter(item => memberIds?.includes(item.id))
            const addIds = memberIds.filter(item => !leftovers.map(member=>member.id).includes(item))
            setNewGroup(leftovers.concat(users.filter(item => addIds.includes(item.id))))
        }
    },[memberIds])

    /// Area
    useEffect(() => {
        let areaId = selectedArea
            ? selectedArea.AreaId
            : currentUser
            ? currentUser.areaId
            : null;
        if (areaId) {
            setCurrentAreaId(areaId);
        }
        }, [selectedArea, currentUser.areaId]);
    useEffect(() => {
    if (!currentArea) {
        setCurrentArea(
        areasByUser.filter((area) => area.id == currentUser?.areaId).at(0)
        );
    }
    }, [areasByUser]);

    // useEffect(()=>{console.log(selectedArea)},[selectedArea])
    
    return (
        <div >
            <Modal
                scrollable={true}
                style={{zIndex: '1059 !important', position: 'center'}}
                size="xl"
                className='font-weight-bold'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex justify-content-center'>
                    <Modal.Title>
                        <p className="Bold_13">
                            {t("ManageGroupsOfUser")}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-12 col-lg-6 d-flex flex-column p-0 border-0 bg-white'>
                            <div className='' style={{margin:'0 5px', border: '1px solid #DBE3EF'}}>
                                <div className='Bold_13 bg-white d-flex align-items-center px-6 py-5' style={{borderBottom: '1px solid #DBE3EF'}}>
                                    <span className='mr-5'>
                                        {t("Member")}
                                    </span> 
                                    <i className="fas fa-circle mr-5"  style={{fontSize: 5, color: "#4A5677"}}></i>
                                    <span style={{color:'#5180FB', textTransform:'lowercase'}}>
                                        {memberIds?.length >0 ?memberIds?.length : 1} {t("Member")}
                                    </span>
                                </div>
                                <div className='p-2' style={{background:'#F6F7FB'}}>
                                    <div style={{background:'white', border:'1px solid #DBE3EF'}}>
                                        <div style={{maxHeight: 304, overflowY: "auto"}}>
                                            {newGroup?.map((item, index) => (
                                                <div className='w-100 px-4 py-3' style={{borderBottom: index < newGroup.length - 1 ? '1px solid #DBE3EF' : ""}}>
                                                    {item.username}
                                                    {(item.id !== myId?.id) &&(item.id !== group?.admin.id) && 
                                                        (<i className="fa fa-trash-alt cursor-pointer text-hover-danger"
                                                        style={{float:'right'}}
                                                        onClick={() => {
                                                            handleDeleteMember(item.id) 
                                                        }}
                                                        ></i>)
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white d-flex justify-content-center' style={{height:'48px'}}>
                                    <a 
                                        className='btn btn-primary font-weight-bold'
                                        style={{width:'90%', margin:'auto'}} 
                                        onClick={() => { 
                                            setModalAddMemberShowing(true);
                                        }}
                                    >
                                        {t("AddMember")}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 d-flex flex-column p-0 border-0 bg-white mt-6 mt-lg-0'>
                            <div className='' style={{margin:'0 5px', border: '1px solid #DBE3EF'}}>
                                <div className='Bold_13 bg-white m-0 px-6 py-5' style={{borderBottom: "1px solid #DBE3EF"}}>
                                    {t("ManageGroup")}
                                </div>
                                <div className='p-2' style={{background:'#F6F7FB'}}>
                                    <div style={{background:'white', border: '1px solid #DBE3EF'}}>
                                        <p className='mb-1 mx-4 mt-3' style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#9EA5BD',
                                        }}>{t("GroupName")}</p>
                                        <div className="mx-4">
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder= {name}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                        </div>

                                        <p className='mb-1 mx-4 mt-3' style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#9EA5BD',
                                        }}>{t("Area")}</p>
                                        <SelectArea
                                            className="mx-4 mb-3"
                                            onSelect={(area) => {
                                            console.log("Selected area", area);
                                            setSelectedArea(area);
                                        }}
                                        rootAreaId={currentUser?.areaId}
                                        selectedArea={selectedArea?{...selectedArea,AreaName:selectedArea?.AreaName??selectedArea?.name,AreaId:selectedArea?.AreaId??selectedArea?.id}:undefined}
                                        />
                                    </div>
                                    <div style={{background:'white', border: '1px solid #DBE3EF', marginTop:'10px'}}>
                                        <div className='Regular_13 d-flex flex-column justify-content-between mt-2 mb-2'>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox 
                                                    className={`mr-3 ${myId?.broadcast? '' :cx('checkbox-disabled')}`}
                                                    checked={broadcast}
                                                    onCheck={() => {setBroadcast(true)} }
                                                    onUncheck={() => {setBroadcast(false)} }
                                                />
                                                <div className="">{t("broadcast")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className={`mr-3 ${myId?.editSpeaker? '' :cx('checkbox-disabled')}`}
                                                    checked={editSpeaker}
                                                    onCheck={() => {setEditSpeaker(true)} }
                                                    onUncheck={() => {setEditSpeaker(false)} }
                                                />
                                                <div className="">{t("editSpeaker")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className={`mr-3 ${myId?.editProgram? '' :cx('checkbox-disabled')}`}
                                                    checked={editProgram}
                                                    onCheck={() => {setEditProgram(true)} }
                                                    onUncheck={() => {setEditProgram(false)} }
                                                />
                                                <div className="">{t("editProgram")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className={`mr-3 ${myId?.editAccount? '' :cx('checkbox-disabled')}`}
                                                    checked={editAccount}
                                                    onCheck={() => {setEditAccount(true)}}
                                                    onUncheck={() => {setEditAccount(false)}}
                                                />
                                                <div className="">{t("editAccount")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className={`mr-3 ${myId?.acceptProgram? '' :cx('checkbox-disabled')}`}
                                                    checked={acceptProgram}
                                                    onCheck={() => {setAcceptProgram(true)}}
                                                    onUncheck={() => {setAcceptProgram(false)}}
                                                />
                                                <div className="">{t("acceptProgram")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-light  font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        <div className='col-6'>
                            <button
                                className= {`w-100 btn font-weight-bold ${cx("save")}`}
                                onClick={() => { handleSubmit(); handleClose() }}
                            >
                                {t("Save_")}
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalAddMember
                show={modalAddMemberShowing}
                users={users}
                startMemberIds = {memberIds}
                returnMembers={setMemberIds}
                myId={myId?.id}
                adminId ={group?.admin.id}
                onClose={() => {
                    setModalAddMemberShowing(false);
                }}/>
        </div>
    );
}

export default ModalManageGroup;