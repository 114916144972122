import axiosClient from "./axiosClient";
import qs from 'qs';

const prefix = "bulletins";
const bulletinApi = {
  getCategories: () => {
    const url = prefix + `/categories`;
    return axiosClient.get(url);
  },

  getDigitalDevices() {
    const url = prefix + `/digital-devices`;
    return axiosClient.get(url);
  },

  getRelaySources() {
    const url = prefix + `/relay-sources`;
    return axiosClient.get(url);
  },

  create(data) {
    const url = prefix + `/`;
    return axiosClient.post(url, data);
  },

  /**
   * @param {Number} bulletinId
   * @param {1|2} approve 1: approve, 2: reject
   * @param {string} reason
   * @returns {Promise<AxiosResponse<any>>}
   */
  approve(bulletinId, approve, reason = null) {
    const url = prefix + `/approve`;
    return axiosClient.put(url, {
      bulletinId,
      approve,
      reason,
    });
  },

  getEBulletinData(id) {
    const url = prefix + `/${id}`;
    return axiosClient.get(url);
  },

  getBulletins(areaId, params) {
    return axiosClient.get(prefix, {
      params: {
        areaId,
        ...params
      }
    });
  },

  getPlayingBulletins(areaId, params) {
    const url = prefix + `/history`;
    return axiosClient.get(url, {
      params: {
        types: ["PLAYING"],
        areaId,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    });
  },

  getPlayedBulletins(areaId, params) {
    const url = prefix + `/history`;
    return axiosClient.get(url, {
      params: {
        types: ["PLAYED", "STOPPED"],
        areaId,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    });
  },

  deleteBulletins(bulletinIds) {
    return axiosClient.delete(prefix, {
      data: {
        bulletins: bulletinIds,
      }
    });
  },

  stopBulletins(bulletinIds) {
    const url = prefix + `/stop`;
    return axiosClient.put(url, {
      bulletins: bulletinIds,
    });
  },

  stopPlayingBulletins(bulletinIds) {
    const url = prefix + `/stop-playing`;
    return axiosClient.put(url, {
      ids: bulletinIds,
    });
  },

  deleteHistoryBulletins(bulletinHistoryIds) {
    const url = prefix + `/history`;
    return axiosClient.delete(url, {
      data: {
        historiesId: bulletinHistoryIds,
      }
    });
  },

  getTTSVoices() {
    const url = prefix + `/voice-tts`;
    return axiosClient.get(url);
  },

  convertTTS(text, voiceId) {
    const url = `/tts/synthesis`;
    return axiosClient.post(url, {
      text,
      voiceId,
    });
  },

  getListenUrl(ids = []) {
    const url = prefix + `/listen`;
    return axiosClient.post(url, {
      ids,
    });
  },

  getHistory(historyId) {
    const url = prefix + `/history/${historyId}`;
    return axiosClient.get(url);
  },
};

export default bulletinApi;
