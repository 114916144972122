import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {formatBoardBulletinFileObj, formatDuration, formatFileObj} from "../../../../general/utils/Utils";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import AudioPlayer from "../AudioPlayer";
import ConfirmModal from "../ConfirmModal";
import trashIcon from "../../../../assets/images/trash-round-bg.svg";
import { AppIcons } from "general/constants/AppResource";

const PrimaryButton = styled.label`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;

export default function ChooseSourceVideo({
  show = true,
  disableAll = false,
  videos,
  onVideoChange,
}) {
  const { t } = useTranslation();

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const inputRef = useRef();
  const videoRef = useRef();

  const onInputChange = (e) => {
    if (e.target.files && e.target.files.length) {
      let newVideos = [];
      console.log(e.target.files);
      for (const file of Array.from(e.target.files)) {
        newVideos.push(formatBoardBulletinFileObj(file));
      }
      onVideoChange([...newVideos]);
    }
    e.target.value = "";
  };

  const handleChangeVideo = (e) => {
    inputRef.current.click();
  };

  const handlePlayVideo = (e) => {
    videoRef.current.play();
    console.log("check");
  };

  const togglePreviewModal = () => {
    if (showPreviewModal) {
      setPreviewFile(null);
    }
    setShowPreviewModal(!showPreviewModal);
  };
  const removeFileModalRef = useRef();

  return (
    <div
      style={{
        background: "#F6F7FB",
        border: "1px solid #DBE3EF",
        display: show ? "" : "none",
      }}
    >
      <div className="w-100 border-bottom" style={{ padding: 10 }}>
        {/* {videos.length < 1 && ( */}
        <div
          style={{
            padding: 6,
            background: "white",
            border: "1px solid #DBE3EF",
            borderRadius: 6,
            ...(videos.length < 1 ? {} : { display: "none" }),
          }}
        >
          <PrimaryButton
            disabled={disableAll}
            style={{
              opacity: disableAll ? 0.8 : 1,
              cursor: disableAll ? "default" : "pointer",
            }}
          >
            <i className={"fas fa-file-upload"} />
            {t("BulletinUploadFile")}
            <input
              type="file"
              accept="video/mp4,video/x-m4v,video/*"
              ref={inputRef}
              className="d-none"
              // multiple={true}
              onChange={async (e) => {
                onInputChange(e);
              }}
              disabled={disableAll}
            />
          </PrimaryButton>
          <span
            className="Regular_13"
            style={{
              marginLeft: 12,
            }}
          >
            Chưa có video được chọn
          </span>
        </div>
        {/* )} */}

        {videos.length > 0 && (
          <div className="col-12 " style={{ padding: "4px" }}>
            <video
              src={videos[0].src}
              className="m-0"
              width="100%"
              height="auto"
              controls
              ref={videoRef}
            />
          </div>
        )}
      </div>
      {videos.length > 0 && (
        <div
          className="d-flex col-12 justify-content-center align-items-center pb-5 flex-sm-row flex-column"
          // style={{ height: "40px" }}
        >
          <div className="d-flex justify-content-center align-items-center mt-5 mx-2">
            <button
              className=" d-flex flex-row align-items-center shadow-sm playButton px-4 mr-4"
              style={{
                gap: "8px",
                height: "36px",
                background: "#5180FB",
                borderRadius: "6px",
                borderColor: "#3465E5",
              }}
              onClick={() => {
                handlePlayVideo();
                console.log("123");
              }}
            >
              <i className="far fa-play-circle"></i>
              <p className="Bold_13 " style={{ color: "white" }}>
                Xem Ngay
              </p>
            </button>
            <button
              className=" d-flex flex-row align-items-center shadow-sm playButton px-5 "
              style={{
                gap: "8px",
                height: "36px",
                background: "white",
                borderRadius: "6px",
                borderColor: "#DBE3EF",
              }}
              onClick={() => {
                handleChangeVideo();
              }}
            >
              <i className="far fa-edit" style={{ color: "#4A5677" }}></i>
              <p className="Bold_13 " style={{ color: "#4A5677" }}>
                Thay Video Khác
              </p>
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5 mx-2">
            <button
              className=" d-flex flex-row align-items-center shadow-sm playButton px-4 "
              style={{
                gap: "8px",
                height: "36px",
                background: "white",
                borderRadius: "6px",
                borderColor: "#DBE3EF",
              }}
              onClick={() => {
                onVideoChange([]);
              }}
            >
              <i className="far fa-trash-alt" style={{ color: "#E64135" }}></i>
              <p className="Bold_13 " style={{ color: "#E64135" }}>
                Xóa
              </p>
            </button>
            <img src={AppIcons.icSperator} className="mx-4" />
            <i
              className="far fa-volume fa-2x mr-2"
              style={{ color: "#5180FB" }}
            ></i>
            <input
              type="range"
              className=""
              min="0"
              max="100"
              step="1"
              style={{ color: "red" }}
              // value={inputVolumeValue}
              // onChange={(e) => {
              //   setInputVolumeValue(e.target.value);
              //   deviceApi.setVolume({
              //     deviceIds: [device.deviceId],
              //     volume: e.target.value,
              //   });
              // }}
              id="inputVolume"
            />
          </div>
        </div>
      )}
      {/* <Modal
        style={{ maxWidth: 500 }}
        centered={true}
        isOpen={showPreviewModal}
        toggle={togglePreviewModal}
      >
        <ModalBody className="p-0">
          <div
            className="text-center font-weight-boldest text-uppercase border-bottom text-truncate"
            style={{ padding: 20 }}
            title={previewFile?.file?.name}
          >
            {previewFile?.file?.name}
          </div>
          <div style={{ padding: 20 }} className="border-bottom">
            <AudioPlayer url={previewFile ? previewFile?.src : null} />
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: "10px 20px" }}>
          <button
            onClick={togglePreviewModal}
            style={{ height: 40 }}
            className="btn btn-secondary border w-100 m-0"
          >
            Hủy
          </button>
        </ModalFooter>
      </Modal>

      <ConfirmModal
        ref={removeFileModalRef}
        confirmButtonClass={"btn btn-danger"}
        confirmButtonText={"Xác Nhận"}
      /> */}
    </div>
  );
}
