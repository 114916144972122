import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import AreaManagerHomeScreen from "./screens/AreaManagerHomeScreen";

function AreaManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={AreaManagerHomeScreen} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default AreaManager;
