import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";
import {
  buildFormData,
  buildURL,
  FILE_OBJ_LOCAL_ATTRIBUTES,
  findAreaById,
  formatArea,
  formatBoardBulletinFileObj,
  formatDuration,
  formatFileObj,
  getFileDuration,
  useQuery,
} from "general/utils/Utils";
import styled from "styled-components";
import AppPage from "components/AppPage";
import AppCard from "components/AppCard";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import BaseTextField from "../../../../general/custom-fields/BaseTextField";
import ChooseSourceTypeButton from "../../components/ChooseSourceTypeButton";
import {
  BULLETIN_BOARD_SOURCE_TYPES,
  BULLETIN_PLAY_MODE,
  BULLETIN_PRIORITY,
  BULLETIN_REPEAT_TYPE,
} from "../../../../general/constants/Types";
import CurvedTab from "../../components/CurvedTab";
import moment from "moment";
import TimePicker from "../../components/TimePicker";
import _ from "lodash";
import ChooseSource from "../../components/ChooseSource";
import DatePicker from "../../components/DatePicker";
import WeekdayPicker from "../../components/WeekdayPicker";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import { bulletinGetConfig } from "../../bulletinSlice";
import bulletinApi from "../../../../api/bulletinApi";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../../components/Loading";
import ToastHelper from "../../../../general/helpers/ToastHelper";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import ReactPlayer from "react-player";
import { SelectSort } from "../../../Category/components/SelectSort";
import BulletinBoardPicker from "../../components/BulletinBoardPicker";
import bulletinBoardApi from "../../../../api/bulletinBoardApi";

const PrimaryButton = styled.button`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;

  &.btn-success {
    background: #3ace5a !important;
    border: 1px solid #2ab448 !important;
  }

  &.btn-danger {
    background: #e64135 !important;
    border: 1px solid #cd1f13 !important;
  }

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
  }
`;

const AttributeRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;

  @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
    flex-direction: column;
    align-items: initial;
  }
`;

const AttributeTitle = styled.div`
  width: 140px;
  max-width: 40%;
  margin-right: 12px;
  text-align: right;

  @media (min-width: 1200px) and (max-width: 1920px), (max-width: 576px) {
    width: initial;
    max-width: none;
    text-align: initial;
    margin-bottom: 5px;
  }
`;

const AttributeInput = styled.div`
  flex: 1;
`;

BulletinBoardScreen.propTypes = {};

let playTimeDefault = () => ({
  startTime: moment().startOf("minute"),
  endTime: moment().startOf("minute"),
});

function BulletinBoardScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current);
  const [categoryId, setCategoryId] = useState();
  // MARK: --- Params ---
  const categories = useSelector((state) => {
    if (!categoryId && state.bulletin.categories.length) {
      setCategoryId(state.bulletin.categories[0].id);
    }
    return _.map(state.bulletin.categories, function (category) {
      return {
        ...category,
        display: category.name,
      };
    });
  });
  const allArea = useSelector((state) => state.area.allAreas);
  const canAcceptProgram = useSelector(
    (state) => !!state.auth?.current?.acceptProgram
  );
  const canEditProgram = useSelector(
    (state) => !!state.auth?.current?.editProgram
  );
  const routeParams = useParams();
  const history = useHistory();
  const query = useQuery();
  const [eBulletin, setEBulletin] = useState(
    props.location?.state?.eBulletinData
  );
  const [showLoading, setShowLoading] = useState(false);

  const disableAll = query.get("history_id");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [sourceType, setSourceType] = useState(
    BULLETIN_BOARD_SOURCE_TYPES.find((v) => v.active)?.type
  );
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState();
  // play sources
  // array of mp3 files
  const [files, setFiles] = useState([]);

  // array of images
  const [images, setImages] = useState([]);
  //123
  // video
  const [videos, setVideos] = useState([]);

  //text
  const [text, setText] = useState([]);

  // array of record files
  const [recordFiles, setRecordFiles] = useState([]);
  //  AUX device id
  const [device, setDevice] = useState();
  // tts doc content
  const [document, setDocument] = useState();
  const [documentVoice, setDocumentVoice] = useState();
  const [ttsData, setTtsData] = useState();
  const [relaySource, setRelaySource] = useState({
    id: null,
    type: "",
    url: "",
    frequency: "",
  });

  const [activeTab, setActiveTab] = useState(0);
  const [playTime, setPlayTime] = useState([playTimeDefault()]);
  const [mode, setMode] = useState(
    _.get(_.find(BULLETIN_PLAY_MODE, "default"), "value", "")
  );
  const [repeatType, setRepeatType] = useState(
    _.get(_.find(BULLETIN_REPEAT_TYPE, "default"), "value", "")
  );
  // ngay phat trong thang
  const [playingDay, setPlayingDay] = useState([]);
  // ngay phat trong tuan
  const [playingWeekdays, setPlayingWeekdays] = useState([]);
  // khu vuc phat
  const [area, setArea] = useState();
  // const [radioStation, setRadioStation] = useState();
  // loa phat
  const [speakers, setSpeakers] = useState([]);

  const [priority, setPriority] = useState(
    _.get(_.find(BULLETIN_PRIORITY, "default"), "value", "")
  );
  const [approveDate, setApproveDate] = useState();
  const [id, setID] = useState();
  const [creator, setCreator] = useState();

  // url nghe trực tiếp / nghe lại
  const [session, setSession] = useState();
  const [sessionURL, setSessionURL] = useState();
  const [isPlayingSession, setIsPlayingSession] = useState(false);
  const [sessionURLPlaying, setSessionURLPlaying] = useState(false);

  // reject
  const [rejectReasonModalShow, setRejectReasonModalShow] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const listSourceButtonRef = useRef();

  // MARK: --- Functions ---
  const toggleRejectReasonModal = () => {
    setRejectReasonModalShow(!rejectReasonModalShow);
  };

  const onAddPlayTime = () => {
    setPlayTime([...playTime, playTimeDefault()]);
  };

  // create, update bulletin
  const createBulletin = async (autoApprove = true) => {
    setShowLoading(true);
    let bulletinData = {
      title,
      description: summary,
      type: sourceType,
      startDate,
      endDate,
      mode,
      playTime,
      priority,
      repeatType,
      playingDay,
      playingWeekdays,
      categoryId,
      areaId: area?.AreaId,
      // radioStationId: radioStation?.id,
      deviceIds: _.map(speakers, "deviceId"),
      textContent: text,
    };
    if (eBulletin) {
      // update
      bulletinData.id = eBulletin.id;
    } else {
      // create
      bulletinData.autoApprove = !!autoApprove;
    }
    if (sourceType === "IMAGE") {
      bulletinData.files = images.reduce(function (fileArr, fileObj) {
        if (fileObj.file && fileObj.file instanceof File) {
          fileArr.push(fileObj.file);
        }
        return fileArr;
      }, []);
      bulletinData.filesData = _.map(images, function (entry) {
        return _.omit(entry, FILE_OBJ_LOCAL_ATTRIBUTES);
      });
    } else if (sourceType === "VIDEO") {
      bulletinData.files = videos.reduce(function (fileArr, fileObj) {
        if (fileObj.file && fileObj.file instanceof File) {
          fileArr.push(fileObj.file);
        }
        return fileArr;
      }, []);
      bulletinData.filesData = _.map(videos, function (entry) {
        return _.omit(entry, FILE_OBJ_LOCAL_ATTRIBUTES);
      });
    }

    let bulletinFormData = new FormData();
    buildFormData(bulletinFormData, bulletinData);

    let createdBulletin = await bulletinBoardApi.createBulletin(
      bulletinFormData
    );

    setShowLoading(false);
    if (createdBulletin?.data?.bulletin?.id) {
      ToastHelper.showSuccess(
        routeParams.eBulletinId
          ? "Cập nhật bản tin thành công"
          : "Tạo bản tin thành công"
      );
      if (!routeParams.eBulletinId)
        history.push(
          `/bulletins/edit/bulletin-board/${createdBulletin?.data?.bulletin?.id}`,
          {
            eBulletinData: createdBulletin?.data?.bulletin,
          }
        );
      else setEBulletin(createdBulletin?.data?.bulletin);
    }
  };

  const approveBulletin = async (approve = true) => {
    if (!canAcceptProgram) return;
    let response;
    if (approve) {
      response = await bulletinBoardApi.approve(eBulletin?.id, 1);
    } else {
      setRejectReasonModalShow(false);
      response = await bulletinBoardApi.approve(eBulletin?.id, 2, rejectReason);
    }
    if (response.data.result === "success") {
      ToastHelper.showSuccess(
        `${approve ? "Phê duyệt" : "Từ chối"} bản tin thành công`
      );
      if (response.data.bulletin) {
        setEBulletin(response.data.bulletin);
      }
    }
  };

  // MARK: --- Hooks ---
  useEffect(() => {
    // assing rows when flexbox or grid  wraps into a new line
    const assignRows = (listButtonWrapper) => {
      let row = 0;
      // console.dir(cards.children);
      [...listButtonWrapper.children].forEach((el) => {
        // remove classes
		    el.classList.remove("mt-3");
        // if element is more to the left that it's previous element it means it wrapped to a new line
        if (!el.previousElementSibling || el.offsetLeft < el.previousElementSibling.offsetLeft) {
          row++;
        }
        // adds a class of row1, row2, row3
        el.classList.add(`${row > 1 ? 'mt-3' : 'not-wrapped'}`);
      });
    };

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        assignRows(entry.target);
      });
    });

    const listButtonWrapper = listSourceButtonRef.current;
    observer.observe(listButtonWrapper);
    assignRows(listButtonWrapper);
  },[])

  useEffect(() => {
    window.history.replaceState(null, "");
    dispatch(bulletinGetConfig());
  }, []);

  useEffect(async () => {
    // get bullet data
    let eBulletinId = routeParams.eBulletinId;
    if (!eBulletinId || eBulletinId == eBulletin?.id) {
      return;
    }
    setShowLoading(true);
    let bulletinResponse = await bulletinBoardApi.getEBulletinData(eBulletinId);
    setShowLoading(false);
    if (
      bulletinResponse?.data?.result === "success" &&
      bulletinResponse?.data?.bulletin
    ) {
      setEBulletin(bulletinResponse?.data?.bulletin);
    }
  }, [routeParams.eBulletinId]);

  useEffect(async () => {
    // assign bulletin data to state,
    if (eBulletin) {
      let sourceType = eBulletin.type;
      if (eBulletin.type === "IMAGE") {
        setImages(eBulletin.content.images.map(formatBoardBulletinFileObj));
      } else if (eBulletin.type === "VIDEO") {
        setVideos(eBulletin.content.videos.map(formatBoardBulletinFileObj));
      } else {
        setText(eBulletin.content?.text);
      }
      let endDate = moment(eBulletin.endDate);

      setTitle(eBulletin.title);
      setSummary(eBulletin.description);
      if (sourceType) setSourceType(sourceType);
      setStartDate(moment(eBulletin.startDate));
      if (endDate.isValid()) setEndDate(endDate);

      if (
        Array.isArray(eBulletin.startTime) &&
        Array.isArray(eBulletin.endTime)
      ) {
        let playTimeArr = [];
        eBulletin.startTime.map(function (value, i) {
          let startTime = moment(`2022-01-01 ${value}`),
            endTime = moment(`2022-01-01 ${eBulletin.endTime?.[i]}`);
          playTimeArr.push({
            startTime,
            endTime,
          });
        });
        setPlayTime(playTimeArr);
      }
      setRepeatType(eBulletin.repeatType);
      setMode(eBulletin.mode);
      if (Array.isArray(eBulletin.playingDay)) {
        if (eBulletin.repeatType === "Month") {
          setPlayingDay(
            eBulletin.playingDay.map((date) => moment(`2022-01-${date}`))
          );
        } else if (eBulletin.repeatType === "Week") {
          setPlayingWeekdays(eBulletin.playingDay);
        }
      }
      setCategoryId(eBulletin.categoryId);
      setArea(formatArea(eBulletin.area));
      setSpeakers(eBulletin.devices);
      setPriority(eBulletin.priority);
      let approveDate = moment(eBulletin.approveDate);
      if (approveDate.isValid()) {
        setApproveDate(approveDate);
      }
      setID(eBulletin.id);
      setCreator(eBulletin.creator);

      if (disableAll) {
        let bulletinListenResponse = await bulletinApi.getListenUrl([
          eBulletin.id,
        ]);
        if (bulletinListenResponse?.data?.result === "success") {
          setSessionURL(bulletinListenResponse?.data?.data?.[0]?.link);
          setIsPlayingSession(
            !!bulletinListenResponse?.data?.data?.[0]?.status
          );

          let bulletinHistoryResponse = await bulletinApi.getHistory(
            query.get("history_id")
          );
          setSession(bulletinHistoryResponse?.data?.data?.bulletinHistory);
        }
      }
    }
  }, [eBulletin]);

  useCustomCompareEffect(
    async () => {
      if (eBulletin?.id) return;
      let totalDuration = 0;
      if (sourceType === "FILE") {
        totalDuration = _.sumBy(files, "playDuration") || 0;
      } else if (sourceType === "MIC") {
        totalDuration = _.sumBy(recordFiles, "playDuration") || 0;
      } else if (sourceType === "DOCUMENT") {
        if (ttsData && ttsData.audioContent) {
          ({ duration: totalDuration } = await getFileDuration(
            buildURL(ttsData.audioContent)
          ));
        }
      }
      if (!totalDuration || totalDuration < 0) return;
      setPlayTime(
        [...playTime].map(function ({ startTime, endTime }) {
          return {
            startTime,
            endTime: startTime.clone().add(totalDuration, "seconds"),
          };
        })
      );
    },
    [playTime, sourceType, files, recordFiles, ttsData],
    (prevDeps, nextDeps) => _.isEqual(prevDeps, nextDeps)
  );

  useEffect(() => {
    if (!area && allArea.length && currentUser.areaId) {
      let rootArea = findAreaById(currentUser.areaId);
      if (rootArea) {
        setArea(rootArea);
      }
    }
  }, [allArea, currentUser.areaId]);

  useEffect(() => {
    if (mode === "SCHEDULE") {
      if (!["Week", "Month"].includes(repeatType)) {
        setRepeatType(eBulletin?.repeatType || "Month");
      }
    } else if (mode === "FIX") {
      setRepeatType("Day");
    } else {
      setRepeatType("None");
    }
  }, [mode]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(255,255,255,0.5)",
          zIndex: 9999,
          display: showLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
      <AppPage
        backgroundImage={AppImages.imgBackground6}
        headerTitle={t("BulletinManager")}
      >
        <NavBar navBarTitle={t("BulletinManager")}></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={
              disableAll
                ? "Thông Tin Bản Tin bảng tin điện tử"
                : routeParams.eBulletinId
                ? "Cập nhật bản tin bảng tin điện tử"
                : "Bản tin bảng điện tử"
            }
            headerRight={
              !disableAll && (
                <div className="">
                  {(routeParams.eBulletinId || canAcceptProgram) && (
                    <PrimaryButton
                      className="btn btn-primary"
                      onClick={createBulletin}
                    >
                      <i className="far fa-upload mr-2" />
                      {routeParams.eBulletinId ? "Cập nhật" : "Phát bản tin"}
                    </PrimaryButton>
                  )}
                  {!routeParams.eBulletinId && (
                    <PrimaryButton
                      className="btn btn-success"
                      onClick={() => createBulletin(false)}
                      style={{ marginLeft: 16 }}
                    >
                      <i className="far fa-bookmark mr-2" />
                      Lưu Lại
                    </PrimaryButton>
                  )}
                  {canAcceptProgram &&
                    !!routeParams.eBulletinId &&
                    eBulletin?.status === "WAITING" && (
                      <>
                        <PrimaryButton
                          className="btn btn-success"
                          onClick={() => approveBulletin(true)}
                          style={{ marginLeft: 16 }}
                        >
                          <i className="far fa-check mr-2" />
                          Phê Duyệt
                        </PrimaryButton>
                        <PrimaryButton
                          className="btn btn-danger"
                          onClick={toggleRejectReasonModal}
                          style={{ marginLeft: 16 }}
                        >
                          <i className="far fa-times mr-2" />
                          Từ Chối
                        </PrimaryButton>
                      </>
                    )}
                </div>
              )
            }
            buttonText={""}
          >
            <div style={{ background: "#F6F7FB", padding: 10, paddingTop: 0 }}>
              <div className="row">
                <div className="col-12 col-xl-8" style={{ marginTop: 10 }}>
                  <div
                    style={{
                      border: "1px solid #DBE3EF",
                      padding: "20px 10px 10px",
                      background: "white",
                    }}
                  >
                    <BaseTextField
                      className={"Regular_13 mb-3"}
                      name={"title"}
                      label={t("BulletinTitle")}
                      placeholder={t("InsertBulletinTitle")}
                      fieldProps={{
                        value: title,
                        onChange: (e) => setTitle(e.target.value),
                      }}
                      disabled={disableAll}
                    />
                    <BaseTextField
                      className={"Regular_13 mb-3"}
                      name={"title"}
                      label={t("BulletinSummary")}
                      placeholder={t("InsertBulletinSummary")}
                      multiLine={true}
                      fieldProps={{
                        rows: 4,
                        value: summary,
                        onChange: (e) => setSummary(e.target.value),
                      }}
                      disabled={disableAll}
                    />
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        background: "#E4E6EF",
                        margin: "12px 0 10px",
                      }}
                    ></div>
                    <div ref={listSourceButtonRef}>
                      <span className="Regular_13" style={{ marginRight: 12 }}>
                        {t("BulletinChooseSource")}
                      </span>
                      {BULLETIN_BOARD_SOURCE_TYPES.map(
                        ({ type, title, icon }) => (
                          <ChooseSourceTypeButton
                            key={type}
                            title={
                              <span>
                                <i className={`far fa-${icon} mr-2`} />
                                {t(title)}
                              </span>
                            }
                            active={type === sourceType}
                            btnProps={{
                              onClick: () => {
                                setSourceType(type);
                              },
                              disabled: disableAll,
                            }}
                          />
                        )
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: 1,
                        background: "#E4E6EF",
                        margin: "12px 0 10px",
                      }}
                    />
                    <ChooseSource
                      sourceType={sourceType}
                      disableAll={disableAll}
                      // source file
                      files={files}
                      onFilesChange={setFiles}
                      // source mic
                      recordFiles={recordFiles}
                      onRecordFilesChange={setRecordFiles}
                      // source device
                      device={device}
                      onDeviceChange={setDevice}
                      // source document
                      document={document}
                      onDocumentChange={setDocument}
                      documentVoice={documentVoice}
                      onDocumentVoiceChange={setDocumentVoice}
                      ttsData={ttsData}
                      onTTSDataChange={setTtsData}
                      // source relay
                      relaySource={relaySource}
                      onRelaySourceChange={setRelaySource}
                      // source image
                      images={images}
                      onImageChange={setImages}
                      // source image
                      videos={videos}
                      onVideoChange={setVideos}
                      text={text}
                      onTextChange={setText}
                    />
                  </div>

                  {sessionURL && (
                    <div
                      className="d-sm-flex justify-content-between align-items-center"
                      style={{
                        padding: 10,
                        border: "1px solid #DBE3EF",
                        borderTop: "none",
                      }}
                    >
                      {!sessionURLPlaying ? (
                        <PrimaryButton
                          onClick={() => {
                            setSessionURLPlaying(true);
                          }}
                        >
                          <i className="fas fa-play-circle" />
                          {isPlayingSession
                            ? "Nghe trực tiếp"
                            : "Nghe Lại Phiên Phát"}
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton
                          onClick={() => {
                            setSessionURLPlaying(false);
                          }}
                          className="btn btn-danger"
                        >
                          <i className="fas fa-pause-circle" />
                          Dừng
                        </PrimaryButton>
                      )}
                      <div style={{ margin: "8px 0" }}>
                        Tổng thời lượng:{" "}
                        <span style={{ color: "#5180FB" }}>
                          {formatDuration(session?.duration)}
                        </span>
                      </div>
                      <ReactPlayer
                        url={sessionURL}
                        playing={sessionURLPlaying}
                        style={{ display: "none" }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-12 col-xl-4 Regular_13" style={{ marginTop: 10 }}>
                  <CurvedTab
                    titles={[t("BulletinGeneralInfo"), t("Area")]}
                    activeIndex={activeTab}
                    onChangeTab={(newTabIndex) => setActiveTab(newTabIndex)}
                  />
                  <div
                    className={`bg-white ${activeTab !== 0 ? "d-none" : ""}`}
                    style={{
                      border: "1px solid #DBE3EF",
                      borderTop: "none",
                    }}
                  >
                    {/*thong tin co ban*/}
                    <div
                      style={{
                        padding: 10,
                        paddingBottom: 0,
                      }}
                    >
                      <div>
                        {playTime.map(({ startTime, endTime }, index) => (
                          <div
                            className="border"
                            key={index}
                            style={{
                              marginTop: 10,
                            }}
                          >
                            <div
                              className="border-bottom text-primary text-center position-relative"
                              style={{
                                padding: "12px 8px",
                              }}
                            >
                              THỜI ĐIỂM PHÁT {index + 1}
                              {playTime.length > 1 && (
                                <button
                                  onClick={() => {
                                    let currentPlayTime = [...playTime];
                                    currentPlayTime.splice(index, 1);
                                    setPlayTime(currentPlayTime);
                                  }}
                                  className="btn btn-danger p-0"
                                  style={{
                                    width: 30,
                                    height: 30,
                                    position: "absolute",
                                    right: 20,
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                  disabled={disableAll}
                                >
                                  <i className="far fa-trash-alt m-0 p-0" />
                                </button>
                              )}
                            </div>
                            <AttributeRow>
                              <AttributeTitle>Thời điểm bắt đầu</AttributeTitle>
                              <AttributeInput>
                                <TimePicker
                                  className="w-100 text-success"
                                  time={startTime}
                                  disableAll={disableAll}
                                  onChangeTime={(time) => {
                                    let currentPlayTime = [...playTime];
                                    let currentRange = currentPlayTime[index];
                                    currentRange.startTime = time;
                                    setPlayTime(currentPlayTime);
                                  }}
                                />
                              </AttributeInput>
                            </AttributeRow>
                            <AttributeRow>
                              <AttributeTitle>
                                Thời điểm kết thúc
                              </AttributeTitle>
                              <AttributeInput>
                                <TimePicker
                                  className="w-100 text-danger"
                                  time={endTime}
                                  disableAll={disableAll}
                                  onChangeTime={(time) => {
                                    let currentPlayTime = [...playTime];
                                    let currentRange = currentPlayTime[index];
                                    currentRange.endTime = time;
                                    setPlayTime(currentPlayTime);
                                  }}
                                />
                              </AttributeInput>
                            </AttributeRow>
                          </div>
                        ))}
                        <div
                          className="border border-top-0"
                          style={{
                            background: "#F6F7FB",
                            padding: "8px 20px",
                            marginBottom: 10,
                          }}
                        >
                          <PrimaryButton
                            className="w-100 text-truncate"
                            style={{
                              height: 40,
                            }}
                            disabled={disableAll}
                            onClick={onAddPlayTime}
                          >
                            Thêm Thời Điểm Phát
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                    <div className="border-top">
                      <AttributeRow>
                        <AttributeTitle>Ngày bắt đầu</AttributeTitle>
                        <AttributeInput>
                          <DatePicker
                            show={true}
                            className="text-success"
                            dates={[startDate]}
                            disableAll={disableAll}
                            onChangeDates={(dates) => {
                              if (dates.length) {
                                setStartDate(dates[0]);
                              } else {
                              }
                            }}
                            singleDatePicker={true}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Ngày kết thúc</AttributeTitle>
                        <AttributeInput>
                          <DatePicker
                            show={true}
                            className="text-danger"
                            dates={endDate ? [endDate] : []}
                            disableAll={disableAll}
                            onChangeDates={(dates) => {
                              if (dates.length) {
                                setEndDate(dates[0]);
                              } else {
                              }
                            }}
                            singleDatePicker={true}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Chế độ phát</AttributeTitle>
                        <AttributeInput>
                          <AttributeInput>
                            <SelectSort
                              valueBtnStyle={{ width: "100%" }}
                              selections={BULLETIN_PLAY_MODE}
                              currentValue={
                                _.find(BULLETIN_PLAY_MODE, { value: mode })
                                  ?.display
                              }
                              onSelect={(item) => {
                                setMode(item.value);
                              }}
                              disable={disableAll}
                            />
                          </AttributeInput>
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Lịch phát</AttributeTitle>
                        <AttributeInput>
                          <SelectSort
                            valueBtnStyle={{ width: "100%" }}
                            selections={
                              mode
                                ? _.filter(
                                    BULLETIN_REPEAT_TYPE,
                                    (bulletinRepeatType) =>
                                      bulletinRepeatType.modes.includes(mode)
                                  )
                                : BULLETIN_REPEAT_TYPE
                            }
                            currentValue={
                              _.find(BULLETIN_REPEAT_TYPE, {
                                value: repeatType,
                              })?.display
                            }
                            onSelect={(item) => {
                              setRepeatType(item.value);
                            }}
                            disable={disableAll}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow
                        className={`${
                          ["Week", "Month"].includes(repeatType) ? "" : "d-none"
                        }`}
                      >
                        <AttributeTitle>Danh sách ngày phát</AttributeTitle>
                        <AttributeInput className={"text-truncate"}>
                          <DatePicker
                            show={repeatType === "Month"}
                            dates={playingDay}
                            onChangeDates={setPlayingDay}
                            singleDatePicker={false}
                            pickDateMonth={true}
                            disableAll={disableAll}
                          />
                          <WeekdayPicker
                            show={repeatType === "Week"}
                            weekDays={playingWeekdays}
                            onWeekdayChange={setPlayingWeekdays}
                            disableAll={disableAll}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Lĩnh vực phát</AttributeTitle>
                        <AttributeInput>
                          <SelectSort
                            valueBtnStyle={{ width: "100%" }}
                            selections={categories}
                            currentValue={
                              _.find(categories, { id: categoryId })?.name ||
                              "Chọn"
                            }
                            onSelect={(item) => {
                              setCategoryId(item.id);
                            }}
                            disable={disableAll}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Độ ưu tiên</AttributeTitle>
                        <AttributeInput>
                          <SelectSort
                            valueBtnStyle={{ width: "100%" }}
                            selections={BULLETIN_PRIORITY}
                            currentValue={
                              _.find(BULLETIN_PRIORITY, { value: priority })
                                ?.display
                            }
                            onSelect={(item) => {
                              setPriority(item.value);
                            }}
                            disable={disableAll}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Ngày xuất bản</AttributeTitle>
                        <AttributeInput>
                          <input
                            className="form-control"
                            disabled={true}
                            value={
                              eBulletin?.status !== "REJECTED" && approveDate
                                ? approveDate.format("DD/MM/YYYY HH:mm")
                                : "--/--/----"
                            }
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>ID</AttributeTitle>
                        <AttributeInput>
                          <input
                            className="form-control"
                            disabled={true}
                            defaultValue={id}
                          />
                        </AttributeInput>
                      </AttributeRow>
                      <AttributeRow>
                        <AttributeTitle>Tác Giả</AttributeTitle>
                        <AttributeInput>
                          <input
                            className="form-control"
                            disabled={true}
                            defaultValue={creator}
                          />
                        </AttributeInput>
                      </AttributeRow>
                    </div>
                  </div>
                  <div
                    className={`${activeTab !== 1 ? "d-none" : ""}`}
                    style={{
                      border: "1px solid #DBE3EF",
                      borderTop: "none",
                    }}
                  >
                    {/*khu vuc phat*/}
                    <div
                      className="bg-white border-bottom"
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      <BulletinBoardPicker
                        area={area}
                        onAreaChange={setArea}
                        // radioStation={radioStation}
                        // onRadioStationChange={setRadioStation}
                        speakers={speakers}
                        onSpeakersChange={(speakerList) => {
                          setSpeakers(_.sortBy(speakerList, "areaId"));
                        }}
                        disableAll={disableAll}
                      />
                    </div>
                    <div
                      style={{
                        padding: 10,
                      }}
                    >
                      <div className="bg-white border d-flex align-items-center flex-wrap w-100">
                        <AttributeRow>
                          <AttributeTitle>Khu vực phát</AttributeTitle>
                          <SelectArea
                            className={"flex-grow-1"}
                            dropdownClassName={"w-100"}
                            onSelect={(selectedArea) => {
                              if (selectedArea.AreaId != area?.AreaId) {
                                setArea(selectedArea);
                                // setRadioStation(null);
                                setSpeakers([]);
                              }
                            }}
                            selectedArea={area}
                            rootAreaId={currentUser.areaId}
                            isDisabled={disableAll}
                          />
                        </AttributeRow>
                      </div>
                      <div className={`${speakers.length ? "" : "d-none"}`}>
                        <p style={{ margin: "12px 0" }}>
                          Danh sách thiết bị được chọn
                        </p>
                        <div className="border">
                          {speakers.map((speaker, i) => {
                            let title = `${speaker.name} - ${speaker?.area?.name}`;
                            return (
                              <div
                                key={speaker.id}
                                title={title}
                                className={`bg-white text-primary ${
                                  i !== speakers.length - 1
                                    ? "border-bottom"
                                    : ""
                                } d-flex`}
                              >
                                <span
                                  className="text-truncate flex-shrink-1"
                                  style={{ padding: "12px 0 12px 16px" }}
                                >
                                  {title}
                                </span>
                                <span
                                  className="flex-shrink-0 text-danger d-flex justify-content-center align-items-center"
                                  style={{
                                    padding: "12px 16px 12px 10px",
                                    cursor: disableAll ? "default" : "pointer",
                                    opacity: 0.7,
                                  }}
                                  onClick={() => {
                                    if (disableAll) return;
                                    speakers.splice(i, 1);
                                    setSpeakers([...speakers]);
                                  }}
                                >
                                  <i className="text-danger fas fa-times-square" />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*reject reason modal*/}
            <Modal
              style={{ maxWidth: 400 }}
              centered={true}
              isOpen={rejectReasonModalShow}
              toggle={toggleRejectReasonModal}
            >
              <ModalBody className="p-0">
                <div
                  className="text-center font-weight-boldest text-uppercase border-bottom text-truncate"
                  style={{ padding: 20 }}
                  title="Lý do từ chối"
                >
                  Lý do từ chối
                </div>
                <div style={{ padding: 20 }}>
                  <textarea
                    className="form-control"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    rows={5}
                    placeholder={"Nhập lý do từ chối"}
                  />
                </div>
              </ModalBody>

              <ModalFooter style={{ padding: "10px 20px" }}>
                <div className="d-flex m-0 w-100">
                  <div
                    style={{ paddingLeft: 0, paddingRight: 8 }}
                    className="col"
                  >
                    <button
                      onClick={toggleRejectReasonModal}
                      style={{ height: 40 }}
                      className="btn btn-secondary border w-100"
                    >
                      Hủy
                    </button>
                  </div>

                  <div
                    style={{ paddingLeft: 8, paddingRight: 0 }}
                    className="col"
                  >
                    <PrimaryButton
                      onClick={() => approveBulletin(false)}
                      style={{ height: 40 }}
                      className={`w-100 btn-danger`}
                    >
                      Xác Nhận
                    </PrimaryButton>
                  </div>
                </div>
              </ModalFooter>
            </Modal>
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}

export default BulletinBoardScreen;
