const { createSlice } = require("@reduxjs/toolkit");

const appSlice = createSlice({
    name: 'app',
    initialState: {
        activePath: '',
    },
    reducers: {
        setActivePath: (state, action) => {
            const path = action.payload;
            state.activePath = path;
        },
    },
    extraReducers: {

    },
});

const { reducer, actions } = appSlice;
export const { setActivePath } = actions;
export default reducer;