import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppImages } from "general/constants/AppResource";
import FormikControl from "pages/Login/components/inputs/FormikControl";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import authApi from "api/authApi";
import Utils from "general/utils/Utils";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { useHistory } from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import BaseTextField from "general/custom-fields/BaseTextField";
import axiosClient from "api/axiosClient";
import OTPInput from "pages/Login/components/OTPInput";
import { thunkGetCurrentUserInfo } from "pages/Login/authSlice";
import { set } from "lodash";
import { current } from "@reduxjs/toolkit";
import { useRef } from "react";

ModalOTP.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitModalOTP: PropTypes.func,
};

ModalOTP.defaultProps = {
  show: false,
  onClose: null,
};
let hiddenEmail;
function ModalOTP(props) {
  // MARK: --- Params ---
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const otpRef = useRef();
  const currentUser = useSelector((state) => state.auth.current);
  const { show, onClose, reason, onSubmitModalOTP, oldPassword, newPassword } =
    props;
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [message, setMessage] = useState(false);
  const [OTP, setOTP] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser.email) {
      hiddenEmail = currentUser.email.slice(0, 4);
      for (let i = 0; i < currentUser.email.length - 4; i++) {
        hiddenEmail += "*";
      }
    }
  }, [currentUser.email]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const keyDownHandler = (event) => {
    if (OTP.length == 6) {
      if (event.key === "Enter") {
        event.preventDefault();
        onSubmit();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [OTP]);

  useEffect(() => {
    
     
  }, [])
  

  const onSubmit = async (values, otp) => {
    try {
      setLoadingOTP(true);
      if (oldPassword) values.oldPassword = oldPassword;
      if (newPassword) values.newPassword = newPassword;
      values.OTP = otp;
      let res = await onSubmitModalOTP(values);
      setLoadingOTP(false);
      otpRef.current.resetOTP();
      document
        .getElementsByClassName("otp-input")[0]
        .getElementsByTagName("div")[0]
        .getElementsByTagName("div")[0]
        .getElementsByTagName("input")[0]
        .focus();
    } catch (error) {
      otpRef.current.resetOTP();
      document
        .getElementsByClassName("otp-input")[0]
        .getElementsByTagName("div")[0]
        .getElementsByTagName("div")[0]
        .getElementsByTagName("input")[0]
        .focus();
      console.log(error);
      setLoadingOTP(false);
    }
  };

  return (
    <Modal
      className=""
      dialogClassName="modalChangePassword"
      size="sm"
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header className="d-flex justify-content-center py-4">
        <Modal.Title className="">
          <span className="">{t("TwoFactorAuthentication")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light pt-4 otp-input">
        <span className="font-weight-bold">Vui lòng nhập mã OTP tại đây :</span>
        <OTPInput
          func={(otp) => setOTP(otp)}
          ref={otpRef}
          backgroundColor="#F3F6F9"
          onSubmit={onSubmit}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>
          <div className="col-6">
            <button
              className="w-100 btn font-weight-bold text-white d-flex justify-content-center"
              type="submit button"
              style={{ backgroundColor: "#2AB448" }}
              disabled={OTP.length != 6 || loadingOTP}
              onClick={onSubmit}
            >
              {loadingOTP === true ? (
                <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
              ) : null}
              <p className="font-weight-bold text-white mb-0">{t("Gửi")}</p>
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOTP;
