import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AppPage from "../../../../components/AppPage";
import { AppImages } from "../../../../general/constants/AppResource";
import NavBar from "../../../../components/NavBar";
import AppCard from "../../../../components/AppCard";
import SelectArea from "../../../../components/SelectArea/SelectArea";
import BaseSearchBar from "../../../../general/custom-fields/BaseSearchBar";
import AppTabs from "../../../../components/AppTabs";
import { useHistory } from "react-router-dom";
import bulletinApi from "../../../../api/bulletinApi";
import EBulletinHistoryTable from "../../../../components/EBulletinHistoryTable";
import { findAreaById } from "general/utils/Utils";

let tabs = ["Bản tin đang phát", "Bản tin đã phát"].reverse();
export default function eBulletinHistoryScreen() {
  const { t } = useTranslation();
  const history = useHistory();

  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);

  const [selectedArea, setSelectedArea] = useState();
  const [searchText, setSearchText] = useState("");

  const [selectedTab, setSelectedTab] = useState(tabs[1]);
  const [selectedItems, setSelectedItems] = useState([]);

  // MARK: --- Functions ---
  function handleSelectedTab(tab) {
    setSelectedTab(tab);
  }

  // MARK: --- Functions ---
  // Recursive Func to get Breadcrumb

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    
    }
  }, [currentUser, allAreas]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackgroundEBulletin}
        headerTitle="Lịch sử phát"
      >
        <NavBar navBarTitle="Lịch sử phát"></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            className="bg-white"
            title="Lịch sử bản tin phát thanh"
            buttonText={t("Thêm Bản Tin")}
            clickAdd={() => {
              history.push("/bulletins/edit/speaker");
            }}
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setSearchText}
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-300px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      setSelectedArea(area);
                    }}
                    rootAreaId={currentUser.areaId}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>
              </>
            }
          >
            <div className="w-100 bg-white">
              <div className="d-flex justify-content-between align-items-end">
                <AppTabs
                  tabs={tabs}
                  activeTab={selectedTab}
                  handleClick={handleSelectedTab}
                  className="flex-grow-1"
                />
              </div>
            </div>

            <EBulletinHistoryTable
              getEBulletinsAPI={(params) => {
                let areaId = selectedArea
                  ? selectedArea.AreaId
                  : currentUser.areaId;
                if (areaId) {
                  switch (selectedTab) {
                    case tabs[1]:
                      return bulletinApi.getPlayingBulletins(areaId, params);

                    case tabs[0]:
                      return bulletinApi.getPlayedBulletins(areaId, params);
                      break;
                  }
                }
              }}
              currentAreaId={
                selectedArea?.AreaId || currentUser?.areaId || null
              }
              searchText={searchText}
              tableName={selectedTab}
              selectedItems={selectedItems}
              onSelectedItemsChange={setSelectedItems}
            />
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}
