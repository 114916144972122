import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from './style.scss'
import ToastHelper from 'general/helpers/ToastHelper';
import BaseTextField from 'general/custom-fields/BaseTextField';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import CheckBox from 'components/CheckBox';
import { hasIn } from 'lodash';
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import Utils from "general/utils/Utils";
import useSort from "hooks/useSort";
import groupApi from 'api/groupApi';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

ModalAddMember.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    users: PropTypes.object,
    startMemberIds: PropTypes.array,
    returnMembers: PropTypes.func,
    myId: PropTypes.number,
    adminId: PropTypes.number
};

ModalAddMember.defaultProps = {
    show: false,
    onClose: null,
    users: null,
    startMemberIds: [],
    returnMembers: null,
    myId: null,
    adminId: -1
};
function ModalAddMember(props) {
    // MARK: --- Params ---
    const { show, onClose, users, startMemberIds, returnMembers, myId, adminId } = props;
    const leaderId = adminId <0 ? myId : adminId
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const members = [...users].sort((a, b) => a.username > b.username ? 1 : -1,)
    const [memberIds, setMemberIds] = useState(startMemberIds);
    // const [memberNames, setMemberNames] = useState();
    const filteredMember = useMemo(
        () => Utils.filter(members, "username", searchText),
        [searchText, members]
      );

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }
    function handleSave(){
        returnMembers(memberIds)
        handleClose()
    }
    function returnChecked(id){
        if(memberIds.includes(id)){
            return true
        }
        return false
    }
    function handleChangeMember(id, name){
        if(memberIds.includes(id)){
            setMemberIds(memberIds.filter(item => item !== id))
        }else{
            setMemberIds([...memberIds, id])
        }
    }

    // MARK: --- Hooks ---
    useEffect(()=>{
        setMemberIds(startMemberIds)
    },[show])
    return(
        <div>
            <Modal
                className='font-weight-bold'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex justify-content-center' style={{background:'#F6F7FB'}}>
                    <Modal.Title style = {{width:'96%'}}>
                    <BaseSearchBar
                        className='w-100'
                        name="searchBar"
                        placeholder={t("SearchUser")}
                        onSubmit={setSearchText}
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-0">
                    <div className="px-6">
                        {t("UserList")}
                    </div>
                    <div className="mt-6" style={{maxHeight: window.innerHeight - 250, overflow: "auto"}}>
                        {filteredMember.map((item, index) => (
                            <div key={index} className={`d-flex align-items-center py-3 px-6 ${(index < filteredMember.length - 1) && "border-bottom pb-0"}`}>
                                <CheckBox
                                    className="mr-3"    
                                    disabled={leaderId == item.id}
                                    checked={(leaderId != item.id)? returnChecked(item.id) : true}
                                    onCheck={() => {handleChangeMember(item.id)}}
                                    onUncheck={() => {handleChangeMember(item.id)}}
                                />
                                <div className="">{item.username}</div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-light  font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        <div className='col-6'>
                            <a 
                                className= {`w-100 btn font-weight-bold ${cx("save")}`}
                                onClick={() => { handleSave()
                                }}
                            >
                                {t('Save')}
                            </a>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAddMember;