import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import viLocale from "@fullcalendar/core/locales/vi";
import "./style.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AppIcons } from "general/constants/AppResource";
import Utils from "general/utils/Utils";
import { useResizeDetector } from "react-resize-detector";
import { useHistory, useParams } from "react-router-dom";
import deviceApi from "api/deviceApi";
import _ from "lodash";

function ScheduleInfo(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width, height, ref } = useResizeDetector();
  const [contentHeight, setContentHeight] = useState(window.innerHeight - 50);
  const { id } = useParams();
  const [deviceSchedule, setDeviceSchedule] = useState([]);
  const [displayingDate, setDisplayingDate] = useState({});
  const history = useHistory();
  const [timeRange, setTimeRange] = useState({
    min: "00:00:00",
    max: "24:00:00",
  });
  const previous = useRef(null);

  // MARK: --- Functions ---
  function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  function handleResize(e) {
    setContentHeight(window.innerHeight - 50);
  }
  // MARK: --- Hooks ---
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(async () => {
    if (
      !Utils.isObjectNull(id) &&
      !Utils.isObjectEmpty(displayingDate) &&
      !_.isEqual(previous.current, displayingDate)
    ) {
      previous.current = displayingDate;
      const res = await deviceApi.getDeviceSchedule(id, {
        startDate: displayingDate.start,
        endDate: displayingDate.end,
      });

      console.log(res.data.bulletins);

      let bulletins = res.data.bulletins.filter(
        (item) => item.status?.toUpperCase() === "APPROVED"
      );

      let arrEvents = [];

      bulletins.forEach((item) => {
        let arrEventsStartTime = item.startTime
          ? item.startTime.replace(/\[|\]|\"/g, "").split(",")
          : [];
        let arrEventsEndTime = item.endTime
          ? item.endTime.replace(/\[|\]|\"/g, "").split(",")
          : [];

        var tempDate; // biến tạm
        let eventStart; // thời gian bắt đầu của sự kiện
        let eventEnd; // thời gian kết thúc của sự kiện
        let eventStartDate = new Date(item.startDate);
        let displayingDateStart = new Date(displayingDate.start);

        var from =
          displayingDateStart < eventStartDate
            ? eventStartDate
            : displayingDateStart;
        var to = new Date(displayingDate.end);

        arrEventsStartTime.forEach((eventStartTime = item, index) => {
          let eventEndTime = arrEventsEndTime[index];
          switch (item.mode.toUpperCase()) {
            case "NOW":
            case "EMERGENCY":
              eventStart = new Date(item.startDate + " " + eventStartTime);
              if (eventStart < from || eventStart > to) break;
              if (item.duration != 0) {
                eventEnd = new Date(
                  eventStart.getTime() + item.duration * 1000
                );
                tempDate = new Date(
                  !Utils.isObjectNull(item.endDate)
                    ? item.endDate + " " + eventEndTime
                    : item.startDate + " " + eventEndTime
                );
                if (eventEnd > tempDate) eventEnd = tempDate;
              } else
                eventEnd = new Date(
                  !Utils.isObjectNull(item.endDate)
                    ? item.endDate + " " + eventEndTime
                    : item.startDate + " " + eventEndTime
                );
              arrEvents.push({
                title: item.title,
                description: item.description,
                start: isValidDate(eventStart) ? eventStart.toISOString() : "",
                end: isValidDate(eventEnd) ? eventEnd.toISOString() : "",
                priority: item.priority,
                eventId: item.id,
              });
              break;

            case "FIX":
              for (
                var day = new Date(from);
                day <= to;
                day.setDate(day.getDate() + 1)
              ) {
                if (!Utils.isObjectNull(item.endDate)) {
                  tempDate = new Date(item.endDate);
                  if (tempDate < day) break;
                }
                eventStart = new Date(
                  Utils.formatDateTime(day.toISOString(), "YYYY/MM/DD") +
                    " " +
                    eventStartTime
                );

                if (item.duration != 0) {
                  eventEnd = new Date(
                    eventStart.getTime() + item.duration * 1000
                  );
                } else {
                  eventEnd = new Date(
                    Utils.formatDateTime(day.toISOString(), "YYYY/MM/DD") +
                      " " +
                      eventEndTime
                  );
                }
                arrEvents.push({
                  title: item.title,
                  description: item.description,
                  start: isValidDate(eventStart)
                    ? eventStart?.toISOString()
                    : "",
                  end: isValidDate(eventEnd) ? eventEnd?.toISOString() : "",
                  priority: item.priority,
                  eventId: item.id,
                });
              }
              break;

            case "SCHEDULE":
              switch (item.repeatType.toUpperCase()) {
                case "WEEK":
                  for (
                    var day = new Date(from);
                    day <= to;
                    day.setDate(day.getDate() + 1)
                  ) {
                    if (!Utils.isObjectNull(item.endDate)) {
                      tempDate = new Date(item.endDate);
                      if (tempDate < day) break;
                    }
                    if (
                      item.playingDay
                        .replace(/\[|\]|\"/g, "")
                        .split(",")
                        .includes(`${day.getDay() + 1}`)
                    ) {
                      eventStart = new Date(
                        Utils.formatDateTime(day.toISOString(), "YYYY/MM/DD") +
                          " " +
                          eventStartTime
                      );

                      if (item.duration != 0) {
                        eventEnd = new Date(
                          eventStart.getTime() + item.duration * 1000
                        );
                      } else {
                        eventEnd = new Date(
                          Utils.formatDateTime(
                            day.toISOString(),
                            "YYYY/MM/DD"
                          ) +
                            " " +
                            eventEndTime
                        );
                      }
                      arrEvents.push({
                        title: item.title,
                        description: item.description,
                        start: isValidDate(eventStart)
                          ? eventStart?.toISOString()
                          : "",
                        end: isValidDate(eventEnd)
                          ? eventEnd?.toISOString()
                          : "",
                        priority: item.priority,
                        eventId: item.id,
                      });
                    }
                  }
                  break;

                case "MONTH":
                  for (
                    var day = new Date(from);
                    day <= to;
                    day.setDate(day.getDate() + 1)
                  ) {
                    if (!Utils.isObjectNull(item.endDate)) {
                      tempDate = new Date(item.endDate);
                      if (tempDate < day) break;
                    }
                    if (
                      item.playingDay
                        .replace(/\[|\]|\"/g, "")
                        .split(",")
                        .includes(`${day.getDate()}`)
                    ) {
                      eventStart = new Date(
                        Utils.formatDateTime(day.toISOString(), "YYYY/MM/DD") +
                          " " +
                          eventStartTime
                      );

                      if (item.duration != 0) {
                        eventEnd = new Date(
                          eventStart.getTime() + item.duration * 1000
                        );
                      } else {
                        eventEnd = new Date(
                          Utils.formatDateTime(
                            day.toISOString(),
                            "YYYY/MM/DD"
                          ) +
                            " " +
                            eventEndTime
                        );
                      }
                      arrEvents.push({
                        title: item.title,
                        description: item.description,
                        start: isValidDate(eventStart)
                          ? eventStart?.toISOString()
                          : "",
                        end: isValidDate(eventEnd)
                          ? eventEnd?.toISOString()
                          : "",
                        priority: item.priority,
                        eventId: item.id,
                      });
                    }
                  }
                  break;
              }
              break;
            default:
              break;
          }
        });
      });

      setDeviceSchedule(arrEvents);
    }
  }, [id, displayingDate]);

  useEffect(() => {
    if (deviceSchedule && !Utils.isObjectEmpty(displayingDate)) {
      let start = new Date(displayingDate.start);
      let end = new Date(displayingDate.end);
      let dateRange = Math.ceil(
        Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24)
      );
      let minTime = new Date("1970/01/01 23:59:59");
      let maxTime = new Date("1970/01/01 00:00:00");
      let tempDate;

      if (dateRange == 1 || dateRange == 7) {
        deviceSchedule.forEach((item) => {
          tempDate = new Date(
            "1970/01/01" + " " + Utils.formatDateTime(item?.start, "HH:mm:ss")
          );
          if (tempDate < minTime) minTime = tempDate;

          tempDate = new Date(
            "1970/01/01" + " " + Utils.formatDateTime(item?.end, "HH:mm:ss")
          );
          if (tempDate > maxTime) maxTime = tempDate;
        });
        try {
          if(minTime < maxTime)
            setTimeRange({
              min: Utils.formatDateTime(minTime.toISOString(), "HH:mm:ss"),
              max: Utils.formatDateTime(maxTime.toISOString(), "HH:mm:ss"),
            });
          else setTimeRange({
            min: "00:00:00",
            max: "00:00:00",
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        setTimeRange({
          min: "00:00:00",
          max: "24:00:00",
        });
      }
    }
  }, [displayingDate, deviceSchedule]);

  return (
    <>
      <div className="bg-white" ref={ref}>
        <FullCalendar
          locale={viLocale}
          headerToolbar={{
            left: `prev,next today`,
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          handleWindowResize={true}
          navLinks={true}
          nowIndicator={true}
          allDaySlot={false}
          contentHeight={contentHeight < 650 ? 650 : contentHeight}
          scrollTime={"00:00:00"}
          slotDuration={"00:05:00"}
          eventMinHeight={20}
          eventShortHeight={20}
          dayMaxEvents={true}
          slotMinTime={timeRange.min}
          slotMaxTime={timeRange.max}
          defaultTimedEventDuration={"00:01:00"}
          datesSet={(dateInfo) => {
            setDisplayingDate({
              start: Utils.formatDateTime(dateInfo.start, "YYYY/MM/DD"),
              end: Utils.formatDateTime(dateInfo.end, "YYYY/MM/DD"),
            });
          }}
          events={deviceSchedule}
          eventContent={(info) => {
            return (
              <OverlayTrigger
                placement="auto"
                overlay={
                  <Tooltip className="tooltip-container font-weight-bold border border-secondary">
                    <div className="d-flex" style={{ fontSize: 20 }}>
                      {info.event.title}
                    </div>
                    <div className="d-flex flex-row align-items-center mt-4 font-size-base">
                      <i
                        className="far fa-clock mr-5"
                        style={{ color: "#4A5677" }}
                      />
                      <div>
                        {getDayOfWeek(info.event?.start)} •{" "}
                        {Utils.formatDateTime(info.event?.start, "DD/MM/YYYY")}{" "}
                        • {Utils.formatDateTime(info.event?.start, "HH:mm")}
                        {!Utils.isObjectNull(info.event?.end)
                          ? ` - ${Utils.formatDateTime(
                              info.event?.end,
                              "HH:mm"
                            )}`
                          : ""}
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mt-4 font-size-base">
                      <i
                        className="far fa-list mr-5"
                        style={{ color: "#4A5677" }}
                      />
                      <div>
                        {info.event.extendedProps.description ||
                          "Không có mô tả"}
                      </div>
                    </div>
                    <div className="d-flex flex-row align-items-center mt-4 font-size-base">
                      <img src={AppIcons.icCalendarClock} className="mr-5" />
                      <div>
                        Độ ưu tiên:{" "}
                        <RenderPriority
                          priority={info.event.extendedProps.priority}
                        />
                      </div>
                    </div>
                  </Tooltip>
                }
              >
                <div
                  className="w-100 h-100 font-size-sm mr-2 font-weight-boldest"
                  style={{
                    color: "#4A5677",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(
                      `/bulletins/edit/speaker/${info.event.extendedProps.eventId}`
                    )
                  }
                >
                  {info.timeText && info.timeText}
                  <span className="w-100 h-100 font-weight-bold ml-1" style={{wordBreak: 'break-all'}}>
                    {info.event.title}
                  </span>
                </div>
              </OverlayTrigger>
            );
          }}
        />
      </div>
    </>
  );
}

const getDayOfWeek = (date) => {
  switch (date.getDay()) {
    case 0:
      return "Thứ 2";
    case 1:
      return "Thứ 3";
    case 2:
      return "Thứ 4";
    case 3:
      return "Thứ 5";
    case 4:
      return "Thứ 6";
    case 5:
      return "Thứ 7";
    case 6:
      return "Chủ nhật";
  }
};

const RenderPriority = (props) => {
  switch (props.priority?.toUpperCase()) {
    case "EMERGENCY":
      return <span style={{ color: "#E60000" }}>Khẩn cấp</span>;
    case "HIGH":
      return <span style={{ color: "#EB8334" }}>Cao</span>;
    case "MEDIUM":
      return <span style={{ color: "#5180FB" }}>Trung bình</span>;
    case "NORMAL":
      return <span style={{ color: "#3ACE5A" }}>Thấp</span>;
    default:
      return <span>Không</span>;
  }
};
export default ScheduleInfo;
