import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { AppImages } from "general/constants/AppResource";

NotificationModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  // onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
};

NotificationModal.defaultProps = {
  show: false,
  onClose: null,
  text: "Are you sure you want to delete?",
  title: "Delete",
};

/**
 * show (bool)
 * onClose (func): fire when click close
 * onSubmit (func): fire when click submit
 * text (string): show on body
 */
function NotificationModal(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const { show, onClose, onSubmit, text, title } = props;

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleSubmit() {
    if (onSubmit) {
      onSubmit();
    }
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className=" font-weight-bold"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      show={show}
      // scrollable={true}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header className="d-flex justify-content-center py-3">
        <Modal.Title>
          <p className="Bold_13">
            {t("Notification")}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="d-flex flex-column align-items-center bg-light p-0"
        style={{
          maxHeight: "calc(100vh - 300px)",
          overflowY: "auto",
        }}
      >
        <img
          src={AppImages.imgNotiModalBackground}
          className="w-100"
          style={{
            objectFit: "contain",
          }}
        />
        {/* <p className="Regular_13 mt-6">{text}</p> */}
        <p className="Regular_13 m-5 text-justify">
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deserunt mollitia animi, id est
          laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita
          distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
          cumque nihil impedit quo minus id quod maxime placeat facere possimus,
          omnis voluptas assumenda est, omnis dolor repellendus. Temporibus
          autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe
          eveniet ut et voluptates repudiandae sint et molestiae non recusandae.
          Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
          voluptatibus maiores alias consequatur aut perferendis doloribus
          asperiores repellat
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center ">
        <div className="row w-100">
          <div className="col-12 border rounded">
            <a
              className="w-100 btn  font-weight-bolder font-size-base"
              style={{ color: "#4A5677" }}
              onClick={() => handleClose()}
            >
              {t("End")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationModal;
