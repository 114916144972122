import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import _ from "lodash";
import { SelectSort } from 'pages/Category/components/SelectSort';

const PrimaryButton = styled.label`
  background-color: #5180FB !important;
  padding: 12px 10px;
  border: 1px solid #3465E6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 0;
  cursor: pointer;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`

let deviceOptions = [];

export default function ChooseSourceDevice({show, disableAll, device, onDeviceChange}) {
  const digitalDevices = useSelector(state => state.bulletin.digitalDevices)
  const [selectedDevice, setSelectedDevice] = useState();
  
  useEffect(() => {
    if(digitalDevices) deviceOptions = digitalDevices.map(item => ({...item, value: item.deviceId, display: item.name}))
    // setSelectedDevice(_.find(digitalDevices, {deviceId: device?.deviceId}));
  }, [digitalDevices, device]);

  return <div style={{
    display: show ? "" : "none",
  }}>
    <div
      className="d-flex align-items-center text-nowrap"
      style={{
        padding: "12px 0",
        marginBottom: 12,
      }}>
      <label htmlFor="select-device" className="Regular_13 mb-0" style={{marginRight: 12}}>Lựa chọn thiết bị</label>
      <SelectSort
        className="flex-grow-1"
        selections={deviceOptions}
        disable={disableAll}
        currentValue={selectedDevice?.display ?? "Chọn thiết bị"}
        onSelect={(item) => {
          onDeviceChange(item.deviceId);
          setSelectedDevice(item);
        }}
      />
    </div>
    
    <table className="table table-bordered mb-0">
      <thead>
      <tr style={{background: "#F6F7FB",}}>
        <th width="50%" style={{padding: 10}}>Tên Thiết Bị</th>
        <th width="50%" style={{padding: 10}}>ID</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td style={{padding: 10}}>{selectedDevice?.name}</td>
        <td style={{padding: 10}}>{selectedDevice?.deviceId}</td>
      </tr>
      </tbody>
    </table>

    <div style={{padding: 10, background: "#F6F7FB", margin: -10, marginTop: 10}} className="border-top">
      <button className="Bold_13 btn btn-white border w-100" disabled={disableAll}>
        <i className="fas fa-headphones" style={{marginRight: 8}}/>
        Nghe Thử
      </button>
    </div>
  </div>
}
