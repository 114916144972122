import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

BaseTextField.propTypes = {
    name: PropTypes.string.isRequired,
    fieldProps: PropTypes.object,
    fieldMeta: PropTypes.object,
    fieldHelpers: PropTypes.object,

    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    spellCheck: PropTypes.bool,
    showBorder: PropTypes.bool,
};

BaseTextField.defaultProps = {
    type: "text",
    label: "",
    placeholder: "",
    disabled: false,
    text: "",
    spellCheck: false,

    fieldProps: {},
    fieldHelpers: {},
    fieldMeta: {},

    className: 'form-group',
    showBorder: true,
};

/**
 * @param className
 * @param name id of input tag
 * @param type type of input tag
 * @param label label of input tag
 * @param placeholder input place holder
 * @param text muted-text below input field
 * @param fieldProps for form helper
 * @param fieldHelpers for form helper
 * @param fieldMeta for form helper
 * @param multiLine true - textarea, false - input
 */
function BaseTextField(props) {
    // MARK: --- Params ---
    const { name, fieldProps, fieldHelpers, fieldMeta, type, label, placeholder, disabled, text, className, spellCheck, multiLine, showBorder } = props;
    const { error, touched } = fieldMeta;
    const isError = error && touched;
    let Input = !multiLine ? 'input' : 'textarea'

    return (
        <div className="BaseTextField">
            <div className={className}>
                {
                    label && (<label htmlFor={name}>{label}</label>)
                }
                <Input
                    className={`form-control Regular_13 ${isError && 'is-invalid'} ${showBorder ? "" : "border-0"}`}
                    id={name}
                    disabled={disabled}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    spellCheck={spellCheck}
                    autoComplete="new-password"
                    {...fieldProps}
                />
                {
                    text.length > 0 && (
                        <span className='form-text text-muted'>{text}</span>
                    )
                }
                {
                    isError && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{error}</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default BaseTextField;
