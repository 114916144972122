import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./index.scss";

CheckBox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  onUncheck: PropTypes.func,
  disabled: PropTypes.bool,
};

CheckBox.propsDefault = {
  className: "",
  checked: false,
  onCheck: null,
  onUncheck: null,
  disabled: false,
};

/**
 * - className (string)
 * - checked (bool) : init checkbox state
 * - onCheck (func) : fire on check
 * - onUncheck (func) : fire on uncheck
 */
function CheckBox(props) {
  // MARK: --- Params ---
  const { className, checked, onCheck, onUncheck, disabled } = props;
  const inputRef = useRef(null);

  // MARK: --- Functions ---
  function onChange(e) {
    if (e.target.checked) {
      if (onCheck) onCheck();
    } else {
      if (onUncheck) onUncheck();
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    inputRef.current.checked = !!checked;
  }, [checked]);

  return (
    <label className={`${className} custom-checkbox`}>
      <input type="checkbox" onChange={onChange} ref={inputRef} disabled={disabled}/>
      <span className="checkmark"></span>
    </label>
  );
}

export default CheckBox;
