import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Header from "components/Header";
import Footer from "components/Footer";
import { DropdownItem } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppIcons } from "general/constants/AppResource";
import "./styles.scss";
import zIndex from "@material-ui/core/styles/zIndex";
import NotificationModal from "./components/NotificationModal";
import { thunkGetNotifications } from "pages/Login/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authApi from "api/authApi";

NotificationDropdown.propTypes = {
  moveRight: PropTypes.bool,
};

NotificationDropdown.defaultProps = {
  moveRight: true,

  moveRight: PropTypes.bool,
};

NotificationDropdown.defaultProps = {
  moveRight: true,
};

function NotificationDropdown(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const refDropdown = useRef(null);
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const dispatch = useDispatch();

  // const [notiContents, setNotiContents] = useState(null);
  // useEffect(async () => {
  //   const res = await authApi.getNotifications();
  //   setNotiContents(res?.data?.notifications);
  // }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        show &&
        refDropdown.current &&
        !refDropdown.current.contains(event.target)
      ) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);

  const handleScroll = (event) => {
    let scrollY = window.scrollY;
    if (scrollY > 0) {
      setShow(false);
    } else {
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const changeDropdown = (e) => {
    setShow(!show);
  };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };

  return (
    <Dropdown
      className="position-relative"
      style={{ zIndex: "100" }}
      show={show}
    >
      <Dropdown.Toggle
        ref={refDropdown}
        onMouseDown={changeDropdown}
        className=" d-flex justify-content-center align-items-center bg-transparent p-0 border-0 "
      >
        {props.children}
      </Dropdown.Toggle>

      <Dropdown.Menu
        show={show}
        // onMouseLeave={hideDropdown}
        className={`p-0 mt-5 notiDropdown ${
          props.moveRight ? "notiDropdownMenuNavbar" : "notiDropdownMenuHeader"
        }`}
        style={{ boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.15)" }}
      >
        <Dropdown.Header className=" border-bottom rounded-top py-3">
          <span
            className="font-weight-bolder font-size-h6 my-4"
            style={{
              lineHeight: "20px",
              color: "#23262F",
              height: "54px",
              width: "315px",
            }}
          >
            {t("Notification")}
          </span>
        </Dropdown.Header>
        <div className="notiScroll">
          {props.notiContents ? (
            props.notiContents.map((notiContent, index) => (
              <Dropdown.Item
                className="d-flex align-items-center border-bottom w-100"
                onMouseDown={() => {
                  window.open(notiContent?.action, "_self");
                }}
                key={index}
              >
                <img
                  src={notiContent.image ? notiContent.image : AppIcons.icBell}
                  className="notiIcon "
                />
                <div
                  className={`ml-3 d-flex flex-column align-items-center w-100 `}
                >
                  <p className=" font-weight-bolder notificationText ml-2">
                    {notiContent.message}
                  </p>
                  <div
                    className={`d-flex flex-row justify-content-center w-100 px-1 mt-3 `}
                  >
                    {notiContent?.positiveButton?.name ? (
                      <a
                        className="mr-3 noti-button d-flex justify-content-center align-items-center"
                        type="button"
                        style={{ backgroundColor: "#3e97ff" }}
                        onMouseDown={() => {
                          window.open(
                            notiContent?.positiveButton?.action,
                            "_self"
                          );
                        }}
                      >
                        <p className="font-weight-bold text-white mb-0">
                          {notiContent?.positiveButton?.name}
                        </p>
                      </a>
                    ) : null}
                    {notiContent?.negativeButton?.name ? (
                      <a
                        className="ml-3 noti-button d-flex justify-content-center align-items-center"
                        type="button"
                        style={{ backgroundColor: "#E4E6EB" }}
                        onMouseDown={() => {
                          window.open(
                            notiContent?.negativeButton?.action,
                            "_self"
                          );
                        }}
                      >
                        <p className="font-weight-bold text-dark mb-0">
                          {notiContent?.negativeButton?.name}
                        </p>
                      </a>
                    ) : null}
                  </div>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item className="d-flex align-items-center border-bottom w-100">
              <img src={AppIcons.icNoNotification} className="notiIcon " />
              <div
                className={`ml-3 d-flex flex-column align-items-center w-100 text-left `}
              >
                <p
                  className=" font-weight-bolder notificationText ml-2 text-left"
                  style={{
                    maxHeight: "46px",
                    fontSize: "13px",
                    whiteSpace: "normal",
                    lineHeight: "15.5px",
                    textOverflow: "ellipsis",
                    overFlow: "hidden",
                    color: "#4a5677",
                    textAlign: "left",
                  }}
                >
                  Không có thông báo để hiển thị
                </p>
              </div>
            </Dropdown.Item>
          )}
        </div>
      </Dropdown.Menu>
      {/* <NotificationModal
        show={notificationModalShow}
        onClose={() => setNotificationModalShow(false)}
        onSubmit={() => dispatch(thunkDeletaStation({ id: modalStation.id }))}
        title={t("DeleteStation")}
        text={t("AreYouSureYouWantToDeleteThisStation?")}
      /> */}
    </Dropdown>
  );
}

export default NotificationDropdown;
