import authReducer from "pages/Login/authSlice";
import appReducer from "libs/storage/appSlice";
import dashboardSlice from "pages/Dashboard/dashboardSlice";
// nghia
import stationReducer from "pages/RadioStationManager/stationSlice";
import areaReducer from "pages/AreaManager/areaSlice";
// khue
import providerReducer from "pages/ProviderManager/providerSlice";
// sonnh
import accountSlice from "../../pages/AccountManager/accountSlice";

import bulletinReducer from "pages/BulletinManager/bulletinSlice";
import feedbackReducer from "pages/Feedback/feedbackSlice";
import categoryReducer from "pages/Category/CategorySlice";
import reportReducer from "pages/Report/reportSlice";

const {
  configureStore,
  compose,
  applyMiddleware,
  Middleware,
  getDefaultMiddleware,
} = require("@reduxjs/toolkit");

const rootReducer = {
  app: appReducer,
  auth: authReducer,
  dashboard: dashboardSlice,

  area: areaReducer,
  station: stationReducer,
  // khue
  provider: providerReducer,

  bulletin: bulletinReducer,

  //sonnh
  account: accountSlice,

  feedback: feedbackReducer,

  category: categoryReducer,
  report: reportReducer
};

const logger: Middleware = (_store) => (next) => (action) => {
  // console.log('[Redux Logger Middleware-Update State]')
  // console.log('[Action]', action.type, action.payload)
  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  enhancers: [compose(applyMiddleware(logger))],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
