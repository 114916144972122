import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from './style.scss'
import ToastHelper from 'general/helpers/ToastHelper';
import BaseTextField from 'general/custom-fields/BaseTextField';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import CheckBox from 'components/CheckBox';
import ModalAddMember from 'pages/GroupManager/components/ModalAddMember';
import { AppIcons, AppImages } from "general/constants/AppResource";
import groupApi from 'api/groupApi';
import areaApi from "api/areaApi";
import { faL } from '@fortawesome/free-solid-svg-icons';
import SelectArea from "components/SelectArea/SelectArea";
import classnames from 'classnames/bind';
import { setAllAreas } from 'pages/AreaManager/areaSlice';

const cx = classnames.bind(styles);
ModalCreateGroup.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    getGroup: PropTypes.func,
};

ModalCreateGroup.defaultProps = {
    show: false,
    onClose: null,
    getGroup: null,
};

function ModalCreateGroup(props) {
    // MARK: --- Params ---
    const { show, onShow, onClose, getGroup } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [modalAddMemberShowing, setModalAddMemberShowing] = useState(false);
    const [editAccount, setEditAccount] = useState(false)
    const [editProgram, setEditProgram] = useState(false)
    const [acceptProgram, setAcceptProgram] = useState(false)
    const [editSpeaker, setEditSpeaker] = useState(false)
    const [broadcast, setBroadcast] = useState(false)
    const [name, setName] = useState()
    const [users, setUsers] = useState([])
    const [memberIds,setMemberIds] = useState([])
    const [memberNames, setMemberNames] = useState([])
    const [pleaseAdd, setPleaseAdd] = useState(true)
    const [myId, setMyId] = useState()
    // Area
    const [areasByUser, setAreasByUser] = useState([]);
    const [currentArea, setCurrentArea] = useState();
    const [selectedArea, setSelectedArea] = useState();
    const [currentAreaId, setCurrentAreaId] = useState();
    const currentUser = useSelector((state) => state.auth.current);
    // MARK: --- Functions ---
    
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }
    function handleShow() {
        if (onShow) {
            onShow();
        }
    }
    function handleSubmit() {
        if(name != null && memberIds.length > 0){
            const data = {
                name: name,
                editAccount: editAccount,
                areaId: selectedArea.AreaId,
                editProgram: editProgram,
                acceptProgram: acceptProgram,
                editSpeaker: editSpeaker,
                broadcast: broadcast,
                memberIds : [...memberIds, myId.id]
            }
            groupApi.createGroup(data).then(res => {
                const { result } = res.data;
                if (result == "success") {
                    ToastHelper.showSuccess(t('CreatedGroupSuccessfully'));
                    handleClose();
                    getGroup();
                    // setTimeout(() => {window.location.reload()}, 2000)
                }
            })
        }
        else{
            ToastHelper.showError(t('PleaseFillAllFields'))
        }
    }

    function checkFullFilled(){
        if(name != null && name!= '' && memberIds.length > 0){
            return true
        }
        return false
    }

    function getUserList(){
        groupApi.getUserList().then(res => {
            const { result, users } = res.data;
            // console.log(res.data)
            if(result == "success"){
                setUsers(res?.data?.accounts);
            }
        })
    }
    async function getAreas() {
        const res = await areaApi.getAreas();
        const { result, areas } = res.data;
        if (result == "success" && areas) {
            setAreasByUser(areas);
            dispatch(setAllAreas(areas));
        }
    }
    
    function handleDeleteMember(id){
        setMemberIds(memberIds.filter(item => item != id))
    }

    function getMe(){
        groupApi.getMe().then(res => {
            const { result} = res.data;
            if(result == "success"){
                setMyId(res?.data.user);
            }
        })
    }
    // MARK: --- Hooks ---
    useEffect(() => {
        getUserList();
        getMe();
        getAreas();
    } ,[])

    useEffect(()=>{
        setMemberIds([]);
        setPleaseAdd(true);
    },[show]);

    useEffect(()=>{
        if(memberIds?.length > 0) setPleaseAdd(false);
    },[memberIds]);

    useEffect(()=>{
        setMemberNames(users.filter(user => memberIds.includes(user.id)));
    },[memberIds])

    /// Area
    useEffect(() => {
        let areaId = selectedArea
            ? selectedArea.AreaId
            : currentUser
            ? currentUser.areaId
            : null;
        if (areaId) {
            setCurrentAreaId(areaId);
        }
        }, [selectedArea, currentUser.areaId]);

    useEffect(() => {
    if (!currentArea) {
        setCurrentArea(
        areasByUser?.filter((area) => area.id == currentUser?.areaId).at(0)
        );
    }
    }, [areasByUser]);
    // useEffect(()=>{console.log(memberNames)},[memberNames])
    return (
        <div >
            <Modal
                scrollable={true}
                className='font-weight-bold'
                style={{zIndex: '1059 !important'}}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex justify-content-center'>
                    <Modal.Title>
                        <p className="Bold_13">
                            {t("CreateNewGroup")}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-12 col-lg-6 d-flex flex-column p-0 border-0 bg-white'>
                            <div className='' style={{margin:'0 5px', border: '1px solid #DBE3EF'}}>
                                <div className='Bold_13 bg-white d-flex align-items-center px-6 py-5' style={{borderBottom: '1px solid #DBE3EF'}}>
                                    <span className='mr-5'>
                                        {t("Member")}
                                    </span> 
                                    <i className="fas fa-circle mr-5" style={{fontSize: 5, color: "#4A5677"}}></i>
                                    <span style={{color:'#5180FB', textTransform:'lowercase'}}>
                                        {1 + memberNames.length} {t("Member")}
                                    </span>
                                </div>
                                <div className='p-2' style={{background:'#F6F7FB'}}>
                                    <div style={{background:'white', border:'1px solid #DBE3EF'}}>
                                        <div style={{maxHeight: 304, overflowY: "auto"}}>
                                            <div className='w-100 px-4 py-3' style={{borderBottom: memberNames != 0 || pleaseAdd ? '1px solid #DBE3EF' : ""}}>
                                                {myId?.username}
                                            </div>
                                            {
                                                pleaseAdd && 
                                                    <div className="w-100 mb-3" style={{height:'192px'}}>
                                                        <img
                                                        src={AppImages.imgGroupIcon}
                                                        className="rounded mx-auto d-block"
                                                        style={{padding:'25px'}}
                                                        />
                                                        <p style={{
                                                            margin: '0 15px',
                                                            textAlign:'center',
                                                            paddingTop:'10px',
                                                        }}>{t("PleasePressAdd")}</p>
                                                    </div>
                                            }
                                            {memberNames.map((item, index) => (
                                                <div className='w-100 px-4 py-3' style={{borderBottom: index < memberNames.length - 1 ? '1px solid #DBE3EF' : ""}}>
                                                    {item.username}
                                                    <i className="fa fa-trash-alt cursor-pointer text-hover-danger"
                                                    style={{float:'right'}}
                                                    onClick={() => {
                                                        handleDeleteMember(item.id) 
                                                    }}
                                                    ></i>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-white d-flex justify-content-center'
                                 style={{height:'48px', borderTop:'1px solid #DBE3EF'}}>
                                    <a 
                                        className='btn btn-primary font-weight-bold'
                                        style={{width:'90%', margin:'auto'}} 
                                        onClick={() => { 
                                            setModalAddMemberShowing(true);
                                         }}
                                    >
                                        {t("AddMember")}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6 d-flex flex-column p-0 border-0 bg-white mt-6 mt-lg-0'>
                            <div className='' style={{margin:'0 5px', border: '1px solid #DBE3EF'}}>
                                <div className='Bold_13 bg-white m-0 px-6 py-5' style={{borderBottom: "1px solid #DBE3EF"}}>
                                    {t("ManageGroup")}
                                </div>
                                <div className='p-2' style={{background:'#F6F7FB'}}>
                                    <div style={{background:'white', border: '1px solid #DBE3EF'}}>
                                        <p className='mb-1 mx-4 mt-3' style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#9EA5BD',
                                        }}>{t("GroupName")}</p>
                                        <div className="mx-4">
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder= {t('GroupPlaceholder')}
                                                onChange={(e) => { setName(e.target.value) }}
                                            />
                                        </div>

                                        <p className='mb-1 mx-4 mt-3' style={{
                                            fontSize: 12,
                                            fontWeight: 500,
                                            color: '#9EA5BD',
                                        }}>{t("Area")}</p>
                                        <SelectArea
                                            className="mx-4 mb-3"
                                            onSelect={(area) => {
                                                setSelectedArea(area);
                                            }}
                                            rootAreaId={currentUser?.areaId}
                                            selectedArea={selectedArea}
                                        />
                                    </div>
                                    <div className="bg-white mt-3" style={{border: '1px solid #DBE3EF'}}>
                                        <div className='Regular_13 d-flex flex-column justify-content-between mt-2 mb-2'>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className="mr-3"
                                                    disabled={myId?.broadcast ? false : true}
                                                    checked={broadcast}
                                                    onCheck={() => { setBroadcast(true) }}
                                                    onUncheck={() => { setBroadcast(false) }}
                                                />
                                                <div >{t("broadcast")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className="mr-3"
                                                    disabled={myId?.editSpeaker ? false : true}
                                                    checked={editSpeaker}
                                                    onCheck={() => { setEditSpeaker(true) }}
                                                    onUncheck={() => { setEditSpeaker(false) }}
                                                />
                                                <div >{t("editSpeaker")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className="mr-3"
                                                    disabled={myId?.editProgram ? false : true}
                                                    checked={editProgram}
                                                    onCheck={() => { setEditProgram(true) }}
                                                    onUncheck={() => { setEditProgram(false) }}
                                                />
                                                <div >{t("editProgram")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className="mr-3"
                                                    disabled={myId?.editAccount ? false : true}
                                                    checked={editAccount}
                                                    onCheck={() => { setEditAccount(true) }}
                                                    onUncheck={() => { setEditAccount(false) }}
                                                />
                                                <div >{t("editAccount")}</div>
                                            </div>
                                            <div className="d-flex align-items-center px-4 py-3">
                                                <CheckBox
                                                    className="mr-3"
                                                    disabled={myId?.acceptProgram ? false : true}
                                                    checked={acceptProgram}
                                                    onCheck={() => { setAcceptProgram(true) }}
                                                    onUncheck={() => { setAcceptProgram(false) }}
                                                />
                                                <div >{t("acceptProgram")}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-6'>
                            <a  
                                className='w-100 btn btn-light  font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        <div className='col-6'>
                            <a 
                                className= {`w-100 btn font-weight-bold ${checkFullFilled()? cx('save') :cx("save-disabled")}`}
                                onClick={() => { handleSubmit() }}
                            >
                                {t("Save_")}
                            </a>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ModalAddMember
                show={modalAddMemberShowing}
                users={users}
                startMemberIds = {memberIds}
                returnMembers={setMemberIds}
                myId={myId?.id}
                onClose={() => {
                    setModalAddMemberShowing(false);
                    // handleShow();
                }}/>
        </div>
    );
}

export default ModalCreateGroup;