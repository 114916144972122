import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {formatDuration, formatFileObj} from "../../../../general/utils/Utils";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import AudioPlayer from "../AudioPlayer";
import ConfirmModal from "../ConfirmModal";
import trashIcon from "../../../../assets/images/trash-round-bg.svg";
import PreviewAudio from "../PreviewAudio";

const PrimaryButton = styled.label`
  background-color: #5180FB !important;
  padding: 12px 10px;
  border: 1px solid #3465E6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`

export default function ChooseSourceFile({show = true, disableAll = false, files, onFilesChange}) {
  const {t} = useTranslation();

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState();

  const togglePreviewModal = () => {
    if (showPreviewModal) {
      setPreviewFile(null);
    }
    setShowPreviewModal(!showPreviewModal);
  }
  const removeFileModalRef = useRef();

  useEffect(() => {
    return () => {
      files.forEach(file => file.cleanup());
    }
  }, []);

  return <div style={{
    background: "#F6F7FB",
    border: "1px solid #DBE3EF",
    padding: 10,
    display: show ? "" : "none",
  }}>
    <div style={{
      padding: 6,
      background: "white",
      border: "1px solid #DBE3EF",
      borderRadius: 6,
    }}>
      <PrimaryButton
        disabled={disableAll}
        style={{
          opacity: disableAll ? 0.8 : 1,
          cursor: disableAll ? 'default' : 'pointer',
        }}
      >
        <i className={"fas fa-file-upload"}/>
        {t("BulletinUploadFile")}
        <input
          type="file"
          accept=".mp3, .wav"
          className="d-none"
          multiple={true}
          onChange={async e => {
            if (e.target.files && e.target.files.length) {
              let newFiles = [];
              for (const file of Array.from(e.target.files)) {
                newFiles.push(await formatFileObj(file));
              }

              onFilesChange([
                ...files,
                ...newFiles
              ]);
            }
            e.target.value = '';
          }}
          disabled={disableAll}
        />
      </PrimaryButton>
      <span className="Regular_13" style={{
        marginLeft: 12,
      }}>{t("BulletinNoFileYet")}</span>
    </div>

    {!!files.length &&
      <div
        className="border rounded bg-white"
        style={{
          marginTop: 12,
        }}
      >
        {files.map((file, index) =>
          <div key={index} className={`d-flex ${index !== files.length - 1 && 'border-bottom'}`} style={{
            padding: "10px 16px",

          }}>
            <div className="flex-grow-1 text-truncate pr-2">
              <span title={file.file.name}>
                <i className="fas fa-file-check mr-3" style={{color: "#3ACE5A"}}/>
                {file.file.name}
            </span>
            </div>
            <div
              style={{
                padding: "0 8px",
              }}
              className="border-x d-flex align-items-center"
            >
              <i className="far fa-clock" style={{marginRight: 4}}/>
              {formatDuration(file.duration)}
            </div>
            <div className=" d-flex align-items-center">
              <i
                className={`fas ${file.playing ? "fa-pause-circle" : "fa-play-circle"} cursor-pointer user-select-none`}
                style={{marginLeft: 8, color: "#5180FB"}}
                onClick={() => {
                  if (disableAll) return;
                  // toggle play/pause
                  // if (file.playing) file.pause();
                  // else file.play();
                  setPreviewFile(file);
                  togglePreviewModal();
                }}
              />
              <i
                className={`far fa-trash-alt user-select-none cursor-pointer`}
                style={{marginLeft: 8, color: "#E54135"}}
                onClick={async () => {
                  if (disableAll) return;
                  // remove chosen file
                  if (removeFileModalRef.current) {
                    try {
                      await removeFileModalRef.current.show(
                        "Xóa Tập Tin",
                        <div style={{padding: 16}} className="Regular_13 text-center">
                          <img src={trashIcon} style={{marginBottom: 20}}/>
                          <p className="m-0">
                            Bạn có chắc muốn xóa tập tin <span className="text-danger">{file.file.name}</span> không?
                          </p>
                        </div>
                      );
                      let currentFiles = [...files];
                      currentFiles.splice(index, 1)
                      onFilesChange(currentFiles);
                    } catch (e) {

                    }
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    }
    <PreviewAudio
      url={previewFile?.src}
      title={previewFile?.file?.name}
      show={showPreviewModal}
      toggle={togglePreviewModal}
    />


    <ConfirmModal
      ref={removeFileModalRef}
      confirmButtonClass={"btn btn-danger"}
      confirmButtonText={"Xác Nhận"}
    />
  </div>
}
