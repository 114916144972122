import {createSlice} from "@reduxjs/toolkit";
import {createAsyncThunk} from "@reduxjs/toolkit";

import bulletinApi from "api/bulletinApi";
import {thunkGetAreas} from "../AreaManager/areaSlice";

export const bulletinGetConfig = createAsyncThunk(
  'bulletin/getConfig',
  async (params, thunkAPI) => {
    await thunkAPI.dispatch(thunkGetAreas());

    return await Promise.all([
      bulletinApi.getCategories(),
      bulletinApi.getDigitalDevices(),
      bulletinApi.getRelaySources(),
    ]);
  }
)

const bulletinSlice = createSlice({
  name: 'bulletin',
  initialState: {
    categories: [],
    digitalDevices: [],
    relaySources: [],
  },
  reducers: {},
  extraReducers: {
    [bulletinGetConfig.fulfilled]: (state, action) => {
      const [{data: categoryResponse}, {data: digitalDeviceResponse}, {data: replaySourceResponse}] = action.payload;
      if (categoryResponse.result === 'success') {
        state.categories = categoryResponse.categories;
      }
      if (digitalDeviceResponse.result === 'success') {
        state.digitalDevices = digitalDeviceResponse.digitalDevices;
      }
      if (replaySourceResponse.result === 'success') {
        state.relaySources = replaySourceResponse.relaySources;
      }
    },
  }
})

const {actions, reducer} = bulletinSlice;

export const {} = actions;
export default reducer;
