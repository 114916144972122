import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.scss'


AppBreadcrumb.propTypes = {
    items: PropTypes.array,
    text: PropTypes.string,
};

AppBreadcrumb.defaultProps = {
    items: [],
    text: '',
};


function AppBreadcrumb(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const { items, text } = props;
    

    return (
        items.length > 0 &&
        (
            <div className='d-flex flex-row nowrap align-items-center bg-white px-6 py-3'>
                {
                    items.map((item, index) => (
                        <div
                            key={index}
                            className={`Bold_14 text-capitalize nowrap cursor-pointer hover-opacity-70 add-slash ${index&&'pl-2'}`}
                            onClick={item.onClick}
                            >
                            {t(`${item.name}`)}
                        </div>
                    ))
                }
                <div className="Regular_13 pl-2 text-emphasis" aria-current="page">{text}</div>
            </div>
        )
    );
}

export default AppBreadcrumb;
