import Chart from "react-apexcharts";
function Chart_apex(
  {  data=[0, 0, 0, 0, 0, 0, 0, 0],
     colorStart="#77A6EE", 
     colorEnd= "#86B5F3", 
     categories = ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12'] }
  ) {
  const days = categories.map(item => item.substring(0,2))
  const i = 0
  const state = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        }
      },
      stroke: {
        curve: 'straight',
        width: 1,
        colors: [colorStart, colorEnd]
      },
      markers: {
        size: 2,
        colors: colorStart,
        strokeColor: colorStart,
        strokeWidth: 3
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: days,
        tickAmount: 7,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          trim: true,
          minHeight: 40,
          style: {
              colors: [],
              fontFamily: 'Roboto',
          },
          format: 'DD'
        },
      }, 
      yaxis: {
        show:false
      },
      tooltip:{
        x: {
          show: true,
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return categories[value-1]
          }
        },
      },
      fill:{  
        type:'gradient',
        gradient: {
          type:'vertical',
          colorStops:  [{ offset: 0, color: colorStart , opacity: 0.9}, { offset: 100, color: colorEnd, opacity: 0.1}]
        }
      }
    },
    series: [
      {
        name: "số lượng",
        data: data
      }
    ]
  };
  return(
    <div>
    <Chart
      options={state.options}
      series={state.series}
      type= "area"
      
      /> 
    </div>
  )
}

export default Chart_apex;