import React, {useEffect, useRef, useState} from "react";

const AudioContext = window.AudioContext || window.webkitAudioContext;
export default function Visualizer({mediaStream, width, height}) {
  /** @type MediaStreamAudioSourceNode */
  let microphone = useRef().current;
  /** @type AnalyserNode */
  let analyser = useRef().current;
  let rafID = useRef().current;
  let canvasRef = useRef();
  /** @type CanvasRenderingContext2D */
  let ctx = useRef().current;

  useEffect(() => {
    return () => {
      if (rafID) {
        window.cancelAnimationFrame(rafID);
      }
    }
  }, []);

  useEffect(() => {
    if (mediaStream) {
      /** @type HTMLCanvasElement */
      let canvas = canvasRef.current;
      canvas.width = width || window.innerWidth;
      canvas.height = height || window.innerHeight;
      //get context from canvas for drawing
      ctx = canvas.getContext("2d");

      ctx.canvas.width = width || window.innerWidth;
      ctx.canvas.height = height || window.innerHeight;

      !width && !height && window.addEventListener('resize', onWindowResize, false);

      //create gradient for the bins
      const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
      gradient.addColorStop(1, '#00f');
      // gradient.addColorStop(1, '#D6E0FB');
      // gradient.addColorStop(0.5, '#BCCEFB');
      // gradient.addColorStop(0, '#A8BFBB');


      ctx.fillStyle = gradient;

      const context = new AudioContext();
      microphone = context.createMediaStreamSource(mediaStream);
      analyser = context.createAnalyser();
      microphone.connect(analyser);
      rafID = window.requestAnimationFrame(updateVisualization);
    }
  }, [mediaStream]);

  const updateVisualization = function () {
    if (analyser) {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);

      drawBars(array);
    }

    rafID = window.requestAnimationFrame(updateVisualization);
  }

  const drawBars = function (array) {
    /** @type HTMLCanvasElement */
    let canvas = canvasRef.current;
    if (!ctx || !canvas) return;

    //just show bins with a value over the threshold
    const threshold = 0;
    // clear the current state
    // ctx.clearRect(0, 0, canvas.getBoundingClientRect().width, canvas.getBoundingClientRect().height);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    //the max count of bins for the visualization
    const maxBinCount = array.length;
    //space between bins
    // const space = 3;

    ctx.save();


    ctx.globalCompositeOperation = 'source-over';

    //console.log(maxBinCount); //--> 1024
    ctx.scale(0.5, 0.5);
    ctx.translate(width || window.innerWidth, height || window.innerHeight);
    // ctx.fillStyle = "#ff00ff";

    let windowWidth = width || $(window).width();
    const bass = Math.floor(array[1]); //1Hz Frequenz
    const radius = 0.45 * windowWidth <= 450 ? -(bass * 0.25 + 0.45 * windowWidth) : -(bass * 0.25 + 450);

    let bar_length_factor = 1;
    if (windowWidth >= 785) {
      bar_length_factor = 1.0;
    } else if (windowWidth < 785) {
      bar_length_factor = 1.5;
    } else if (windowWidth < 500) {
      bar_length_factor = 20.0;
    }
    //go over each bin

    let value;
    for (let i = 0; i < maxBinCount; i++) {

      value = array[i];
      if (value >= threshold) {
        //draw bin
        //ctx.fillRect(0 + i * space, c.height - value, 2 , c.height);
        //ctx.fillRect(i * space, c.height, 2, -value);
        ctx.fillRect(0, radius, windowWidth <= 450 ? 2 : 3, -value / bar_length_factor);
        ctx.rotate((180 / 128) * Math.PI / 180);
      }
    }

    for (let i = 0; i < maxBinCount; i++) {

      value = array[i];
      if (value >= threshold) {

        //draw bin
        //ctx.fillRect(0 + i * space, c.height - value, 2 , c.height);
        //ctx.fillRect(i * space, c.height, 2, -value);
        ctx.rotate(-(180 / 128) * Math.PI / 180);
        ctx.fillRect(0, radius, windowWidth <= 450 ? 2 : 3, -value / bar_length_factor);
      }
    }

    for (let i = 0; i < maxBinCount; i++) {

      value = array[i];
      if (value >= threshold) {

        //draw bin
        //ctx.fillRect(0 + i * space, c.height - value, 2 , c.height);
        //ctx.fillRect(i * space, c.height, 2, -value);
        ctx.rotate((180 / 128) * Math.PI / 180);
        ctx.fillRect(0, radius, windowWidth <= 450 ? 2 : 3, -value / bar_length_factor);
      }
    }

    ctx.restore();
  }

  const onWindowResize = function () {
    ctx.canvas.width = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
  }
  return <div className="text-center">
    <canvas ref={canvasRef}></canvas>
  </div>
}
