import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
import styles from "./style.scss";
import groupApi from "api/groupApi";
import Global from "general/Global";
import { useDispatch, useSelector } from "react-redux";
import ToastHelper from "general/helpers/ToastHelper";
import Utils from "general/utils/Utils";
import useSort from "hooks/useSort";
import CheckBox from "components/CheckBox";
import ModalManageGroup from "pages/GroupManager/components/ModalManageGroup";
import ModalDeleteGroup from "pages/GroupManager/components/ModalDeleteGroup"
import classnames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import ModalDelete from "components/AppModal/ModalDelete";
const cx = classnames.bind(styles);

function TableListGroups(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const {selectedGroups, setSelectedGroups, groups, setGroups, getGroups,
          step, setStep, page, setPage, total, order, changeOrder, isLoading } = props;
  const [modalManageGroupShowing, setModalManageGroupShowing] = useState(false)
  const [modalDeleteGroupShowing, setModalDeleteGroupShowing] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [editableGroup, setEditableGroup] = useState()
  
  // const { directionIndicator, sortOnClick } = useSort(groups, (sortedArray) =>
  //   setGroups(sortedArray)
  // );

  // const filteredGroups = useMemo(
  //   () => Utils.filter(groups, "name", searchText),
  //   [searchText, groups]
  // );

  // MARK: --- Functions ---
  function handleDeleteGroup(id) {
    const data = [id]
    groupApi.deleteGroup(data).then(res => {
      const { result } = res.data;
      console.log(res.data)
      if(result == "success"){
        ToastHelper.showSuccess(t('DeletedGroupSuccessfully'))
        getGroups()
      }
    })
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    setEditableGroup(groups?.[0]);
  },[])

  return (
    <>
      <DataTable
        // className={cx("table")}
        cols={[1,24,20, 20,20, 15]}
        pointerOnHover
        highlightOnHover
        headItems={[
          <CheckBox
            checked={groups.filter(item => item.checked == true).length == groups.length}
            onCheck={() => {
              setGroups(
                groups.map((item) => {
                  return { ...item, checked: true };
                })
              );
              setSelectedGroups(groups.map((item) => item.id));
            }}
            onUncheck={() => {
              setGroups(
                groups.map((item) => {
                  return { ...item, checked: false };
                })
              );
              setSelectedGroups([]);
            }}
          />,
          <div
            className="sort-direction w-100 cursor-pointer"
            // onClick={() => sortOnClick("name")}
            onClick={() => changeOrder("name")}
          >
            <p>
              {t("GroupList")}
              {order[0] === "name" &&
              <FontAwesomeIcon
                  style={{marginLeft: '4px', fontSize: '15px'}}
                  icon={order[1] == "asc" ? faCaretUp : faCaretDown} />}
              {/* {directionIndicator("name")} */}
            </p>
          </div>,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => changeOrder("memberCount")}
          >
            <p>
              {t("NumberOfMembers")}
              {order[0] === "memberCount" &&
              <FontAwesomeIcon
                  style={{marginLeft: '4px', fontSize: '15px'}}
                  icon={order[1] == "asc" ? faCaretUp : faCaretDown} />}
            </p>
          </div>,
          <div
          className="sort-direction w-100 cursor-pointer"
          onClick={() => changeOrder("areaId")}
        >
          <p>
            Khu vực
            {order[0] === "areaId" &&
            <FontAwesomeIcon
                style={{marginLeft: '4px', fontSize: '15px'}}
                icon={order[1] == "asc" ? faCaretUp : faCaretDown} />}
          </p>
        </div>,,
          "Trưởng nhóm",
          t("Manage"),
        ]}
        // row
        dataItems={
          groups?.map((item, index) => {
            return {
              isRowCheck: item.checked,
              rowClick: () => {},
              data: [
                {
                  comp: (
                    <CheckBox
                      checked={item.checked}
                      onCheck={() => {
                        (item.checked = true)
                        setSelectedGroups([...selectedGroups, item.id])
                      }}
                      onUncheck={() => {
                        (item.checked = false)
                        setSelectedGroups(selectedGroups.filter(id => id !== item.id))
                      }}
                    />
                  ),
                },
                {
                  comp: (
                    <div
                      key={item.id}
                      className="d-flex flex-row position-relative"
                    >
                      <i className="fas fa-users pr-2 text-primary"></i>
                      <p 
                        className="Bold_13 text-primary position-absolute w-100 top-50 translate-middle-y overflow-wrap pr-6" 
                        style={{ left: 30 }}
                      >
                        {item.name}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                      setEditableGroup(item)
                      setModalManageGroupShowing(true)
                    }
                },
                {
                  comp: (
                    <div className="d-flex justify-content-center" style={{width: 103}}>
                      <p className="Regular_13">
                        {item.members.length > 0 ? item.members.length : 1}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                      setEditableGroup(item)
                      setModalManageGroupShowing(true)
                    }
                },
                {
                  comp: (
                    <div
                      className="position-relative"
                      style={{ minWidth: 120 }}
                    >
                      <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                         {item.area.name}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                      setEditableGroup(item)
                      setModalManageGroupShowing(true)
                    }
                },
                {
                  comp: (
                    <div className="position-relative">
                      <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                        {item.admin.username}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                      setEditableGroup(item)
                      setModalManageGroupShowing(true)
                    }
                },
                {
                  comp: (
                    <div style={{ width: 80 }}>
                      <i 
                        className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                        onClick={() => {
                          setModalManageGroupShowing(true);
                          setEditableGroup(item);
                        }}

                      />
                      <i className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                        onClick={() => {
                          setModalDeleteGroupShowing(true)
                          setCurrentId(item.id)
                        }}
                      ></i>
                    </div>
                  ),
                },
              ],
            };
          })}
        step={step}
        onRefresh={() => getGroups()}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white"
        total={total}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />

      <ModalManageGroup
        show={modalManageGroupShowing}
        getGroups={getGroups}
        onClose={() => setModalManageGroupShowing(false)}
        onShow={() => setModalManageGroupShowing(true)}
        group = {editableGroup}
      />

      <ModalDelete
        title={t("DeleteGroup")}
        text={t("SureDeleteGroups")}
        show={modalDeleteGroupShowing}
        onClose={() => {setModalDeleteGroupShowing(false)}}
        onSubmit ={()=>handleDeleteGroup(currentId)}
      />
    </>
  );
}

export default TableListGroups;
