import axiosClient from "./axiosClient";

const reportApi = {
    getReport: (params) => {
        const url = `/reports`;
        return axiosClient.get(url,{params});
    },
    download: () => {
        const url = `/reports/download`;
        axiosClient.interceptors.request.use(async (config) => {
            config.responseType = "blob"
            return config;
        });

        return axiosClient.get(url);
    }
}

export default reportApi
