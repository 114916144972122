import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import styles from "./Modal.module.scss";
import { Priority } from "../screens/Feedback";
import pdfIcon from "../../../assets/icons/pdf.png"

const cx = classNames.bind(styles);

function ModalFeedback({ show, onCancel, onHide, handleSendFeedback, currentFeedback }) {
    const { t } = useTranslation();

    const [action, setAction] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [files, setFiles] = useState([]);
    const ref = useRef();

    const onChangeFile = (e) => {
        const arr = e.target.files;
        const tmp = []
        for (const i of arr) {
            tmp.push(i);
        }
        const _files = tmp.map(item => ({
            id: Math.random().toString(),
            name: item.name,
            link: URL.createObjectURL(item),
            type: item.type.includes("image") ? "image" : "document"
        }))
        for (const file of _files) {
            files.push(file);
        }
        e.target.files = null;
        setFiles([...files]);
        console.log(files)
    }

    const onDelete = (id) => {
        _.remove(files, i => i.id == id);
        setFiles([...files]);
    }

    useEffect(() => {
        if (show) {
            setFeedback("");
            setAction(0);
            if (!currentFeedback.answer) {
                setAction(1);
            }
            setFiles([]);
        }
    }, [show]);

    return (
        <Modal
            className={cx("modal")}
            onHide={onHide}
            show={show}
            size={"lg"}
            centered={true}>
            <div className={cx("title")}>
                {t("FeedbackFrom")}
            </div>
            <div className={cx("body")}>
                <div className={cx("row")}>
                    <div className={cx("left")}>{t("Sender")}:</div>
                    <div className={cx("right")}>{currentFeedback.senderEmail}</div>
                </div>
                <div className={cx("devide")}></div>
                <div className={cx("row")}>
                    <div className={cx("left")}>{t("Area")}:</div>
                    <div className={cx("right")}>{currentFeedback.senderAddress ?? currentFeedback.area?.name}</div>
                </div>
                <div className={cx("devide")}></div>
                <div className={cx("row")}>
                    <div className={cx("left")}>{t("Priority")}:</div>
                    <Priority type={currentFeedback.priority} />
                </div>
                <div className={cx("devide")}></div>
                <div className={cx("content")}>
                    {currentFeedback.content}
                </div>
                {currentFeedback.attachedFiles && (
                    <div className={cx("devide")}></div>
                )}
                {currentFeedback.attachedFiles && (
                    <div className={cx("file-attach")}>
                        <div className={cx("label")}>{t("AttachFile")}</div>
                        <div className={cx("files")}>
                            {currentFeedback.attachedFiles?.map((item, index) => (
                                <ItemFile
                                    key={index}
                                    {...item} />
                            ))}
                        </div>
                    </div>
                )}
                <div className={cx("area")}>
                    <div className={cx("label")}>{t("EnterFeedback")}</div>
                    <div className={cx("border")}>
                        <textarea
                            ref={ref}
                            className={cx("input")}
                            style={{ cursor: action == 1 ? undefined : "default" }}
                            readOnly={action == 1 ? false : currentFeedback.answer}
                            value={action == 1 ? feedback : currentFeedback.answer ?? ""}
                            onChange={e => setFeedback(e.target.value)}
                            placeholder={t("EnterFeedbacks")}
                        >
                        </textarea>
                    </div>
                </div>
                {(action == 1 || !currentFeedback.answer) &&
                    <div className={cx("file-attachs")}>
                        <div className={cx("label")}>{t("AttachFile")}</div>
                        {files.length > 0 &&
                            <div className={cx("files")}>
                                {files.map((item, index) => (
                                    <ItemFile
                                        key={index}
                                        onDelete={onDelete}
                                        {...item} />
                                ))}
                            </div>
                        }
                    </div>
                }
                {(action == 1 || !currentFeedback.answer) &&
                    <label
                        htmlFor="upload"
                        className={cx("btn-attach")}>
                        {t("Upload")}
                    </label>
                }
                <input
                    className={cx("input-file")}
                    type="file"
                    id="upload"
                    multiple="multiple"
                    onChange={onChangeFile} />
            </div>
            <div className={cx("action")}>
                <button
                    className={cx("btn")}
                    onClick={onCancel}>
                    {t("Cancel")}
                </button>
                <button
                    className={cx("btn")}
                    style={{
                        opacity: (action == 1 && (feedback.length == 0 || feedback == currentFeedback.answer)) ? 0.8 : undefined,
                        cursor: (action == 1 && (feedback.length == 0 || feedback == currentFeedback.answer)) ? "default" : undefined,
                    }}
                    onClick={() => {
                        if (action == 0) {
                            setAction(1);
                            setFeedback(currentFeedback.answer);
                            ref.current?.focus();
                        }
                        if (action == 1) {
                            if (feedback.length == 0 || feedback == currentFeedback.answer) {
                                return;
                            }
                            handleSendFeedback(feedback);
                        }
                    }}>
                    {currentFeedback.answer ? (action == 0 ? t("EditFeedback") : t("SendFeedback")) : t("SendFeedback")}
                </button>
            </div>
        </Modal>
    );
}

function ItemFile({ type = "document", link, name, onDelete, id }) {

    return (
        <div className={cx("wrapper")}>
            {onDelete &&
                <FontAwesomeIcon
                    icon={faCircleXmark}
                    className={cx("icon-delete")}
                    onClick={() => onDelete(id)} />
            }
            <a
                className={cx("file")}
                href={link}
                target="_blank">
                {type == "image" && (
                    <img
                        style={{ width: 98, height: 98, objectFit: "contain" }}
                        src={link} />
                )}
                {type == "video" && (
                    <video
                        width={98}
                        height={98} >
                        <source src={link} />
                    </video>
                )}
                {type == "document" && (
                    <img
                        className={cx("icon-file")}
                        src={pdfIcon} />
                )}
            </a>
            <div className={cx("file-name")}>{name}</div>
        </div>
    );
}

export {
    ModalFeedback
}