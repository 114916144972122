import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  faCaretDown,
  faCaretUp,
  faGear,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";

import styles from "./Category.module.scss";
import AppCard from "components/AppCard";
import AppPage from "components/AppPage";
import NavBar from "components/NavBar";
import { AppImages } from "general/constants/AppResource";
import DataTable from "components/Table";
import CheckBox from "components/CheckBox";
import Global from "general/Global";
import ToastHelper from "general/helpers/ToastHelper";
import categoryApi from "api/categoryApi";
import {
  check,
  checkAll,
  setOrderType,
  setStep,
  setPage,
  thuckGetCategories,
  unCheck,
  unCheckAll,
  setOrderBy,
  setSearchText
} from "../CategorySlice";
import AppPagination from "components/AppPagination";
import { ModalAdd, ModalDelete, ModalEdit } from "../components/Modal";
import { SelectSort } from "../components/SelectSort";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";

const cx = classNames.bind(styles);

function Category() {
  const { t } = useTranslation();
  const [currentCategory, setCurrentCategry] = useState({
    id: -1,
  });
  const [action, setAction] = useState("delete-one");
  const [showModalDelele, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [sortByName, setSortByName] = useState(false);
  const [sortByNumber, setSortByNumber] = useState(false);

  const { categories, total, limit, page, orderBy, orderType, loadingState, q } =
    useSelector((state) => state.category);
  const dispatch = useDispatch();

  const reload = () => {
    dispatch(
      thuckGetCategories({
        query: q ?? "",
        order: [orderBy, orderType],
        page: page,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    if (categories.length == 0) {
      reload();
    }
  }, []);

  useEffect(() => {
    reload();
  }, [page, limit, orderBy, orderType, q]);

  const checkButtonDelete = () => {
    if (categories.length === 0) {
      return false;
    }
    return categories.reduce((pre, item) => pre + item.isCheck, 0);
  };

  const isCheckAll = () => {
    if (categories.length == 0) {
      return false;
    }
    return categories.reduce((pre, item) => pre && item.isCheck, true);
  };

  //thêm lĩnh vực
  const addCategory = async (category) => {
    const response = await categoryApi.createCaegory({
      name: category,
      description: "Mô tả",
      note: "Ghi chú",
    });
    if ((response.data.result = "success")) {
      setShowModalAdd(false);
      ToastHelper.showSuccess(t("AddCategorySuccess"));
      reload();
    }
  };

  //sửa lĩnh vực
  const editCategory = async (category) => {
    const response = await categoryApi.editCategory({
      name: category,
      categoryId: currentCategory.id,
    });
    if ((response.data.result = "success")) {
      setShowModalEdit(false);
      ToastHelper.showSuccess(t("EditCategorySuccess"));
      reload();
    }
  };
  //xóa lĩnh vực
  const deleteCategory = async () => {
    if (action == "delete-one") {
      const response = await categoryApi.deleteCategory({
        itemIds: [currentCategory.id],
      });
      if ((response.data.result = "success")) {
        setShowModalDelete(false);
        ToastHelper.showSuccess(t("DeleteCategorySuccess"));
        reload();
      }
    } else {
      const tmp = categories.filter((item) => item.isCheck == true);
      const ids = tmp.map((item) => item.id);
      const response = await categoryApi.deleteCategory({
        itemIds: ids,
      });
      if ((response.data.result = "success")) {
        setShowModalDelete(false);
        ToastHelper.showSuccess(t("DeleteCategorySuccess"));
        reload();
      }
    }
  };

  return (
    <>
      <AppPage
        className={cx("container")}
        backgroundImage={AppImages.imgBackground14}
        headerTitle={t("Category").toUpperCase()}
      >
        <NavBar navBarTitle={t("Category").toUpperCase()}></NavBar>
        <div className="RadioStationManager d-flex flex-column flex-column-fluid my-2">
          <AppCard
            className={cx("container")}
            title={t("BulletinCategory").toLocaleUpperCase()}
            buttonText={t("AddCategory")}
            clickAdd={() => {
              setShowModalAdd(true);
            }}
            headerRight={
              <BaseSearchBar
                name="searchBar"
                placeholder={t("Search")}
                onSubmit={(searchText) => dispatch(setSearchText(searchText))}
                className="display-smaller-md-none w-md-200px w-xl-250px w-xxxl-300px mr-6"
              />
            }
            additionalHeader={
              <>
                <div className="col-12 display-bigger-md-none p-0"
                >
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={(searchText) => dispatch(setSearchText(searchText))}
                    className="w-100"
                  />
                </div>
                {checkButtonDelete() > 0 && (
                  <div className="w-100">
                    <button
                      className={"btn btn-light-danger font-weight-bold"}
                      onClick={() => {
                        if (action != "delete-multi") {
                          setAction("delete-multi");
                        }
                        setShowModalDelete(true);
                      }}
                    >
                      <i className="fas fa-books-medical"></i>
                      {`${t("Delete")}   ${checkButtonDelete()}`}
                    </button>
                  </div>
                )}
              </>
            }
          >
            <DataTable
              className={cx("table")}
              cols={[0, 40, 40, 20]}
              headItems={[
                <CheckBox
                  checked={isCheckAll()}
                  onCheck={() => {
                    dispatch(checkAll());
                  }}
                  onUncheck={() => {
                    dispatch(unCheckAll());
                  }}
                />,
                <div className="d-flex flex-row align-items-center cursor-pointer">
                  <span
                    className={cx("list")}
                    onClick={() => {
                      setSortByNumber(false);
                      setSortByName(true);
                      dispatch(setOrderBy("name"));
                      if (orderType == "asc") {
                        dispatch(setOrderType("desc"));
                      } else {
                        dispatch(setOrderType("asc"));
                      }
                    }}
                  >
                    {t("CategoryList")}
                  </span>
                  {sortByName && (
                    <FontAwesomeIcon
                      className={cx("icon-sort")}
                      icon={orderType == "asc" ? faCaretUp : faCaretDown}
                    />
                  )}
                </div>,
                <div
                  className={`${cx("row")} cursor-pointer`}
                  onClick={() => {
                    setSortByNumber(true);
                    setSortByName(false);
                    dispatch(setOrderBy("radioBulletinsCount"));
                    if (orderType == "asc") {
                      dispatch(setOrderType("desc"));
                    } else {
                      dispatch(setOrderType("asc"));
                    }
                  }}
                >
                  {t("NumberBulletin")}
                  {sortByNumber && (
                    <FontAwesomeIcon
                      className={cx("icon-sort")}
                      icon={orderType == "asc" ? faCaretUp : faCaretDown}
                    />
                  )}
                </div>,
                t("Manage").toLocaleUpperCase(),
              ]}
              dataItems={categories.map((item) => ({
                isRowCheck: item.isCheck,
                rowClick: () => {},
                data: [
                  {
                    comp: (
                      <CheckBox
                        checked={item.isCheck}
                        onCheck={() => {
                          dispatch(check(item.id));
                        }}
                        onUncheck={() => {
                          dispatch(unCheck(item.id));
                        }}
                      />
                    )
                  },
                  {
                    comp: (
                      <div
                        className="d-flex flex-row align-items-center"
                        key={item.id}
                      >
                        <span className={cx("item-content")}>
                          {item.name}
                        </span>
                      </div>
                    ),
                    onClick: () => {
                      setCurrentCategry(item);
                      setShowModalEdit(true);
                    }
                  },
                  {
                    comp: (
                      <div className="d-flex justify-content-center" style={{ width: 75 }}>
                        <span className={cx("number")}>
                          {item.radioBulletinsCount}
                        </span>
                      </div>
                    ),
                    onClick: () => {
                      setCurrentCategry(item);
                      setShowModalEdit(true);
                    }
                  },
                  {
                    comp: (
                      <div className={cx("action")}>
                        {/* edit */}
                        <FontAwesomeIcon
                          className={cx("icon")}
                          icon={faGear}
                          onClick={() => {
                            setCurrentCategry(item);
                            setShowModalEdit(true);
                          }}
                        />
                        {/* delete */}
                        <FontAwesomeIcon
                          className={cx("icon")}
                          icon={faTrashCan}
                          onClick={() => {
                            if (action != "delete-one") {
                              setAction("delete-one");
                            }
                            if (item.id != currentCategory.id) {
                              setCurrentCategry(item);
                            }
                            setShowModalDelete(true);
                          }}
                        />
                      </div>
                    ),
                  },
                ],
              }))}
              onRefresh={() => reload()}
              isLoading={loadingState}
            />
            <AppPagination
              className="bg-white"
              total={total}
              curPage={page + 1}
              curStep={limit}
              step={Global.gAvailablePagination}
              setPage={(page) => dispatch(setPage(page - 1))}
              setStep={(step) => dispatch(setStep(step))}
            />
          </AppCard>
        </div>
        <ModalDelete
          show={showModalDelele}
          onCancel={() => setShowModalDelete(false)}
          onSubmit={deleteCategory}
          onHide={() => setShowModalDelete(false)}
        />
        <ModalAdd
          show={showModalAdd}
          onSubmit={addCategory}
          onCancel={() => setShowModalAdd(false)}
          onHide={() => setShowModalAdd(false)}
        />
        <ModalEdit
          show={showModalEdit}
          onCancel={() => setShowModalEdit(false)}
          onSubmit={editCategory}
          onHide={() => setShowModalEdit(false)}
          onDelete={() => {
            setShowModalEdit(false);
            if (action != "delete-one") {
              setAction("delete-one");
            }
            setShowModalDelete(true);
          }}
          currentCategory={currentCategory}
        />
      </AppPage>
    </>
  );
}

export default Category;
