import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './style.scss'
import { addAccount, updateAccount } from 'pages/ProviderManager/providerSlice';
import providerApi from 'api/providerApi';
import ToastHelper from 'general/helpers/ToastHelper';
import BaseTextField from 'general/custom-fields/BaseTextField';
import SelectRadioStation from 'components/SelectRadioStation/SelectRadioStation';
import SelectArea from 'components/SelectArea/SelectArea';
import SelectProviderDropdown from 'pages/ProviderManager/components/SelectProviderDropdown';

ModalCreateAccount.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    account: PropTypes.object
};

ModalCreateAccount.defaultProps = {
    show: false,
    onClose: null,
    account: null,
};

function ModalCreateAccount(props) {
    // MARK: --- Params ---
    const { show, onClose, account } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const { selectedProvider, providers } = useSelector(state => state.provider)
    const currentUser = useSelector((state) => state.auth.current);
    const { allAreas } = useSelector(state => state.area);
    const [selectedRadioStation, setSelectedRadioStation] = useState(null)
    const [selectedArea, setSelectedArea] = useState(null)
    const [currentProvider, setCurrentProvider] = useState(null)

    const formik = useFormik({
        initialValues: {
            Provider: '',
            Account: '',
            Password: '',
            ServerUrl: '',
        },
        onSubmit: (values) => {
            if(account){
                    handleModifyAccount({
                        id: account.id, 
                        serverUrl: values.ServerUrl, 
                        account: values.Account, 
                        password: values.Password
                    })
                    handleClose()
                }   
            else if(selectedRadioStation){
                    handleAddAccount({
                        serverUrl: values.ServerUrl,    
                        account: values.Account, 
                        password: values.Password, 
                        radioStationId: selectedRadioStation.id, 
                        providerId: currentProvider.id,
                        areaId: selectedArea?.AreaId
                    })
                    handleClose()
                    setSelectedRadioStation(null)
                    setSelectedArea(null)
                    formik.handleReset();
                }          
        },
        validationSchema: yup.object({
            Provider: yup.string().required(t('Required')),
            Account: yup.string().required(t('Required')),
            Password: yup.string().required(t('Required')),
            ServerUrl: yup.string().required(t('Required'))
        }),
    });

    useEffect(() => {
        if(account){
            formik.getFieldHelpers('Account').setValue(account?.account)
            formik.getFieldHelpers('Password').setValue(account?.password)
            formik.getFieldHelpers('ServerUrl').setValue(account?.serverUrl)
        }
    },[account])

    useEffect(() => {
        if(selectedProvider){
            setCurrentProvider(selectedProvider)
            formik.getFieldHelpers('Provider').setValue(selectedProvider?.name)
        }
    },[selectedProvider])
    
    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    async function handleAddAccount(params) {
        
        const res = await providerApi.addAccount(params);
        const { result, providerAccount } = res?.data;

        if(result == "success"){
            ToastHelper.showSuccess(t('CreatedAccountSuccessfully'))
            if(currentProvider.id === selectedProvider.id) dispatch(addAccount(providerAccount))
        }
    }

    async function handleModifyAccount(params) {

        const res = await providerApi.modifyAccount(params);
        const { result } = res.data;

        if(result == "success"){
            ToastHelper.showSuccess(t('UpdatedAccountSuccessfully'))
            dispatch(updateAccount(params))
        }
    }

    return (
        <div>
            <Modal
                className='font-weight-bold'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex justify-content-center'>
                    <Modal.Title>
                        <p className="Bold_13">
                            {t('Account')}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    {!account &&
                        <SelectProviderDropdown
                            className='FieldName Regular_13 pb-4 font-weight-bold'
                            name='Provider'
                            label={t('Provider')}
                            options={providers}
                            renderDropdownItem={(item) => (
                                <div 
                                    key={item.id}
                                    className={`w-100 px-5 py-3 d-flex flex-row cursor-pointer font-weight-bold Regular_13 ${
                                        formik.values.Provider == item.name ? "bg-primary text-white" : "text-primary"
                                    }`}
                                >
                                    <i className="fas fa-user-tie pr-2" style={{color: "inherit"}}></i>
                                    <p className="" >{item.name}</p>
                                </div>
                            )}
                            onItemClick={(item) => {
                                setCurrentProvider(item)
                                formik.getFieldHelpers('Provider').setValue(item.name)
                            }}
                            fieldProps={formik.getFieldProps('Provider')}
                            fieldHelpers={formik.getFieldHelpers('Provider')}
                            fieldMeta={formik.getFieldMeta('Provider')}
                        />
                    }
                    <BaseTextField
                        className='FieldName Normal_12 pb-5'
                        name='Account'
                        label={t('Account')}
                        placeholder={t('Account')}
                        fieldProps={formik.getFieldProps('Account')}
                        fieldHelpers={formik.getFieldHelpers('Account')}
                        fieldMeta={formik.getFieldMeta('Account')}
                    />
                    <BaseTextField
                        className='FieldName Normal_12 pb-5'
                        name='Password'
                        label={t('password')}
                        placeholder={t('password')}
                        fieldProps={formik.getFieldProps('Password')}
                        fieldHelpers={formik.getFieldHelpers('Password')}
                        fieldMeta={formik.getFieldMeta('Password')}
                    />
                    <BaseTextField
                        className='FieldName Normal_12 pb-5'
                        name='ServerUrl'
                        label={t('ServerAddress')}
                        placeholder={t('ServerAddress')}
                        fieldProps={formik.getFieldProps('ServerUrl')}
                        fieldHelpers={formik.getFieldHelpers('ServerUrl')}
                        fieldMeta={formik.getFieldMeta('ServerUrl')}
                    />
                    {
                        !account && <>
                            <div className='mb-1'
                                style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#9EA5BD',
                                }}
                            >{t('Area')}</div>
                            <SelectArea
                                onSelect={(area) => {
                                    setSelectedArea(area);
                                    if(selectedRadioStation && area.AreaId != 1) {
                                        let obj = allAreas.find(item => item.id == selectedRadioStation.areaId);
                                        if(obj.id != area.AreaId && obj.parentId != area.AreaId) setSelectedRadioStation(null)
                                    }
                                }}
                                rootAreaId={currentUser?.areaId}  
                                selectedArea={selectedArea}
                                className="mr-2 bg-white w-100 rounded"
                            />

                            <div className='mt-3 mb-1'
                                style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: '#9EA5BD',
                                }}
                            >{t('RadioStation')}</div>
                            <SelectRadioStation
                                onSelect={(radioStation) => {
                                    setSelectedRadioStation(radioStation);
                                }}
                                areaId={selectedArea?.AreaId}
                                selectedRadioStation={selectedRadioStation}
                                className="bg-white rounded"
                                noBorder={false}
                            />
                        </>
                    }
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-light  font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-primary font-weight-bold'
                                onClick={() => { formik.handleSubmit() }}
                            >
                                {t('Save')}
                            </a>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalCreateAccount;