import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppIcons, AppImages } from "general/constants/AppResource";
import PassWordInput from "../PasswordInput";
import * as Yup from "yup";

ModalUpdateAccount.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  submitOnclick: PropTypes.func,
  loading: PropTypes.bool,
};

ModalUpdateAccount.defaultProps = {
  show: false,
  onClose: null,
};

function ModalUpdateAccount(props) {
  // MARK: --- Params ---
  const { show, onClose, reason, loading } = props;
  const { t } = useTranslation();

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  }

  function handleChange(event) {
    this.setState({ value: event.target.value });
  }

  const initialValues = {};
  const validationSchema = Yup.object({});

  return (
    <Modal
      className=""
      size="sm"
      //   style={{width:"300px"}}
      dialogClassName="modal-50w"
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header className="d-flex justify-content-center py-4">
        <Modal.Title className="">
          <span className="">{t("UpdateAccount")}</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-light pt-4 ">
        <div className="d-flex justify-content-center">
          <div
            className="p-3"
            style={{ backgroundColor: "#F2F3F7", borderRadius: "30px" }}
          >
            <div className="bg-white p-4 " style={{ borderRadius: "30px" }}>
              <img
                src={AppIcons.icCheck}
                style={{ width: "70px", height: "70px" }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <span
            className="font-weight-bold text-center"
            style={{ color: "#4A5677" }}
          >
            {t("SureUpdateAccount")}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div className="col-6">
            <a
              className="w-100 btn font-weight-bold text-white"
              style={loading ? { backgroundColor: "grey" } : { backgroundColor: "#2AB448" }}
              onClick={loading ? null : props.submitOnclick}
            >
              {loading === false ? null : (
                <span className="spinner-border spinner-border-sm text-light mr-2"></span>
              )}
              {t("Save")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalUpdateAccount;
