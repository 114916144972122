import React from 'react';
import 'rc-slider/assets/index.css';
import ReactPlayer from "react-player";
import {Modal, ModalBody, ModalFooter} from "reactstrap";

export default function PreviewAudio({show, toggle, url, title}) {

  return <Modal
    style={{maxWidth: 500}}
    centered={true}
    isOpen={show}
    toggle={toggle}
    className={'w-100'}
  >
    <ModalBody className="p-0">
      {!!title &&
        <div
          className="text-center font-weight-boldest text-uppercase border-bottom text-truncate"
          style={{padding: 20}}
          title={title}>
          {title}
        </div>}
      <div style={{padding: 20}} className="border-bottom">
        <ReactPlayer
          key={url}
          url={url}
          playing={true}
          controls={true}
          height={40}
          width={'100%'}
          config={{
            file: {
              forceAudio: true
            }
          }}
        />
      </div>
    </ModalBody>
    <ModalFooter style={{padding: "10px 20px"}}>
      <button
        onClick={toggle}
        style={{height: 40}}
        className="btn btn-secondary border w-100 m-0"
      >
        Hủy
      </button>
    </ModalFooter>
  </Modal>
}
