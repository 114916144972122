import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import styles from "./Modal.module.scss";

const cx = classNames.bind(styles);

function ModalDelete({ show, onSubmit, onCancel, onHide }) {

    const { t } = useTranslation();

    return (
        <Modal
            className={cx("delete")}
            show={show}
            // size={"sm"}
            onHide={onHide}
            centered={true}>
            <div className={cx("title")}>
                {t("DeleteCategory")}
            </div>
            <div className={cx("body")}>
                <FontAwesomeIcon icon={faTrashCan} className={cx("icon-trash")} />
                <div className={cx("confirm")}>{t("ConfirmDeleteCategory")}</div>
            </div>
            <div className={cx("action")}>
                <button
                    className={cx("btn")}
                    onClick={onCancel}>
                    {t("Cancel")}
                </button>
                <button
                    className={cx("btn")}
                    onClick={onSubmit}>
                    {t("Delete")}
                </button>
            </div>
        </Modal>
    );
}

function ModalAdd({ show, onSubmit, onCancel, onHide }) {
    const { t } = useTranslation();

    const [category, setCategory] = useState("");
    const ref = useRef();

    useEffect(() => {
        if (show) {
            if (category.length > 0) {
                setCategory("");
            }
        }
        ref.current?.focus();
    }, [show]);

    return (
        <Modal
            className={cx("add")}
            show={show}
            onHide={onHide}
            // size={"lg"}
            centered={true}>
            <div className={cx("title")}>
                {t("AddCategory")}
            </div>
            <div className={cx("body")}>
                <div className={cx("span")}>
                    {t("CategoryName")}
                </div>
                <div className={cx("border")}>
                    <input
                        ref={ref}
                        className={cx("input")}
                        value={category}
                        onChange={e => setCategory(e.target.value)}
                        onKeyDown={e => {
                            if (e.key == "Enter") {
                                if (category.length == 0) {
                                    return;
                                }
                                onSubmit(category)
                            }
                        }} />
                </div>
            </div>
            <div className={cx("action")}>
                <button
                    className={cx("btn")}
                    onClick={onCancel}>
                    {t("Cancel")}
                </button>
                <button
                    className={cx("btn")}
                    style={{
                        opacity: category.length > 0 ? undefined : 0.8,
                        cursor: category.length > 0 ? 'pointer' : "default"
                    }}
                    onClick={() => {
                        if (category.length == 0) {
                            return;
                        }
                        onSubmit(category);
                    }}>
                    {t("Confirm")}
                </button>
            </div>
        </Modal>
    );
}

function ModalEdit({ show, onCancel, onSubmit, onDelete, onHide, currentCategory }) {
    const { t } = useTranslation();

    const [category, setCategory] = useState("");
    const ref = useRef();

    useEffect(() => {
        if (currentCategory.name) {
            if (currentCategory.name != category) {
                setCategory(currentCategory.name)
            }
        }
        ref.current?.focus();
    }, [show]);

    return (
        <Modal
            className={cx("add", "edit")}
            show={show}
            // size={"lg"}
            onHide={onHide}
            centered={true}>
            <div className={cx("title")}>
                {t("CategoryInfor")}
            </div>
            <div className={cx("body")}>
                <div className={cx("span")}>
                    {t("CategoryName")}
                </div>
                <div className={cx("border")}>
                    <input
                        ref={ref}
                        className={cx("input")}
                        value={category}
                        onChange={e => setCategory(e.target.value)}
                        onKeyDown={e => {
                            if (e.key == "Enter") {
                                if (category == currentCategory.name || category.length == 0) {
                                    return;
                                }
                                onSubmit(category);
                            }
                        }} />
                </div>
            </div>
            <div className={cx("action")}>
                <button
                    className={cx("btn")}
                    onClick={onCancel}>
                    {t("Cancel")}
                </button>
                <button
                    className={cx("btn")}
                    onClick={onDelete}>
                    {t("Delete")}
                </button>
                <button
                    className={cx("btn")}
                    style={{
                        opacity: (category == currentCategory.name || category.length == 0) ?
                            0.7 : undefined,
                        cursor: (category == currentCategory.name || category.length == 0) ? 'default' : "pointer"
                    }}
                    onClick={() => {
                        if (category == currentCategory.name || category.length == 0) {
                            return;
                        }
                        onSubmit(category)
                    }}>
                    {t("Confirm")}
                </button>
            </div>
        </Modal>
    );
}

export {
    ModalDelete,
    ModalAdd,
    ModalEdit
}