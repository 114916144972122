import axiosClient from "./axiosClient";

const categoryApi = {
    getCategory: (params) => {
        const url = `/categories/search`;
        return axiosClient.post(url, params)
    },

    createCaegory: (params) => {
        const url = `/categories/create`;
        return axiosClient.post(url, params);
    },

    editCategory: (params) => {
        const url = `/categories/edit`;
        return axiosClient.put(url, params);
    },

    deleteCategory: (params) => {
        const url = `/categories/delete`;
        return axiosClient.delete(url, { data: params });
    }
}

export default categoryApi