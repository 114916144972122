import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";
import UserHelper from "general/helpers/UserHelper";
import Utils from "general/utils/Utils";
import store from "libs/storage/store";
import React from "react";
import { Redirect, Route } from "react-router-dom";

// Route can dang nhap
function PrivateRoute(props) {
  const isAuth = !Utils.isObjectEmpty(localStorage.getItem(PreferenceKeys.refreshToken)) && !Utils.isObjectEmpty(localStorage.getItem(PreferenceKeys.accessToken));
  // debugger
  return isAuth
  ? <Route {...props} />
  : <Redirect to='/auth/sign-in' />
}
export default PrivateRoute;
