import PreferenceKeys from "./constants/PreferenceKeys";

const Global = {
  animationIn: "fadeIn",
  animationOut: "fadeOut",
  animationInDuration: 2000,
  animationOutDuration: 2000,
  captchaToken: "",
  gActivePath: "",
  gNeedToRefreshUserList: false,
  gNeedToRefreshMemberList: false,
  gNeedToRefreshCourseList: false,
  gNeedToRefreshCategoryList: false,
  gNeedToRefreshBookList: false,
  gNeedToRefreshVideoList: false,
  gNeedToRefreshArticleList: false,
  gNeedToRefreshAcademyData: false,
  urlRegex:
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
  gDefaultPagination: 10,
  gAvailablePagination: [5, 10, 20],
  gNeedToRefreshFaqList: false,
  gNeedToRefreshFeaturedReviewList: false,
  gMaximumFileUploadSize: 100 * 1024 * 1024, //in bit, default is 100MB

  gFiltersUserList: {
    page: 0,
    limit: 30,
    q: "",
    accessLevel: -1,
  },
  gFiltersMemberList: {
    page: 0,
    limit: 30,
    q: "",
    accessLevel: -1,
  },
  gFiltersCourseList: {
    page: 0,
    limit: 30,
    q: "",
    accessLevel: -1,
  },
  gFiltersBookList: {
    page: 0,
    limit: 30,
    q: "",
  },
  gFiltersCategoryList: {
    page: 0,
    limit: 30,
    searchKey: "",
  },
  gFiltersArticleList: {
    page: 0,
    limit: 30,
    q: "",
  },
  gFiltersVideoList: {
    page: 0,
    limit: 30,
    q: "",
  },
  gFiltersArticleList: {
    page: 0,
    limit: 30,
    q: "",
  },
  gFiltersFaqList: {
    page: 0,
    limit: 30,
    searchKey: "",
  },
  gFiltersFeaturedReviewList: {
    page: 0,
    limit: 30,
    searchKey: "",
  },
  refreshToken: localStorage.getItem(PreferenceKeys.refreshToken),
  accessToken: localStorage.getItem(PreferenceKeys.accessToken),
  initialRefreshToken: "",
};

export default Global;
