import React, { useEffect, useRef, useState } from "react";
import AppPage from "components/AppPage";
import { useTranslation } from "react-i18next";
import NavBar from "components/NavBar";
import { AppIcons, AppImages } from "general/constants/AppResource";
import AppCard from "components/AppCard";
import ModalCreateGroup from "../../components/ModalCreateGroup";
import TableListGroups from "pages/GroupManager/components/TableListGroups";
import { useSelector, useDispatch } from "react-redux";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import groupApi from "api/groupApi";
import ModalDeleteGroup from "../../components/ModalDeleteGroup";
import ToastHelper from "general/helpers/ToastHelper";
import SelectArea from "components/SelectArea/SelectArea";
import areaApi from "api/areaApi";
import { setAllAreas } from "../../../AreaManager/areaSlice";
import { findAreaById } from "general/utils/Utils";
function GroupManagerHomeScreen(props) {
  // MARK: --- Params ---
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalCreateGroupShowing, setModalCreateGroupShowing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [modalDeleteGroupShowing, setModalDeleteGroupShowing] = useState(false);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(10);
  const [total, setTotal] = useState(20);
  const [order, setOrder] = useState(["id", "asc"]);
  /// Area
  const [areasByUser, setAreasByUser] = useState([]);
  const [currentArea, setCurrentArea] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [currentAreaId, setCurrentAreaId] = useState(0);
  const currentUser = useSelector((state) => state.auth.current);
  const [isLoading, setIsLoading] = useState(true);

  // MARK: --- Functions ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    
    }
  }, [currentUser, allAreas]);

  async function getGroups() {
    setIsLoading(true);
    const res = await groupApi.getGroups({
      filters: {
        areaId: currentAreaId > 0 ? currentAreaId : 1,
      },
      order: order,
      query: searchText,
      page: page - 1,
      limit: step,
    });
    const { result, groups } = res?.data;
    // console.log(groups)
    if (result == "success") {
      console.log(res?.data.groups);
      setGroups(groups);
      setTotal(res?.data.total);
    }
    setIsLoading(false);
  }
  function handleDeleteGroups() {
    groupApi.deleteGroup(selectedGroups).then((res) => {
      const { result } = res.data;
      // console.log(res.data)
      if (result == "success") {
        ToastHelper.showSuccess(t("DeletedGroupSuccessfully"));
        setSelectedGroups([]);
        setModalDeleteGroupShowing(false);
        getGroups();
      }
    });
  }

  function changeOrder(orderBy) {
    if (order[1] == "asc") {
      setOrder([orderBy, "desc"]);
    } else {
      setOrder([orderBy, "asc"]);
    }
  }
  // MARK: --- Hooks ---
  
  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    getGroups();
  }, [currentAreaId, searchText, step, page, order]);

  /// Area
  async function getAreas() {
    const res = await areaApi.getAreas();
    const { result, areas } = res.data;
    if (result == "success" && areas) {
      setAreasByUser(areas);
      dispatch(setAllAreas(areas));
    }
  }
  useEffect(() => {
    getAreas();
  }, []);
  useEffect(() => {
    let areaId = selectedArea
      ? selectedArea.AreaId
      : currentUser
      ? currentUser.areaId
      : null;
    if (areaId) {
      setCurrentAreaId(areaId);
    }
  }, [selectedArea, currentUser.areaId]);
  useEffect(() => {
    if (!currentArea) {
      setCurrentArea(
        areasByUser.filter((area) => area.id == currentUser?.areaId).at(0)
      );
    }
  }, [areasByUser]);
  return (
    <AppPage
      backgroundImage={AppImages.providerBackground}
      headerTitle="Quản trị nhóm người dùng"
    >
      <NavBar navBarTitle="Quản trị nhóm người dùng"></NavBar>
      <div className="d-flex flex-column flex-column-fluid my-2">
        <AppCard
          title="Danh sách nhóm người dùng"
          buttonText={t("CreateGroup")}
          clickAdd={() => {
            setModalCreateGroupShowing(true);
          }}
          additionalHeader={
            <>
              <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                <BaseSearchBar
                  name="searchBar"
                  placeholder={t("Search")}
                  onSubmit={setSearchText}
                  className="w-100"
                />
              </div>
              <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                <p
                  className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                  style={{ color: "#4A5677" }}
                >
                  {t("Display")}
                </p>
                <SelectArea
                  onSelect={(area) => {
                    setSelectedArea(area);
                  }}
                  rootAreaId={currentUser?.areaId}
                  selectedArea={selectedArea}
                  className="flex-grow-1"
                />
              </div>
              <div className="w-100">
                {!!selectedGroups.length && (
                  <button
                    className="CardButton btn btn-light-danger mr-2 d-flex flex-row align-items-center mt-6"
                    onClick={(e) => {
                      e.preventDefault();
                      setModalDeleteGroupShowing(true);
                    }}
                  >
                    <i className="fa fa-users"></i>
                    <p
                      className="font-weight-bold text-capitalize m-0"
                      style={{ fontSize: 13 }}
                    >
                      {`${t("Delete")} ${selectedGroups.length}`}
                    </p>
                  </button>
                )}
              </div>
            </>
          }
        >
          <TableListGroups
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            groups={groups}
            setGroups={setGroups}
            total={total}
            step={step}
            setStep={setStep}
            page={page}
            setPage={setPage}
            order={order}
            changeOrder={changeOrder}
            getGroups={getGroups}
            isLoading={isLoading}
          />
        </AppCard>
      </div>

      <ModalCreateGroup
        show={modalCreateGroupShowing}
        onClose={() => {
          setModalCreateGroupShowing(false);
        }}
        onShow={() => {
          setModalCreateGroupShowing(true);
        }}
        getGroup={getGroups}
      />

      <ModalDeleteGroup
        show={modalDeleteGroupShowing}
        onClose={() => {
          setModalDeleteGroupShowing(false);
        }}
        message={t("SureDeleteGroups")}
        onSubmit={() => handleDeleteGroups()}
      />
    </AppPage>
  );
}

export default GroupManagerHomeScreen;
