import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatDuration, formatFileObj } from "../../../../general/utils/Utils";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import AudioPlayer from "../AudioPlayer";
import ConfirmModal from "../ConfirmModal";
import trashIcon from "../../../../assets/images/trash-round-bg.svg";
import { AppIcons } from "general/constants/AppResource";

const PrimaryButton = styled.label`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;

export default function ChooseSourceText({
  show = true,
  disableAll = false,
  text,
  onTextChange,
}) {
  return (
    <div
      style={{
        background: "#F6F7FB",
        display: show ? "" : "none",
      }}
    >
      <textarea
        className="form-control"
        value={text}
        onChange={(e) => onTextChange(e.target.value)}
        rows={5}
        placeholder={"Nhập nội dung bản tin"}
      />
    </div>
  );
}
