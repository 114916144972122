import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Modal, ModalBody, ModalFooter} from "reactstrap";

TimePicker.propsTypes = {
  // time: PropTypes.objectOf(moment).isRequired
  // time: PropTypes.string.isRequired
}

const PrimaryButton = styled.button`
  background-color: #5180FB !important;
  padding: 10px 10px;
  border: 1px solid #3465E6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;

  i {
    color: white;
    margin-right: 8px;
  }
`
const TimePickerDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ModalFlipper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .chevron {
    cursor: pointer;

    i {
      color: #DBE3EF;
      font-size: 40px;
      line-height: 40px;
    }
  }

  .time-label {
    color: #4A5677;
    font-size: 60px;
    line-height: 73px;
    width: 84px;
    text-align: center;
  }

  .time-unit {
    color: #9EA5BD;
    font-size: 24px;
    line-height: 30px;
  }
`

const TimePickerSeparator = styled.span`
  color: #9EA5BD;
  font-size: 50px;
  line-height: 60px;
  margin: -32px 16px 0;
`

export default function TimePicker({time, disableAll, onChangeTime, className = ''}) {
  const [showModal, setShowModal] = useState(false);
  const [pickTime, setPickTime] = useState(time);

  const addUnit = (type = 'hour', amount = 1) => {
    switch (type) {
      case "hour":
      case "minute":
        setPickTime(pickTime.clone().add(amount, type));
        break;
      case "period":
        setPickTime(pickTime.clone().add(12, 'hour'));
        break;
    }
  }

  const toggleModal = () => {
    // if (showModal) {
    //   setPickTime(pickTime);
    // }
    setShowModal(!showModal);
  }

  useEffect(() => {
    setPickTime(time.clone());
  }, [time]);

  return <>
    <TimePickerDiv
      className={`form-control ${className}`}
      style={{
        background: disableAll ? '#F3F6F9' : 'white',
        cursor: disableAll ? 'default' : 'pointer',
      }}
      disabled={disableAll}
      onClick={() => {
        if (disableAll) return;
        setShowModal(true);
      }}
    >
      <i className="far fa-clock text-reset" style={{marginRight: 4}}/>
      <span>{time ? time.format('HH:mm') : '--:--'}</span>
    </TimePickerDiv>

    <Modal
      centered={true}
      isOpen={showModal}
      toggle={toggleModal}
      style={{
        width: 350,
      }}
    >
      <ModalBody className="p-0">
        <div className="text-center font-weight-boldest text-uppercase border-bottom" style={{padding: 20}}>
          Chọn thời điểm phát
        </div>
        <div className="d-flex align-items-center" style={{padding: 20}}>
          <ModalFlipper>
            <span className="chevron" onClick={() => addUnit('hour', 1)}>
              <i className="fas fa-chevron-up"/>
            </span>
            <input
              className="time-label"
              value={pickTime.format('HH')}
              pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
              onChange={e => {
                let number = Number(e.target.value);
                if (Number.isInteger(number) && number >= 0 && number <= 23) {
                  setPickTime(pickTime.clone().set({hour: number}));
                }
              }}
            />
            <span className="time-unit">Giờ</span>
            <span className="chevron" onClick={() => addUnit('hour', -1)}>
              <i className="fas fa-chevron-down"/>
            </span>
          </ModalFlipper>
          <TimePickerSeparator>:</TimePickerSeparator>
          <ModalFlipper>
            <span className="chevron" onClick={() => addUnit('minute', 1)}>
              <i className="fas fa-chevron-up"/>
            </span>
            <input
              className="time-label"
              value={pickTime.format('mm')}
              pattern="[0-9]{2}"
              onChange={e => {
                let number = Number(e.target.value);
                if (Number.isInteger(number) && number >= 0 && number <= 59) {
                  setPickTime(pickTime.clone().set({minute: number}));
                }
              }}
            />
            <span className="time-unit">Phút</span>
            <span className="chevron" onClick={() => addUnit('minute', -1)}>
              <i className="fas fa-chevron-down"/>
            </span>
          </ModalFlipper>
          {/*<TimePickerSeparator>:</TimePickerSeparator>*/}
          {/*<ModalFlipper>*/}
          {/*  <span className="chevron" onClick={() => addUnit('period', 1)}>*/}
          {/*    <i className="fas fa-chevron-up"/>*/}
          {/*  </span>*/}
          {/*  <span className="time-label">{pickTime.format('A')}</span>*/}
          {/*  <span className="time-unit invisible">-</span>*/}
          {/*  <span className="chevron" onClick={() => addUnit('period', -1)}>*/}
          {/*    <i className="fas fa-chevron-down"/>*/}
          {/*  </span>*/}
          {/*</ModalFlipper>*/}
        </div>
      </ModalBody>
      <ModalFooter style={{padding: "10px 20px"}}>
        <div className="d-flex m-0 w-100">

          <div style={{paddingLeft: 0, paddingRight: 8}} className="col">
            <button
              onClick={toggleModal}
              style={{height: 40}}
              className="btn btn-secondary border w-100"
            >
              Hủy
            </button>
          </div>

          <div style={{paddingLeft: 8, paddingRight: 0}} className="col">
            <PrimaryButton
              onClick={() => {
                onChangeTime(pickTime.clone().startOf('minute'));
                toggleModal();
              }}
              style={{height: 40}}
              className={"w-100"}>
              Áp Dụng
            </PrimaryButton>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  </>
}
