import React, {useState} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {formatBoardBulletinFileObj} from "../../../../general/utils/Utils";

const PrimaryButton = styled.label`
  background-color: #5180fb !important;
  padding: 12px 10px;
  border: 1px solid #3465e6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`;

export default function ChooseSourceImage({
                                            show = true,
                                            disableAll = false,
                                            images,
                                            onImageChange,
                                          }) {
  const {t} = useTranslation();

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handleDeleteImage = (index) => {
    console.log("123");
    console.log(index);
    images.splice(index, 1);
    console.log(images);
    onImageChange([...images]);
  }

  // const togglePreviewModal = () => {
  //   if (showPreviewModal) {
  //     setPreviewFile(null);
  //   }
  //   setShowPreviewModal(!showPreviewModal);
  // };
  // const removeFileModalRef = useRef();

  //   useEffect(() => {
  //     return () => {
  //       images.forEach((image) => image.cleanup());
  //     };
  //   }, []);

  return (
    <div
      style={{
        background: "#F6F7FB",
        border: "1px solid #DBE3EF",
        padding: 10,
        display: show ? "" : "none",
      }}
    >
      <div
        style={{
          padding: 6,
          background: "white",
          border: "1px solid #DBE3EF",
          borderRadius: 6,
        }}
      >
        <PrimaryButton
          disabled={disableAll}
          style={{
            opacity: disableAll ? 0.8 : 1,
            cursor: disableAll ? "default" : "pointer",
          }}
        >
          <i className={"fas fa-file-upload"}/>
          {t("BulletinUploadFile")}
          <input
            type="file"
            accept=".png, .jpg, .jpg"
            className="d-none"
            multiple={true}
            onChange={async (e) => {
              if (e.target.files && e.target.files.length) {
                let newImages = [];
                console.log(e.target.files);
                console.log(images);
                for (const file of Array.from(e.target.files)) {
                  newImages.push(formatBoardBulletinFileObj(file));
                }
                onImageChange([...images, ...newImages]);
                console.log(images);
              }
              e.target.value = "";
            }}
            disabled={disableAll}
          />
        </PrimaryButton>
        {images.length > 0 ? (
          <span
            className="Regular_13"
            style={{
              marginLeft: 12,
            }}
          >
            Đã chọn {images.length} ảnh
          </span>
        ) : (
          <span 
            className="Regular_13"
            style={{
              marginLeft: 12,
            }}
          >
            Chưa có hình ảnh được chọn
          </span>
        )}
      </div>
      <div className="container px-2">
        <div className="row">
          {images.length > 0 && (
            <>
              {images.map((image, index) => (
                <div key={index} className="col-md-4 bg-light mt-2 p-2">
                  <div
                    className="col-12 border bg-white position-relative"
                    style={{padding: "4px"}}
                  >
                    <img
                      src={image.src}
                      className="m-0"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                        aspectRatio: "3/2",
                        objectPosition: "center",
                      }}
                    />
                    <div
                      className=" rounded bg-white position-absolute d-flex justify-content-center align-items-center"
                      style={{width: "32px", height: "30px", right: "15px", bottom: "15px", cursor: "pointer"}}
                      onClick={() => handleDeleteImage(index)}
                    >
                      <i
                        className="far fa-trash-alt"
                        style={{color: "#E64135"}}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {/* <ConfirmModal
        ref={removeFileModalRef}
        confirmButtonClass={"btn btn-danger"}
        confirmButtonText={"Xác Nhận"}
      /> */}
    </div>
  );
}
