import React from "react";
import { Field, ErrorMessage } from "formik";
import "./styles.scss";
import { GrFormViewHide } from "react-icons/gr";
import TextError from "pages/Login/components/inputs/TextError";

function PassWordInput(props) {

  const { label, name, type, ...rest } = props;
  const [isHiddenPassword, setIsHiddenPassword] = React.useState(true);
  return (
    <div className="modal-change-password-control mb-3">
      <label htmlFor={name}>{label}</label>
      <div className="input-field">
        <Field id={name} name={name} type={isHiddenPassword ? "password" : "text"} {...rest} />
        {/* {name === "password" ? ( */}
          <div className="password-toggle-container" onClick={()=>{setIsHiddenPassword(!isHiddenPassword)}}>
            {isHiddenPassword ? <i className="fas fa-eye bg-white"></i> : <i className="fas fa-eye-slash bg-white"></i>}
          </div>
        {/* ) : null} */}
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default PassWordInput;
