import React, { forwardRef, useImperativeHandle } from "react";
import OtpInput from "react-otp-input";
import PropTypes from "prop-types";

// OTPInput.propTypes = {};

const OTPInput = forwardRef((props, ref) => {
  let backgroundColor = props.backgroundColor ? props.backgroundColor : null;
  let borderColor = props.borderColor ? props.borderColor : "#E4E6EF";
  const [otp, setOtp] = React.useState("");
  useImperativeHandle(ref, () => ({
    resetOTP() {
      setOtp(null);
    },
  }));
  return (
    <OtpInput
      value={otp}
      onChange={(OTP) => {
        console.log(OTP);
        setOtp(OTP);
        if (OTP.length === 6) {
          props.onSubmit({}, OTP);
        }
        props.func(OTP);
      }}
      shouldAutoFocus={true}
      numInputs={6}
      inputStyle={{
        fontSize: "24px",
        width: "36px",
        height: "36px",
        margin: "4px",
        borderTop: "0px",
        borderLeft: "0px",
        borderRight: "0px",
        outline: "none",
        borderColor: borderColor,
        backgroundColor: backgroundColor,
      }}
      containerStyle={{
        margin: "10px",
        padding: "10px",
        justifyContent: "center",
      }}
      isInputNum
    />
  );
});

export default OTPInput;
