import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import ProviderManagerHomeScreen from "./pages/ProviderManagerHomeScreen";

function ProviderManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={ProviderManagerHomeScreen} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ProviderManager;
