import React from "react";
import PropTypes from "prop-types";

LoadingOverlay.propTypes = {
  message: PropTypes.string,
};

LoadingOverlay.defaultProps = {
  message: "",
};

function LoadingOverlay(props) {
  const { message } = props;

  return (
    <div className="overlay-layer rounded zindex-2">
      <div className="spinner spinner-right spinner-primary pr-12 py-3 pl-4 rounded">
        <span className="text-secondary">{message}</span>
      </div>
    </div>
  );
}

export default LoadingOverlay;
