const customDataTableStyle = {
  rows: {
    style: {
      minHeight: "46.5px", // override the row height
      fontSize: "1rem",
      color: "#3F4254",
      paddingLeft: "0px",
      "&:not(:last-of-type)": {
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "#ebedf3",
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: "#F3F6F9 ",
    },
  },
  headRow: {
    style: {
      borderBottomColor: "#ebedf3",
    },
  },
  cells: {
    style: {
      paddingLeft: "0px", // override the cell padding for data cells
      paddingRight: "8px",
      // marginLeft: 'px'
    },
  },
  headCells: {
    style: {
      fontSize: "0.9rem",
      fontWeight: 600,
      paddingLeft: "0px",
      paddingRight: "0px",
      color: "#B5B5C3",
      textTransform: "uppercase",
      // letterSpacing: "0.1rem",
      lineHeight: "1.5 !important",
    },
    activeSortStyle: {
      "&:focus": {
        outline: "none",
        color: "#3699FF",
      },
      "&:not(focus)": {
        color: "#3699FF",
      },
      "&:hover:not(:focus)": {
        color: "#3699FF",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        outline: "none",
        color: "#B5B5C3",
      },
      "&:hover": {
        color: "#3699FF",
      },
    },
  },
};

export default customDataTableStyle;
