import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

ChooseSourceTypeButton.propsType = {
  title: PropTypes.string,
  active: PropTypes.bool,
  btnProps: PropTypes.object,
}
const Button = styled.button`
  background: white;
  border: 1px solid #DBE3EF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 12px;
  padding: 8px 10px;
  font-weight: 600;
  line-height: 16px;
  color: #4A5677;

  i {
    color: inherit;
  }

  &.active {
    color: #5180FB;
    border: 1px solid #5180FB
  }
  
  &:disabled{
    background: #F3F6F9;
  }
`
export default function ChooseSourceTypeButton({title, active, btnProps}) {
  const style = active ? {color: "#5180FB", border: "1px solid #5180FB"} : {};
  return <Button className={``} {...btnProps}
    style={style}
  >
    {title}
  </Button>
}
