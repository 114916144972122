import React, {useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

let TabContainer = styled.div`

  border-bottom: 1px solid #DBE3EF;

  > div {
    position: relative;
    display: inline-block;
    padding: 14px 6px;
    text-decoration: none;
    z-index: 1;


    width: 160px;
    max-width: calc(50% - 20px);
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    text-align: center;

    &:not(.active) {
      cursor: pointer;
    }

    &:not(:first-child) {
      margin-left: 10px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border: 1px solid #DBE3EF;
      border-bottom: none;
      border-radius: 0.4em 0.4em 0 0;
      background: #F6F7FB;
      transform: scale(1.2, 1.3) perspective(0.5em) rotateX(2deg);
      transform-origin: bottom left;
    }

    &.active {
      z-index: 2;

      &:before {
        margin-bottom: -1px;
        border-bottom: 1px solid white;
        background-color: white;
      }
    }
  }
`

CurvedTab.propsTypes = {
  titles: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number,
  onChangeTab: PropTypes.func,
}
export default function CurvedTab({titles, activeIndex = 0, onChangeTab}) {
  return <TabContainer className="position-relative text-nowrap">
    {titles.map((title, key) =>
      <div
        key={key}
        className={`${key == activeIndex ? "active" : ""}`}
        onClick={() => {
          onChangeTab(key);
        }}
        title={title}
      >
                <span className="text-truncate d-block user-select-none">
                    {title}
                </span>
      </div>
    )}
  </TabContainer>
}
