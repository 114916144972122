import React from 'react';
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError';

const OneCheck = (props) => {
    const { label, name, ...rest } = props;
    return (
        <div className="login-form-control login-checkbox">
            <Field type='checkbox' id={name} name={name} {...rest} />
            <label htmlFor={name}>{label}</label>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default OneCheck