import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppIcons } from "general/constants/AppResource";

function ModalDeleteGroup(props) {
  // MARK: --- Params ---
  const { show, onClose, length, onSubmit, message } = props;
  const { t } = useTranslation();

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className=""
      size="sm"
      //   style={{width:"300px"}}
      dialogClassName="modal-50w"
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Modal.Header className="d-flex justify-content-center py-4">
        <Modal.Title className="">
          <span className="">{t("DeleteGroup")}</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="bg-light ">
        <div className="d-flex justify-content-center">
          <div
            className="p-3"
            style={{ backgroundColor: "#F2F3F7", borderRadius: "30px" }}
          >
            <div className="bg-white p-4 " style={{ borderRadius: "30px" }}>
              <img
                src={AppIcons.icTrashCan}
                style={{ width: "70px", height: "70px" }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
            <span className="font-weight-bold" style={{color:"#4A5677"}}>{t('SureDeleteGroups')}</span>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div className="col-6">
            <a
              className="w-100 btn btn-danger font-weight-bold text-white"
              onClick={() => {onSubmit(), handleClose()}}
            >
              {t("Delete")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDeleteGroup