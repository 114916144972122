import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";
InfoComponent.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  icon: PropTypes.string,
};

InfoComponent.defaultProps = {
  title: null,
  info: null,
  icon: null,
};
function InfoComponent(props) {
  const { title, info, icon } = props;

  return (
    <div
      className="Card border-0 bg-white d-flex"
      style={{
        height: 120,
        width: "100%",
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: 80 }}
      >
        <img
          className="border-0"
          src={icon}
          style={{ width: 60, height: 60 }}
        ></img>
      </div>
      <div className="d-flex flex-column " style={{ flex: 1 }}>
        <div className="flex-grow-1 d-flex flex-column justify-content-end">
          <p
            style={{
              fontSize: 18,
              fontFamily: "Inter",
              fontWeight: 700,
              color: "#4A5677",
            }}
          >
            {title}
          </p>
        </div>

        <p
          className="flex-grow-1"
          style={{
            fontSize: 28,
            fontFamily: "Inter",
            fontWeight: 700,
            color: "#5180FB",
          }}
        >
          {info}
        </p>
      </div>
    </div>
  );
}

export default InfoComponent;
