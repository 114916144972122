import { AppIcons } from "general/constants/AppResource";
import ForgotPasswordForm from "pages/Login/components/ForgotPasswordInput";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/loginForm/LoginForm";
import "./styles.scss";
function ForgotPassword(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="login-page">
      <div
        className="container h-100 d-flex flex-column"
        style={{ minHeight: "100vh" }}
      >
        <header style={{}}>
          <div className="login-header-wrapper">
            <div className="logo">
              <img src={AppIcons.icLogoLogin} alt="logo" />
            </div>
            <div className="login-title">{t("loginTitle")}</div>
          </div>
        </header>
        <main style={{}}>
          <div className="login-form">
            <ForgotPasswordForm redirectLogin={props.location?.state?.from} />                                        
          </div>         
        </main>
      </div>
    </div>
  );
}

export default ForgotPassword;
