import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import Global from "general/Global";
import {formatDuration} from "general/utils/Utils";

import useSort from "hooks/useSort";

import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";

import "./index.scss";

import {thunkGetAreas} from "pages/AreaManager/areaSlice";
import {useHistory} from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import ConfirmModal from "../../pages/BulletinManager/components/ConfirmModal";
import trashIcon from "../../assets/images/trash-round-bg.svg";
import moment from "moment";
import _ from "lodash";
import bulletinApi from "../../api/bulletinApi";
import bulletinBoardApi from "../../api/bulletinBoardApi";

/**
 * Props:
 * * getEBulletinsAPI (`func`): hàm nhận query-string từ bên trong để gọi api
 * * searchText (`string`): string to search on
 *
 * Example:
 * ```js
 * import stationApi from '...';
 *
 * <DevicesTable
 getEBulletinsAPI={(params) => stationApi.getStationDevices(station.id, params)}
 searchText={searchText}
 />
 * ```
 */

function EBulletinHistoryTable(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    getEBulletinsAPI,
    searchText,
    currentAreaId,
    tableName,
    selectedItems: selectedBulletins,
    onSelectedItemsChange: setSelectedBulletins,
    isBoardBulletin = false,
  } = props;
  const history = useHistory();
  const confirmModalRef = useRef();
  const areas = useSelector((state) => state.area.allAreas);

  const [isLoading, setIsLoading] = useState(true);
  const [bulletinTotal, setBulletinTotal] = useState(0);
  const [bulletins, setBulletins] = useState([]);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);

  const {directionIndicator, sortOnClick} = useSort(
    bulletins,
    (sortedArray) => setBulletins(sortedArray)
  );

  // MARK: --- Functions ---

  async function getBulletins() {
    setIsLoading(true);
    const res = await getEBulletinsAPI({
      page: page - 1,
      limit: step,
      q: searchText,
      // orderField: "connection",
      // orderMode: "DESC",
    });
    if (res && res.data.result === "success") {
      setBulletins(res.data.eBulletinHistories);
      setBulletinTotal(res.data.total);
      if (res.data.eBulletinHistories.length === 0 && page > 1) {
        setPage(page - 1);
      }
    } else {
      setBulletins([]);
      setBulletinTotal(0);
    }
    setIsLoading(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (areas.length === 0) {
      dispatch(thunkGetAreas());
    }
  }, []);

  useEffect(() => {
    getBulletins();
  }, [searchText, page, step, currentAreaId, tableName]);

  function handleSetting(bulletin) {
    if (bulletin.buletinId) {
      history.push(
        `/bulletins/edit/speaker/${bulletin.buletinId}?history_id=${bulletin.id}`
      );
    }
    if (bulletin.bulletinId) {
      if (isBoardBulletin) return history.push(`/bulletins/edit/bulletin-board/${bulletin.bulletinId}`);
    }
  }

  async function handleStop(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Dừng bản tin`,
        <div style={{padding: 16}} className="text-center">
          <img src={trashIcon} style={{marginBottom: 20}}/>
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn dừng bản tin
                <span className="text-danger">
                  &nbsp;{bulletin["radioBulletin.title"] || bulletin?.eBulletin?.title}
                </span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn dừng {selectedBulletins.length} bản tin đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let stopCall = isBoardBulletin ? bulletinBoardApi.stopPlayingBulletins : bulletinApi.stopPlayingBulletins
      await stopCall(batchDelete ? selectedBulletins : [bulletin.id])
        .then((data) => {
          if (data.data.status === 0) {
            ToastHelper.showSuccess("Dừng bản tin thành công");
            getBulletins();
            if (batchDelete) {
              setSelectedBulletins([]);
            }
          }
        });
    } catch (e) {
    }
  }

  async function handleDelete(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Xóa lịch sử phát`,
        <div style={{padding: 16}} className="text-center">
          <img src={trashIcon} style={{marginBottom: 20}}/>
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn xóa lịch sử phát
                <span className="text-danger">
                  &nbsp;{bulletin["radioBulletin.title"] || bulletin?.eBulletin?.title}
                </span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn xóa {selectedBulletins.length} lịch sử phát
                đã chọn?
              </>
            )}
          </p>
        </div>
      );

      let deleteCall = isBoardBulletin ? bulletinBoardApi.deleteHistoryBulletins : bulletinApi.deleteHistoryBulletins;
      await deleteCall(batchDelete ? selectedBulletins : [bulletin.id])
        .then((data) => {
          if (data.data.status === 0) {
            ToastHelper.showSuccess("Xóa lịch sử phát thành công");
            getBulletins();
            if (batchDelete) {
              setSelectedBulletins([]);
            }
          }
        });
    } catch (e) {
    }
  }

  return (
    <>
      <DataTable
        className="bulletin-datatable"
        cols={[1, 39, 10, 10, 10, 10, 10, 10]}
        headItems={[
          // Name
          <CheckBox
            checked={selectedBulletins.length == bulletins.length}
            onCheck={() => {
              // setSelectedBulletins(_.unionBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.union(selectedBulletins, _.map(bulletins, "id"))
              );
            }}
            onUncheck={() => {
              // setSelectedBulletins(_.differenceBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.difference(selectedBulletins, _.map(bulletins, "id"))
              );
            }}
          />,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("title")}
          >
            <p>
              Tin
              {directionIndicator("title")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("startTime")}
          >
            <p>
              Ngày Phát
              {directionIndicator("startTime")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("startTime")}
          >
            <p>
              Thời Điểm Phát
              {directionIndicator("startTime")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("duration")}
          >
            <p>
              Thời Lượng
              {directionIndicator("duration")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("radioBulletin.areaId")}
          >
            <p>
              Khu Vực
              {directionIndicator("radioBulletin.areaId")}
            </p>
          </div>,

          // Area
          <div className="w-100" onClick={() => sortOnClick("status")}>
            <p>
              TRẠNG THÁI
              {directionIndicator("status")}
            </p>
          </div>,
          t("Manage"),
        ]}
        // row
        dataItems={bulletins.map((item) => {
          let startTime = moment(item.startTime),
            endTime = item.endTime ? moment(item.endTime) : null;
          let isRowCheck = selectedBulletins.includes(item.id);
          return {
            isRowCheck: isRowCheck,
            data: [
              {
                comp: (
                  <CheckBox
                    checked={isRowCheck}
                    onCheck={() =>
                      setSelectedBulletins([...selectedBulletins, item.id])
                    }
                    onUncheck={() => {
                      let currentSelectedBulletins = [...selectedBulletins];
                      let itemIndex = currentSelectedBulletins.indexOf(item.id);
                      if (itemIndex !== -1) {
                        currentSelectedBulletins.splice(itemIndex, 1);
                        setSelectedBulletins(currentSelectedBulletins);
                      }
                    }}
                  />
                ),
              },
              {
                comp: (
                  <div
                    key={item.id}
                    className="custom-row-checkbox cursor-pointer"
                  >
                    <div
                      className="Bold_13 text-primary text-emphasis q-max-line-1"
                      title={item["radioBulletin.title"]}
                      style={{marginBottom: 4}}
                    >
                      {item["radioBulletin.title"] || item?.eBulletin?.title}
                    </div>
                    <div className="Regular_13 q-max-line-3">
                      {item["radioBulletin.description"]}
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item);
                },
              },
              {
                comp: (
                  <p className="Regular_13 text-nowrap">
                    {startTime.format("DD/MM/YYYY")}
                  </p>
                ),
              },
              {
                comp: (
                  <div>
                    <div className="Regular_13 text-nowrap">
                      Từ&nbsp;
                      <span style={{color: "#3ACE5A"}}>
                        {startTime.format("hh:mm:ss")}
                      </span>
                    </div>
                    {!!endTime && (
                      <div className="Regular_13 text-nowrap">
                        Đến&nbsp;
                        <span className="text-danger">
                          {endTime.format("hh:mm:ss")}
                        </span>
                      </div>
                    )}
                  </div>
                ),
              },
              {
                comp: (
                  <p className="Regular_13 text-nowrap">
                    {formatDuration(item.duration)}
                  </p>
                ),
              },
              {
                comp: (
                  <p className="Regular_13 text-nowrap">
                    {item["radioBulletin.area.name"] || item?.eBulletin?.area?.name}
                  </p>
                ),
              },
              {
                comp: !!item.status && (
                  <div
                    className={`bulletin-status-badge bulletin-status-badge-${item.status.toLowerCase()}`}
                  >
                    {item.status === "PLAYING" && "Đang Phát"}
                    {item.status === "PLAYED" && "Đã Phát"}
                    {item.status === "STOPPED" && "Đã Dừng"}
                  </div>
                ),
              },
              {
                comp: (
                  <div className="Manage">
                    <i
                      className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                      onClick={() => {
                        handleSetting(item);
                      }}
                    ></i>
                    {item.status === "PLAYING" ? (
                      <i
                        className="fas fa-stop-circle cursor-pointer text-danger"
                        onClick={() => handleStop(item)}
                      />
                    ) : (
                      <i
                        className="fas fa-trash-alt cursor-pointer text-hover-danger"
                        onClick={() => handleDelete(item)}
                      />
                    )}
                  </div>
                ),
              },
            ],
          };
        })}
        step={step}
        onRefresh={getBulletins}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white rounded-bottom"
        total={bulletinTotal}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
      <ConfirmModal
        ref={confirmModalRef}
        confirmButtonClass={"btn btn-danger"}
        confirmButtonText={"Xác Nhận"}
      />
    </>
  );
}

export default EBulletinHistoryTable;
