import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import _ from "lodash";
import ReactPlayer from 'react-player';
import PreviewAudio from "../PreviewAudio";
import { SelectSort } from 'pages/Category/components/SelectSort';

const PrimaryButton = styled.button`
  background-color: #5180FB !important;
  padding: 12px 10px;
  border: 1px solid #3465E6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 0;
  cursor: pointer;

  i {
    color: white;
    margin-right: 8px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
`

let relayOptions = [];

export default function ChooseSourceRelay(
  {show, disableAll, relaySource, onRelaySourceChange,}
) {
  const relaySources = useSelector(state => state.bulletin.relaySources);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if(relaySources) relayOptions = relaySources.map(item => ({...item, value: item.id, display: item.name}))
  },[relaySources])

  return <div style={{
    display: show ? "" : "none",
  }}>
    <div
      className="d-flex align-items-center text-nowrap"
      style={{
        padding: "12px 0",
        marginBottom: 12,
      }}>
      <label htmlFor="select-relay-source" className="Regular_13 mb-0" style={{marginRight: 12}}>Chọn/Nhập Kênh</label>
      <SelectSort
        className="flex-grow-1"
        selections={relayOptions}
        disable={disableAll}
        currentValue={_.find(relayOptions, {id: relaySource?.id})?.display ?? "Chọn"}
        onSelect={(item) => {
          let selectedRelaySource = _.find(relaySources, {id: Number(item.id)});
          let currentRelaySource = {...relaySource};
          if (selectedRelaySource) {
            currentRelaySource = selectedRelaySource;
          } else {
            currentRelaySource.id = null;
          }
          onRelaySourceChange(currentRelaySource);
        }}
      />
    </div>

    <table className="table table-bordered mb-0">
      <thead>
      <tr style={{background: "#F6F7FB",}}>
        <th width="25%" style={{padding: 10}}>Loại Kênh</th>
        <th width="25%" style={{padding: 10}}>Tên Kênh</th>
        <th width="50%" style={{padding: 10}}>Đường dẫn/Tần số</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td style={{padding: 10}}>{relaySource.type}</td>
        <td style={{padding: 10}}>{relaySource.name}</td>
        <td style={{padding: 10}} className="text-break">
          {relaySource.type === 'AFM' ? relaySource.frequency : relaySource.url}
        </td>
      </tr>
      </tbody>
    </table>

    {relaySource.type === "DFM" &&
      <div style={{padding: 10, background: "#F6F7FB", margin: -10, marginTop: 10}} className="border-top">
        <button className="Bold_13 btn btn-white border w-100" onClick={() => setPlaying(true)}>
          <i className="fas fa-headphones" style={{marginRight: 8}}/>
          Nghe Thử
        </button>
        <PreviewAudio
          url={relaySource.url}
          title={relaySource.name}
          show={playing}
          toggle={() => {
            setPlaying(!playing)
          }}
        />
      </div>}
  </div>
}
