import {toast} from "react-toastify";

const ToastHelper = {
  showSuccess: (message) => {
    toast.success(message);
  },

  showError: (message) => {
    if (Array.isArray(message)) {
      toast.error(
        <>
          {message.map(mess =>
            <span key={mess}>{mess}<br/></span>
          )}
        </>
      )
    } else {
      toast.error(message);
    }
  },
};

export default ToastHelper;
