import React, {Component, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

const PrimaryButton = styled.button`
  background-color: #5180FB;
  padding: 12px 10px;
  border: 1px solid #3465E6;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;
  margin-bottom: 0;
  cursor: pointer;

  i {
    color: white;
    margin-right: 8px;
  }
`

export default class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      title: "",
      content: "",
    };

    this.promiseInfo = {};
    this.toggle = this.toggle.bind(this);
  }

  show = async (title = "", content = "") => {
    return new Promise((resolve, reject) => {
      this.promiseInfo = {
        resolve,
        reject
      };
      this.setState({
        show: true,
        title,
        content,
      });
    });
  };

  toggle = (event, confirm = false) => {
    const {resolve, reject} = this.promiseInfo;
    if (this.state.show) {
      if (confirm) {
        resolve();
      } else {
        reject();
      }
    }
    this.setState({
      show: !this.state.show,
    });

  }

  render() {
    const {show} = this.state;
    return <Modal
      style={{maxWidth: 400}}
      centered={true}
      isOpen={show}
      toggle={this.toggle}
      className={'w-100'}
    >
      <ModalHeader className="d-flex justify-content-center">
        <p className="Bold_13">{this.state.title}</p>
      </ModalHeader>
      <ModalBody className="p-0 bg-light py-6">
        {this.state.content}
      </ModalBody>

      <ModalFooter style={{padding: "10px 20px"}}>
        <div className="d-flex m-0 w-100">

          <div style={{paddingLeft: 0, paddingRight: 8}} className="col">
            <button
              onClick={this.toggle}
              style={{height: 40}}
              className="btn btn-secondary border w-100"
            >
              Hủy
            </button>
          </div>

          <div style={{paddingLeft: 8, paddingRight: 0}} className="col">
            <PrimaryButton
              onClick={(e) => {
                this.toggle(e, true);
              }}
              style={{height: 40}}
              className={`w-100 ${this.props.confirmButtonClass}`}>
              {this.props.confirmButtonText}
            </PrimaryButton>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  }
}
ConfirmModal.propTypes = {
  confirmButtonClass: PropTypes.string,
  confirmButtonText: PropTypes.string,
};
ConfirmModal.defaultProps = {
  confirmButtonClass: "",
  confirmButtonText: "Áp Dụng",
};
