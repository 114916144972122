const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  loadingState: false,
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
});

export default DashboardSlice.reducer;
export const { setTransactionInLast } = DashboardSlice.actions;
