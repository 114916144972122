import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import GroupManagerHomeScreen from "./screens/GroupManagerHomeScreen";

import NotFound from "components/NotFound";

function GroupManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={GroupManagerHomeScreen} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default GroupManager;
