import axiosClient from "./axiosClient";

const feedbackApi = {
    getFeedback: (params) => {
        const url = `/feedbacks/search`;
        return axiosClient.post(url, params);
    },
    answerFeedback: (params) => {
        const url = `/feedbacks/answer`;
        return axiosClient.post(url, params);
    }
}

export default feedbackApi