import React from "react";
import ChooseSourceFile from "./ChooseSourceFile";
import ChooseSourceMic from "./ChooseSourceMic";
import ChooseSourceDevice from "./ChooseSourceDevice";
import ChooseSourceDocument from "./ChooseSourceDocument";
import ChooseSourceRelay from "./ChooseSourceRelay";
import ChooseSourceImage from "./ChooseSourceImage";
import ChooseSourceVideo from "./ChooseSourceVideo";
import ChooseSourceText from "./ChooseSourceText";

export default function ChooseSource({
  sourceType,
  disableAll,
  // source file
  files,
  onFilesChange,
  // source mic
  recordFiles,
  onRecordFilesChange,
  // source device
  device,
  onDeviceChange,
  // source document
  document,
  onDocumentChange,
  documentVoice,
  onDocumentVoiceChange,
  ttsData,
  onTTSDataChange,
  //source image
  images,
  onImageChange,

  //source video
  videos,
  onVideoChange,
  // source relay
  relaySource,
  onRelaySourceChange,

  //source text
  text,
  onTextChange,
}) {
  return (
    <>
      <ChooseSourceFile
        show={sourceType === "FILE"}
        disableAll={!!disableAll}
        files={files}
        onFilesChange={onFilesChange}
      />
      <ChooseSourceMic
        show={sourceType === "MIC"}
        disableAll={!!disableAll}
        recordFiles={recordFiles}
        onRecordFilesChange={onRecordFilesChange}
      />
      <ChooseSourceDevice
        show={sourceType === "DEVICE"}
        disableAll={!!disableAll}
        device={device}
        onDeviceChange={onDeviceChange}
      />
      <ChooseSourceDocument
        show={sourceType === "DOCUMENT"}
        disableAll={!!disableAll}
        document={document}
        onDocumentChange={onDocumentChange}
        documentVoice={documentVoice}
        onDocumentVoiceChange={onDocumentVoiceChange}
        ttsData={ttsData}
        onTTSDataChange={onTTSDataChange}
      />
      <ChooseSourceRelay
        show={sourceType === "RELAY"}
        disableAll={!!disableAll}
        relaySource={relaySource}
        onRelaySourceChange={onRelaySourceChange}
      />
      {images ? (
        <ChooseSourceImage
          show={sourceType === "IMAGE"}
          disableAll={!!disableAll}
          images={images}
          onImageChange={onImageChange}
        />
      ) : null}
      {videos ? (
        <ChooseSourceVideo
          show={sourceType === "VIDEO"}
          disableAll={!!disableAll}
          videos={videos}
          onVideoChange={onVideoChange}
        />
      ) : null}

      {text ? (
        <ChooseSourceText
          show={sourceType === "TEXT"}
          disableAll={!!disableAll}
          text={text}
          onTextChange={onTextChange}
        />
      ) : null}
    </>
  );
}
