import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading";
import SearchBarNoFormik from "../SearchBarNoFormik";

import "./styles.scss";
import { AppIcons } from "general/constants/AppResource";

CardDetail.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  clickAdd: PropTypes.func,
  additionalHeader: PropTypes.element,
  isLoading: PropTypes.bool,
  bottomComponent : PropTypes.any,
};

CardDetail.defaultProps = {
  className: "",
  title: "",
  clickAdd: null,
  isLoading: false,
  bottomComponent : null
};

/**
 * - className (string)
 * - title (string)
 * - headerRight (element) : component after search and button
 * - buttonText (string) default: 'Add'. If empty wont show button
 * - additionalHeader (element): component below header
 *
 */
function CardDetail(props) {
  const {
    className,
    title,
    blueButtonText,
    whiteButtonText,
    clickAdd,
    isLoading,
    bottomComponent,
  } = props;
  const { t } = useTranslation();

  function onClick() {
    if (clickAdd) clickAdd();
  }

  return (
    <div
      className="bg-white d-flex flex-column border mt-6 mb-3"
      // style={{ zIndex: "5" }}
    >
      {/* CARD HEADER  */}
      <div className="CardHeader bg-white d-flex align-items-center border-bottom px-6">
        <p className="CardTitle Bold_13 py-5 m-0">
          {title}
        </p>
      </div>

      <div className="bg-light p-3">
        <div
          className={`bg-white w-100 d-flex align-items-center py-5 border ${className}`}
        >
          {props.children}
        </div>
      </div>

      {/* Bottom component */}
      {bottomComponent ? (
        <div className="bg-light p-3 border-bottom">
          {bottomComponent}
        </div>
      ) : null}
    </div>
  );
}

export default CardDetail;
