import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import moment from "moment";
import _ from "lodash";
import usePrevious from "../../../../hooks/usePrevious";

const PrimaryButton = styled.button`
  background-color: #5180FB !important;
  padding: 12px 10px;
  border: 1px solid #3465E6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;

  i {
    color: white;
    margin-right: 8px;
  }
`;

const DatePickerDiv = styled.div`
  padding: 12px 10px;
  color: #5180FB;
  user-select: none;
  cursor: pointer;

  i {
    margin-right: 4px;
  }
`

const Chevron = styled.span`
  padding: 20px;
  cursor: pointer;
`

const Calendar = styled.div`
  user-select: none;
`

const WeekDayRow = styled.div`
  display: flex;
  background: #F6F7FB;
  height: 50px;
  justify-content: space-evenly;

  div {
    width: 50px;
    height: 100%;
    text-align: center;
    line-height: 50px;
  }
`

const WeekDateRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DateContainer = styled.div`
  width: 50px;
  height: 44px;
  text-align: center;
  padding: 7px 10px;
  cursor: pointer;

  &:hover:not(.active) {
    div {
      background: rgba(81, 128, 251, 0.9);
      color: white;
    }
  }

  div {
    width: 100%;
    height: 100%;
    line-height: 30px;
    border-radius: 6px;
  }

  &.active {
    div {
      background: rgba(81, 128, 251);
      color: white;
    }
  }
`
/**
 * @typedef {import('moment').Moment} Moment
 */
/**
 * @param {Boolean} show
 * @param {Boolean} disableAll
 * @param {string} className
 * @param {Moment[]} dates
 * @param onChangeDates
 * @param singleDatePicker
 * @param pickDateMonth
 * @returns {JSX.Element}
 * @constructor
 */
export default function DatePicker(
  {show, disableAll, className = '', dates = [], onChangeDates, singleDatePicker = false, pickDateMonth = false}
) {
  // input label
  const [selectedDateStr, setSelectedDateStr] = useState('');
  const [showModal, setShowModal] = useState(false);
  // current modal picker month
  const [pickerMonth, setPickerMonth] = useState(moment().startOf(pickDateMonth ? 'year' : 'month'));
  // picker month's days
  const [pickerDates, setPickerDates] = useState([]);
  // selected date in modal picker
  const [selectedDates, setSelectedDates] = useState([]);

  const toggleModal = () => {
    if (disableAll) return;
    if (showModal) {
      setSelectedDates(dates);
    }
    setShowModal(!showModal);
  }

  useEffect(() => {
    // set input label
    if (singleDatePicker) {
      setSelectedDateStr(dates.length ? dates[0].format("DD/MM/YYYY") : "--/--/----");
    } else {
      setSelectedDateStr(dates.length ? dates.map((date, i) => {
        let dateFormat = 'D';
        if (!pickDateMonth) {
          if (i === dates.length - 1 || (dates[i + 1] && dates[i + 1].month() !== date.month())) {
            dateFormat = 'D/M';
          }
        }
        return date.format(dateFormat)
      }).join(',') : "Ngày trong tháng");
    }
    setSelectedDates(dates);
  }, [dates]);

  const prevSelectedDates = usePrevious(selectedDates);
  const prevPickerMonth = usePrevious(pickerMonth);
  useEffect(() => {
    if (_.isEqual(prevSelectedDates, selectedDates) && prevPickerMonth == pickerMonth) return;
    let startDate = pickerMonth.clone().startOf('month'),
      endDate = pickerMonth.clone().endOf('month').startOf('day');
    if (!pickDateMonth) {
      startDate.isoWeekday(1);
      endDate.isoWeekday(7);
    }

    let dateArr = [];
    let dateStrArr = selectedDates.map(date => date.format("DD/MM/YYYY"));
    do {
      let dateObj = startDate.clone(),
        activeIndex = dateStrArr.indexOf(dateObj.format("DD/MM/YYYY"));
      dateArr.push({
        dateObj: dateObj,
        isSameMonth: dateObj.month() === pickerMonth.month(),
        active: activeIndex !== -1,
        activeIndex: activeIndex,
      });
    }
    while (startDate.add(1, 'days').diff(endDate) <= 0 || (dateArr.length < 42 && !pickDateMonth))

    setPickerDates(dateArr);
  }, [pickerMonth, selectedDates]);

  return <>
    <DatePickerDiv
      className={`form-control text-truncate align-items-center ${className} ${show ? 'd-flex' : 'd-none'}`}
      title={selectedDateStr}
      onClick={toggleModal}
      style={{
        background: disableAll ? '#F3F6F9' : 'white',
        cursor: disableAll ? 'default' : 'pointer',
      }}
    >
      <i className="far fa-calendar-day text-reset"/>
      <span>{selectedDateStr}</span>
    </DatePickerDiv>
    <Modal
      style={{maxWidth: 350}}
      centered={true}
      isOpen={showModal}
      toggle={toggleModal}
    >
      <ModalBody className="p-0">
        {!pickDateMonth &&
          <>
            <Chevron className="float-left" onClick={() => setPickerMonth(pickerMonth.clone().add(-1, 'month'))}>
              <i className="far fa-chevron-left"/>
            </Chevron>
            <Chevron className="float-right" onClick={() => setPickerMonth(pickerMonth.clone().add(1, 'month'))}>
              <i className="far fa-chevron-right"/>
            </Chevron>
            <div className="text-center font-weight-boldest text-uppercase border-bottom" style={{padding: 20,}}>
              Tháng {pickerMonth.format('M/YYYY')}
            </div>
          </>
        }
        <Calendar>
          {!pickDateMonth &&
            <WeekDayRow>
              <div>T2</div>
              <div>T3</div>
              <div>T4</div>
              <div>T5</div>
              <div>T6</div>
              <div>T7</div>
              <div>CN</div>
            </WeekDayRow>}
          <div>
            {_.chunk(pickerDates, 7).map((pickerWeekDate, index) =>
              <WeekDateRow key={index}>
                {pickerWeekDate.map(({dateObj, isSameMonth, active, activeIndex}, i) =>
                  <DateContainer
                    key={i}
                    className={`${isSameMonth ? '' : 'opacity-20'} ${active ? 'active' : ''}`}
                    onClick={() => {
                      if (!isSameMonth && pickDateMonth) return;

                      let currentDates = [...selectedDates];
                      if (singleDatePicker) {
                        currentDates = [dateObj];
                      } else {
                        if (active) {
                          currentDates.splice(activeIndex, 1);
                        } else {
                          currentDates.push(dateObj);
                        }
                      }
                      setSelectedDates(currentDates);
                      if (!isSameMonth) {
                        setPickerMonth(dateObj.clone().startOf("months"))
                      }
                    }}
                  >
                    <div>{dateObj.format('D')}</div>
                  </DateContainer>
                )}
              </WeekDateRow>
            )}
          </div>
        </Calendar>
      </ModalBody>
      <ModalFooter style={{padding: "10px 20px"}}>
        <div className="d-flex m-0 w-100">

          <div style={{paddingLeft: 0, paddingRight: 8}} className="col">
            <button
              onClick={toggleModal}
              style={{height: 40}}
              className="btn btn-secondary border w-100"
            >
              Hủy
            </button>
          </div>

          <div style={{paddingLeft: 8, paddingRight: 0}} className="col">
            <PrimaryButton
              onClick={() => {
                onChangeDates(selectedDates.sort((a, b) => a - b));
                toggleModal();
              }}
              style={{height: 40}}
              className={"w-100"}>
              Áp Dụng
            </PrimaryButton>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  </>
}
