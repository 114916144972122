import React from "react";
import Input from "./Input";
import OneCheck from "./OneCheck";
import "./Input.scss";

const FormikControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "oneCheckbox":
      return <OneCheck {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
