import { param } from "jquery";
import axiosClient from "./axiosClient";

const groupApi = {
    getMe: () => {
        const url = `/auth/me`;
        return axiosClient.post(url,{});
    },
    getGroups: (params) => {
        const url = `/group/search`;
        return axiosClient.post(url, params);
    },
    getUserList: () => {
        const url = `/account/search`;
        return axiosClient.post(url,{});
    },
    createGroup: (params) => {
        const { name, areaId, editProgram, acceptProgram, editSpeaker, broadcast, editAccount, memberIds } = params
        const url = `/group/create`;
        return axiosClient.post(url, {
            name: name,
            areaId: areaId, 
            editProgram: editProgram,
            acceptProgram: acceptProgram,   
            editSpeaker: editSpeaker,
            broadcast: broadcast,
            editAccount: editAccount,
            memberIds: memberIds
        });
    },
    editGroup: (params) => {
        const { id, name, areaId, editProgram, acceptProgram, editSpeaker, broadcast, editAccount, memberIds } = params
        const url = `/group/edit`;
        return axiosClient.put(url, {
            groupId: id,
            name: name,
            areaId: areaId,
            editProgram: editProgram,
            acceptProgram: acceptProgram,
            editSpeaker: editSpeaker,
            broadcast: broadcast,
            editAccount: editAccount,
            memberIds: memberIds
        });
    },
    deleteGroup: (params) => {
        const url = `/group/delete`;
        return axiosClient.delete(url,{data : { itemIds: params }});
    },

    deleteMember: (params) => {
        const url = `/group/member/remove`;
        console.log(params)
        return axiosClient.delete(url, {data: params});
    }
}

export default groupApi;