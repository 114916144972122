import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import "./Input.scss";
import { GrFormViewHide } from "react-icons/gr";

function Input(props) {
  const { label, name, isHiddenPassword, setIsHiddenPassword, ...rest } = props;
  return (
    <div className="login-form-control">
      <label htmlFor={name}>{label}</label>
      <div className="input-field">
        <Field id={name} name={name} {...rest} />
        {name === "password" ? (
          <div
            className="password-toggle-container"
            onClick={() => setIsHiddenPassword(!isHiddenPassword)}
          >
            <GrFormViewHide />
          </div>
        ) : null}
        {name === "newPassword" ? (
          <div
            className="password-toggle-container"
            onClick={() => setIsHiddenPassword(!isHiddenPassword)}
          >
            <GrFormViewHide />
          </div>
        ) : null}
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default Input;
