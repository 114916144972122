import React from "react";
import PropTypes from "prop-types";
import HeaderMobile from "components/HeaderMobile";
import Sidebar from "components/Sidebar";
import PageContent from "components/PageContent";
import ScrollTop from "components/ScrollTop";
import { Animated } from "react-animated-css";
import Global from "general/Global";
AppPage.propTypes = {};

function AppPage(props) {
  return (
    // <Animated
    //   animationIn={Global.animationIn}
    //   animationOut={Global.animationOut}
    //   animationInDuration={Global.animationInDuration}
    //   animationOutDuration={Global.animationOutDuration}
    //   isVisible={true}
    //   className=" imageDiv w-100"
    // >
    <div>
      <HeaderMobile />
      <Sidebar />
      {/* <Animated
        animationIn={Global.animationIn}
        animationOut={Global.animationOut}
        animationInDuration={Global.animationInDuration}
        animationOutDuration={Global.animationOutDuration}
        isVisible={true}
        className=" imageDiv w-100"
      > */}
      <PageContent
        backgroundImage={props.backgroundImage}
        headerTitle={props.headerTitle}
      >
        {props.children}
      </PageContent>
      {/* </Animated> */}
      <ScrollTop />
    </div>
  );
}

export default AppPage;
