import React from 'react';
import PropTypes from 'prop-types';

Empty.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    onClickButton: PropTypes.func,
    icon: PropTypes.string,
    visible: PropTypes.bool,
};

Empty.defaultProps = {
    text: "Không có dữ liệu",
    buttonText: 'Làm mới',
    onClickButton: null,
    icon: 'fad fa-redo-alt',
    visible: true,
};

function Empty(props) {
    const { text, buttonText, onClickButton, icon, visible } = props;

    const handleButtonClick = () => {
        if (onClickButton) {
            onClickButton();
        }
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <p>{text}</p>
            <a href="#" onClick={handleButtonClick} className={`btn btn-sm btn-light-primary font-weight-bolder py-2 px-3 ${visible ? '' : 'd-none'}`}>
                <i className={`${icon} mr-2`}></i>
                {buttonText}
            </a>
        </div>
    );
}

export default Empty;