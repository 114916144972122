import React from "react";
import PropTypes from "prop-types";
import AppConfigs from "general/constants/AppConfigs";
import { useTranslation } from "react-i18next";
import { AppIcons } from "general/constants/AppResource";

Footer.propTypes = {};

function Footer(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();

  return (
    <div className="footer py-4 d-flex flex-lg-column bg-white " id="kt_footer">
      {/* begin::Container */}
      <div
        className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between bg-white"
        style={{ height: "40px" }}
      >
        {/* begin::Copyright */}
        <div className="text-dark">
          <a
            // href="https://www.mdcgate.com/apps/"
            target="_blank"
            className="text-hover-primary font-weight-bolder font-size-lg"
            style={{ color: "#9EA5BD" }}
          >
            {AppConfigs.copyright}
          </a>
        </div>
        <div className="d-flex flex-row align-items-center">
          <a
            // href="https://www.mdcgate.com/apps/"
            target="_blank"
            className="text-hover-primary font-weight-bolder font-size-lg"
            style={{ color: "#9EA5BD" }}
          >
            {t("AboutUs")}
          </a>
          <img
            src={AppIcons.icSperator}
            className="mx-4"
            style={{ height: "20px" }}
          ></img>
          <a
            // href="https://www.mdcsoftware.com.vn/"
            target="_blank"
            className="text-hover-primary font-weight-bolder font-size-lg"
            style={{ color: "#9EA5BD" }}
          >
            {t("Help")}
          </a>
        </div>
        {/* end::Copyright */}
        {/* begin::Nav */}
        {/* <div className="nav nav-dark order-1 order-md-2 font-weight-bold">
                    <a href="https://www.mdcgate.com/apps/" target="_blank" className="nav-link pr-3 pl-0">{t('AboutUs')}</a>
                    <a href="https://www.mdcgate.com/apps/" target="_blank" className="nav-link px-3">{t('Terms')}</a>
                    <a href={`mailto: ${AppConfigs.emailSupport}`} target="_blank" className="nav-link pl-3 pr-0">{t('Contact')}</a>
                </div> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  );
}

export default Footer;
