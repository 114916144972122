import React, { Component } from "react";
import classSet from "classnames";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import _ from "lodash";
var arrSpeaker = [];
var map = {},
  Microsoft,
  infobox = {},
  scriptURL =
    "https://www.bing.com/api/maps/mapcontrol?callback=bingmapsCallback",
  pendingProps = [],
  pushPinForClustering = [],
  clusterLayer = null;

class ReactBingmaps extends Component {
  constructor(props) {
    super(props);
    if (document.querySelector('script[src="' + scriptURL + '"]') === null) {
      this.loadScript(scriptURL);
      window.bingmapsCallback = function () {
        Microsoft = window.Microsoft;
        this.afterDependencyLoad(pendingProps);
      }.bind(this);
    }
  }
  componentDidMount() {
    if (Microsoft === null || Microsoft === undefined) {
      pendingProps.push(this.props);
    } else {
      this.reactBingmaps(this.props, Microsoft);
    }
  }
  afterDependencyLoad(pendingProps) {
    try {
      pendingProps.map((props) => this.reactBingmaps(props, Microsoft));
    } catch (exception) {
      console.log("Error loading Microsoft bingmaps", pendingProps, exception);
    }
  }
  componentWillUnmount() {
    try {
      let mapReference = this.props.id
        ? "#" + this.props.id
        : ".react-bingmaps";
      if (map[mapReference]){
        map[mapReference].dispose();
        map[mapReference] = null;
      }
      infobox = {};
      pendingProps = [];
    } catch (exception) {
      console.log(exception);
    }
  }
  loadScript(url) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    arrSpeaker = [...this.props.infoboxesWithPushPins];
    let mapReference = this.props.id ? "#" + this.props.id : ".react-bingmaps";
    if (this.props.isZoomLocation === true) {
      this.setMapZoom(this.props.zoom, mapReference);
      this.setMapCenter(this.props.center, mapReference);
    }
    if (prevProps.center.join() !== this.props.center.join()) {
      this.setMapCenter(this.props.center, mapReference);
    }
    if (prevProps.zoom !== this.props.zoom) {
      this.setMapZoom(this.props.zoom, mapReference);
    }
    if (prevProps.mapTypeId !== this.props.mapTypeId) {
      this.setMapTypeId(
        this.props.mapTypeId,
        this.props.center,
        this.props.heading,
        mapReference
      );
    }
    if (prevProps.navigationBarMode !== this.props.navigationBarMode) {
      this.setMapNavigationBarMode(this.props.navigationBarMode, mapReference);
    }
    if (prevProps.supportedMapTypes !== this.props.supportedMapTypes) {
      this.setMapSupportedMapTypes(this.props.supportedMapTypes, mapReference);
    }
    if (prevProps.disableStreetside !== this.props.disableStreetside) {
      this.setDisableStreetside(this.props.disableStreetside, mapReference);
    }
    if (
      !_.isEqual(
        prevProps.infoboxesWithPushPins,
        this.props.infoboxesWithPushPins
      )
    ) {
      this.setInfoboxesWithPushPins(
        this.props.infoboxesWithPushPins,
        "infoboxesWithPushPins",
        mapReference,
        this.props.clusterOption,
        this.props.setListPushpinTitles
      );
    }
    if (prevProps.regularPolygons !== this.props.regularPolygons) {
      this.createRegularPolygons(this.props.regularPolygons, mapReference);
    }
    if (prevProps.boundary !== this.props.boundary) {
      this.setBoundary(this.props.boundary, mapReference);
    }
    if (prevProps.mapOptions !== this.props.mapOptions) {
      this.setMapOptions(this.props.mapOptions, mapReference);
    }
    if (prevProps.polyline !== this.props.polyline) {
      this.setPolyline(this.props.polyline, mapReference);
    }
    if (prevProps.directions !== this.props.directions) {
      this.setDirections(this.props.directions, mapReference);
    }
  }
  reactBingmaps(props, Microsoft) {
    const {
      bingmapKey,
      center,
      mapTypeId,
      zoom,
      isZoomLocation,
      navigationBarMode,
      supportedMapTypes,
      heading,
      disableStreetside,
      infoboxesWithPushPins,
      setListPushpinTitles,
      getLocation,
      regularPolygons,
      boundary,
      mapOptions,
      polyline,
      directions,
      mapHandlers,
      clusterOption,
    } = props;
    if (bingmapKey && Microsoft) {
      let mapReference = props.id ? "#" + props.id : ".react-bingmaps";
      if (!map[mapReference]) {
        map[mapReference] = new Microsoft.Maps.Map(mapReference, {
          credentials: bingmapKey,
        });
      }
      this.setMapCenter(center, mapReference);
      this.setMapTypeId(mapTypeId, center, heading, mapReference);
      this.setMapZoom(zoom, mapReference);
      this.setMapNavigationBarMode(navigationBarMode, mapReference);
      this.setMapSupportedMapTypes(supportedMapTypes, mapReference);
      this.setDisableStreetside(disableStreetside, mapReference);
      this.setInfoboxesWithPushPins(
        arrSpeaker,
        "infoboxesWithPushPins",
        mapReference,
        clusterOption,
        setListPushpinTitles
      );
      this.setGetLocation(getLocation, mapReference);
      this.createRegularPolygons(regularPolygons, mapReference);
      this.setBoundary(boundary, mapReference);
      this.setMapOptions(
        {
          navigationBarMode: Microsoft.Maps.NavigationBarMode.square,
          ...mapOptions,
        },
        mapReference
      );
      this.setPolyline(polyline, mapReference);
      this.setDirections(directions, mapReference);
      this.setMapHandlers(mapHandlers, mapReference);
    }
  }
  setLocation(location, mapReference) {
    if (
      map[mapReference] &&
      location.center &&
      location.center[0] &&
      location.center[1] &&
      location.zoom &&
      location.isZoom === true
    ) {
      map[mapReference].setView({
        center: new Microsoft.Maps.Location(center[0], center[1]),
      });
    }
  }
  setMapCenter(center, mapReference) {
    if (map[mapReference] && center && center[0] && center[1]) {
      map[mapReference].setView({
        center: new Microsoft.Maps.Location(center[0], center[1]),
      });
    }
  }
  setMapTypeId(mapTypeId, center, heading, mapReference) {
    if (map[mapReference] && mapTypeId) {
      let isBirdEyeAvailable = false;
      if (mapTypeId === "birdseye" && center && center[0] && center[1]) {
        let location = new Microsoft.Maps.Location(center[0], center[1]);
        Microsoft.Maps.getIsBirdseyeAvailable(
          location,
          Microsoft.Maps.Heading[heading],
          (onResponse) => {
            isBirdEyeAvailable = onResponse;
          }
        );
      }
      if (mapTypeId) {
        map[mapReference].setView({
          mapTypeId: isBirdEyeAvailable
            ? Microsoft.Maps.MapTypeId.birdseye
            : Microsoft.Maps.MapTypeId[mapTypeId],
        });
      }
    }
  }
  setMapZoom(zoom, mapReference) {
    if (map[mapReference] && zoom) {
      map[mapReference].setView({
        zoom: zoom,
      });
    }
  }
  setMapNavigationBarMode(navigationBarMode, mapReference) {
    if (map[mapReference] && navigationBarMode) {
      map[mapReference].setView({
        navigationBarMode: navigationBarMode,
      });
    }
  }
  setMapSupportedMapTypes(supportedMapTypes, mapReference) {
    if (map[mapReference] && supportedMapTypes) {
      map[mapReference].setView({
        supportedMapTypes: supportedMapTypes.map(
          (id) => Microsoft.Maps.MapTypeId[id]
        ),
      });
    }
  }
  setDisableStreetside(disableStreetside, mapReference) {
    if (map[mapReference] && disableStreetside) {
      map[mapReference].setView({
        disableStreetside: disableStreetside,
      });
    }
  }
  setInfoboxesWithPushPins(
    infoboxesWithPushPins,
    infoboxCreateType,
    mapReference,
    clusterOption,
    setListPushpinTitles
  ) {
    if (map[mapReference] && infoboxesWithPushPins) {
      //Remove existing Infoboxes
      var i;
      for (
        i = 0;
        infobox[infoboxCreateType] && i < infobox[infoboxCreateType].length;
        i++
      ) {
        infobox[infoboxCreateType][i].setMap(null);
      }

      map[mapReference].layers.clear();
      clusterLayer = null;
      pushPinForClustering = [];

      //Add Infoboxes with Pushpins
      if (infoboxesWithPushPins) {
        for (
          var infoboxWithPushPinIndex = 0;
          infoboxWithPushPinIndex < infoboxesWithPushPins.length;
          infoboxWithPushPinIndex++
        ) {
          if (infoboxesWithPushPins[infoboxWithPushPinIndex].location) {
            //Set Location
            let location = new Microsoft.Maps.Location(
              infoboxesWithPushPins[infoboxWithPushPinIndex].location[0],
              infoboxesWithPushPins[infoboxWithPushPinIndex].location[1]
            );

            //Set Infobox Option
            let infoboxOption = infoboxesWithPushPins[infoboxWithPushPinIndex]
              .infoboxOption
              ? infoboxesWithPushPins[infoboxWithPushPinIndex].infoboxOption
              : null;

            //ConvertToHtml if Obj
            if (infoboxOption.htmlContent) {
              infoboxOption.htmlContent = ReactDOMServer.renderToStaticMarkup(
                infoboxOption.htmlContent
              );
            }

            //Set Pushpin Option
            let pushPinOption = infoboxesWithPushPins[infoboxWithPushPinIndex]
              .pushPinOption
              ? infoboxesWithPushPins[infoboxWithPushPinIndex].pushPinOption
              : null;

            //Initilize if anchor for Pushpin
            if (
              pushPinOption.anchor &&
              pushPinOption.anchor[0] &&
              pushPinOption.anchor[1]
            ) {
              pushPinOption.anchor = new Microsoft.Maps.Point(
                pushPinOption.anchor[0],
                pushPinOption.anchor[1]
              );
            }

            //Set Pushpin
            let pushpin = new Microsoft.Maps.Pushpin(location, pushPinOption);
            //Store some metadata with the pushpin.
            pushpin.metadata = {
              infoboxOption: infoboxOption,
              infoboxAddHandler:
                infoboxesWithPushPins[infoboxWithPushPinIndex]
                  .infoboxAddHandler,
              connection: infoboxesWithPushPins[infoboxWithPushPinIndex].connection,
              type: infoboxesWithPushPins[infoboxWithPushPinIndex].type
            };

            pushPinForClustering.push(pushpin);
            Microsoft.Maps.Events.addHandler(pushpin, "click", pushpinClicked);
          }
        }

        //Add Clustured Marker
        Microsoft.Maps.loadModule("Microsoft.Maps.Clustering", function () {
          clusterLayer = new Microsoft.Maps.ClusterLayer(pushPinForClustering, {
            gridSize: 120,
            clusteredPinCallback: createCustomClusterPushpins,
          });
          map[mapReference].layers.insert(clusterLayer);
        });

        //Clustured Marker Handler
        function createCustomClusterPushpins(cluster) {
          let icon;

          let arr = cluster.containedPushpins.filter(item => item.metadata.type == "speaker");
          if(arr.length !== cluster.containedPushpins.length && arr.length !== 0) return;
          let numberOfDigits = cluster.containedPushpins.length.toString().length;

          if(arr.length === cluster.containedPushpins.length) {
            arr = cluster.containedPushpins.filter(item => item.metadata.connection.toUpperCase() !== "OFFLINE");
            if(arr.length === cluster.containedPushpins.length) icon = clusterOption.icon(1, numberOfDigits);
              else if(arr.length === 0) icon = clusterOption.icon(2, numberOfDigits); 
                else icon = clusterOption.icon(0, numberOfDigits);
          } else {
            arr = cluster.containedPushpins.filter(item => item.metadata.connection.toUpperCase() !== "OFFLINE");
            if(arr.length === cluster.containedPushpins.length) icon = clusterOption.icon(4, numberOfDigits);
              else if(arr.length === 0) icon = clusterOption.icon(5, numberOfDigits); 
                else icon = clusterOption.icon(3, numberOfDigits);
          }
          

          Microsoft.Maps.Events.addHandler(cluster, "click", pushpinClicked);
          cluster.setOptions({
            icon: icon,
            anchor: new Microsoft.Maps.Point(26, 16),
            textOffset: new Microsoft.Maps.Point(-7 + (4 - numberOfDigits) * 4, 16),
          });
        }

        function pushpinClicked(e) {
          showInfobox(e.target);
        }

        // infoBox for Pushpin & ClusteredPushpin
        infobox = new Microsoft.Maps.Infobox(map[mapReference].getCenter(), {
          visible: false,
          showPointer: false,
        });
        infobox.setMap(map[mapReference]);

        function showInfobox(pin) {
          function MakeCallback(callback, data, e) {
            data ? callback(data, e) : callback(e);
          }

          if (pin.containedPushpins) {
            // infobox for ClusterPushpin
            let listTitles = pin.containedPushpins.map((item) =>
              item.getTitle()
            );
            setListPushpinTitles(listTitles);

            infobox.setOptions({
              location: pin.getLocation(),
              htmlContent: ReactDOMServer.renderToStaticMarkup(
                clusterOption.htmlContent(listTitles)
              ),
              visible: true,
            });

            Microsoft.Maps.Events.addHandler(
              infobox,
              clusterOption.clusterInfoboxAddHandler.type,
              function (callback, data, e) {
                MakeCallback(callback, data, e);
              }.bind(this, clusterOption.clusterInfoboxAddHandler.callback)
            );
          } else {
            // infobox for Pushpin
            setListPushpinTitles([pin.getTitle()]);

            infobox.setOptions({
              title: pin.getTitle(),
              location: pin.getLocation(),
              htmlContent: pin.metadata.infoboxOption.htmlContent,
              visible: true,
            });

            Microsoft.Maps.Events.addHandler(
              infobox,
              pin.metadata.infoboxAddHandler.type,
              function (callback, data, e) {
                MakeCallback(callback, data, e);
              }.bind(this, pin.metadata.infoboxAddHandler.callback)
            );
          }
        }
      }
    }
  }
  setGetLocation(getLocation, mapReference) {
    if (map[mapReference] && getLocation) {
      if (getLocation.addHandler) {
        Microsoft.Maps.Events.addHandler(
          map[mapReference],
          getLocation.addHandler,
          function (callback, e) {
            if (
              typeof e.getX !== "undefined" &&
              typeof e.getY !== "undefined"
            ) {
              let point = new Microsoft.Maps.Point(e.getX(), e.getY());
              let location = e.target.tryPixelToLocation(point);
              this.MakeCallback(callback, location);
            } else {
              this.MakeCallback(callback, "Event: " + getLocation.addHandler);
            }
          }.bind(this, getLocation.callback)
        );
      } else {
        Microsoft.Maps.Events.addHandler(
          map[mapReference],
          "click",
          function (callback, e) {
            if (
              typeof e.getX !== "undefined" &&
              typeof e.getY !== "undefined"
            ) {
              let point = new Microsoft.Maps.Point(e.getX(), e.getY());
              let location = e.target.tryPixelToLocation(point);
              this.MakeCallback(callback, location);
            } else {
              this.MakeCallback(callback, "Event: " + getLocation.addHandler);
            }
          }.bind(this, getLocation.callback)
        );
      }
    }
  }
  setInfoboxesWithPushPinsHandler(infobox, pushpin, addHandler, mapReference) {
    if (addHandler === "mouseover") {
      Microsoft.Maps.Events.addHandler(pushpin, addHandler, function () {
        infobox.setOptions({ visible: true });
      });
      Microsoft.Maps.Events.addHandler(pushpin, "mouseout", function () {
        infobox.setOptions({ visible: false });
      });
    } else {
      Microsoft.Maps.Events.addHandler(pushpin, addHandler, function () {
        infobox.setOptions({ visible: true });
      });
    }
  }
  MakeCallback(callback, data, e, mapReference) {
    data ? callback(data, e) : callback(e);
  }
  createRegularPolygons(regularPolygons, mapReference) {
    if (map[mapReference] && regularPolygons) {
      for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
        var regularPolygon = map[mapReference].entities.get(i);
        if (regularPolygon instanceof Microsoft.Maps.Polygon) {
          map[mapReference].entities.removeAt(i);
        }
      }
      for (
        let regularPolygonIndex = 0;
        regularPolygonIndex < regularPolygons.length;
        regularPolygonIndex++
      ) {
        if (
          regularPolygons[regularPolygonIndex].center &&
          regularPolygons[regularPolygonIndex].center[0] &&
          regularPolygons[regularPolygonIndex].center[1]
        ) {
          let location = new Microsoft.Maps.Location(
            regularPolygons[regularPolygonIndex].center[0],
            regularPolygons[regularPolygonIndex].center[1]
          );
          let radius = regularPolygons[regularPolygonIndex].radius
            ? regularPolygons[regularPolygonIndex].radius
            : 0;
          let points = regularPolygons[regularPolygonIndex].points
            ? regularPolygons[regularPolygonIndex].points
            : 0;
          let option = regularPolygons[regularPolygonIndex].option
            ? regularPolygons[regularPolygonIndex].option
            : {};

          Microsoft.Maps.loadModule("Microsoft.Maps.SpatialMath", function () {
            var locations = Microsoft.Maps.SpatialMath.getRegularPolygon(
              location,
              radius,
              points,
              Microsoft.Maps.SpatialMath.DistanceUnits.Miles
            );
            var polygon = new Microsoft.Maps.Polygon(locations, option);
            map[mapReference].entities.push(polygon);
          });
        }
      }
    }
  }
  setBoundary(boundary, mapReference) {
    if (map[mapReference] && boundary) {
      for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
        var regularPolygon = map[mapReference].entities.get(i);
        if (regularPolygon instanceof Microsoft.Maps.Polygon) {
          map[mapReference].entities.removeAt(i);
        }
      }

      var boundaryLocation = boundary.location ? boundary.location : null;

      var geoDataRequestOptions = boundary.option ? boundary.option : {};
      var polygonStyle = boundary.polygonStyle ? boundary.polygonStyle : null;
      var search = boundary.search ? boundary.search : null;
      if (!search && boundaryLocation) {
        Microsoft.Maps.loadModule(
          "Microsoft.Maps.SpatialDataService",
          function () {
            Microsoft.Maps.SpatialDataService.GeoDataAPIManager.getBoundary(
              boundaryLocation,
              geoDataRequestOptions,
              map[mapReference],
              function (data) {
                if (data.results && data.results.length > 0) {
                  map[mapReference].entities.push(data.results[0].Polygons);
                }
              },
              polygonStyle,
              function errCallback(networkStatus, statusMessage) {
                console.log(networkStatus);
                console.log(statusMessage);
              }
            );
          }
        );
      } else {
        Microsoft.Maps.loadModule(
          ["Microsoft.Maps.SpatialDataService", "Microsoft.Maps.Search"],
          function () {
            var searchManager = new Microsoft.Maps.Search.SearchManager(
              map[mapReference]
            );
            var geocodeRequest = {
              where: search,
              callback: function (geocodeResult) {
                if (
                  geocodeResult &&
                  geocodeResult.results &&
                  geocodeResult.results.length > 0
                ) {
                  map[mapReference].setView({
                    bounds: geocodeResult.results[0].bestView,
                  });
                  Microsoft.Maps.SpatialDataService.GeoDataAPIManager.getBoundary(
                    geocodeResult.results[0].location,
                    geoDataRequestOptions,
                    map[mapReference],
                    function (data) {
                      if (data.results && data.results.length > 0) {
                        map[mapReference].entities.push(
                          data.results[0].Polygons
                        );
                      }
                    },
                    polygonStyle,
                    function errCallback(networkStatus, statusMessage) {
                      console.log(networkStatus);
                      console.log(statusMessage);
                    }
                  );
                }
              },
            };
            searchManager.geocode(geocodeRequest);
          }
        );
      }
    }
  }
  setMapOptions(mapOptions, mapReference) {
    if (map[mapReference] && mapOptions) {
      map[mapReference].setOptions(mapOptions);
    }
  }
  setPolyline(polyline, mapReference) {
    if (map[mapReference] && polyline) {
      for (var i = map[mapReference].entities.getLength() - 1; i >= 0; i--) {
        var ref = map[mapReference].entities.get(i);
        if (ref instanceof Microsoft.Maps.Polyline) {
          map[mapReference].entities.removeAt(i);
        }
      }

      var polylineLocations = polyline.location ? polyline.location : null;
      var polylineOption = polyline.option ? polyline.option : null;

      var polylineLocationsAsMapLocations = [];
      for (
        var polylineLocationIndex = 0;
        polylineLocationIndex < polylineLocations.length &&
        polylineLocations[polylineLocationIndex][0] &&
        polylineLocations[polylineLocationIndex][1];
        polylineLocationIndex++
      ) {
        polylineLocationsAsMapLocations.push(
          new Microsoft.Maps.Location(
            polylineLocations[polylineLocationIndex][0],
            polylineLocations[polylineLocationIndex][1]
          )
        );
      }

      if (polylineLocationsAsMapLocations.length !== 0) {
        var polylineObject = new Microsoft.Maps.Polyline(
          polylineLocationsAsMapLocations,
          polylineOption
        );
        map[mapReference].entities.push(polylineObject);
      }
    }
  }
  setDirections(directions, mapReference) {
    if (map[mapReference] && directions) {
      var inputPanel = directions.inputPanel ? directions.inputPanel : null;
      var renderOptions = directions.renderOptions
        ? directions.renderOptions
        : null;
      var requestOptions = directions.requestOptions
        ? directions.requestOptions
        : null;
      var wayPoints = directions.wayPoints ? directions.wayPoints : [];
      var wayPointsAsDirection = [];

      Microsoft.Maps.loadModule("Microsoft.Maps.Directions", function () {
        var directionsManager = new Microsoft.Maps.Directions.DirectionsManager(
          map[mapReference]
        );
        directionsManager.clearAll();
        if (inputPanel) {
          directionsManager.showInputPanel(inputPanel);
        }
        if (renderOptions) {
          if (renderOptions.itineraryContainer) {
            renderOptions.itineraryContainer = document.getElementById(
              renderOptions.itineraryContainer
            );
          }
          directionsManager.setRenderOptions(renderOptions);
        }
        if (requestOptions) {
          let distanceUnit = requestOptions.distanceUnit
            ? Microsoft.Maps.Directions.DistanceUnit[
                requestOptions.distanceUnit
              ]
            : null;
          let routeMode = requestOptions.routeMode
            ? Microsoft.Maps.Directions.RouteMode[requestOptions.routeMode]
            : null;
          let routeAvoidance = requestOptions.routeAvoidance
            ? Microsoft.Maps.Directions.RouteAvoidance[
                requestOptions.routeAvoidance
              ]
            : null;
          let routeOptimization = requestOptions.routeOptimization
            ? Microsoft.Maps.Directions.RouteOptimization[
                requestOptions.routeOptimization
              ]
            : null;
          let timeType = requestOptions.timeType
            ? Microsoft.Maps.Directions.TimeTypes[requestOptions.timeType]
            : null;

          let vehicleSpec = requestOptions.vehicleSpec
            ? requestOptions.vehicleSpec
            : null;
          let maxRoutes = requestOptions.maxRoutes
            ? requestOptions.maxRoutes
            : null;
          let routeDraggable = requestOptions.routeDraggable
            ? requestOptions.routeDraggable
            : null;
          let routeIndex = requestOptions.routeIndex
            ? requestOptions.routeIndex
            : null;
          let time = requestOptions.time ? requestOptions.time : null;

          directionsManager.setRequestOptions(
            Object.assign(
              {},
              distanceUnit && { distanceUnit },
              maxRoutes && { maxRoutes },
              routeMode && { routeMode },
              routeAvoidance && { routeAvoidance },
              routeOptimization && { routeOptimization },
              timeType && { timeType },
              vehicleSpec && { vehicleSpec },
              routeDraggable && { routeDraggable },
              routeIndex && { routeIndex },
              time && { time }
            )
          );
        }

        for (
          var wayPointsIndex = 0;
          wayPointsIndex < wayPoints.length;
          wayPointsIndex++
        ) {
          let address = wayPoints[wayPointsIndex].address
            ? wayPoints[wayPointsIndex].address
            : null;
          let location =
            wayPoints[wayPointsIndex].location &&
            wayPoints[wayPointsIndex].location[0] &&
            wayPoints[wayPointsIndex].location[1]
              ? new Microsoft.Maps.Location(
                  wayPoints[wayPointsIndex].location[0],
                  wayPoints[wayPointsIndex].location[1]
                )
              : null;
          let isViaPoint = wayPoints[wayPointsIndex].isViaPoint
            ? wayPoints[wayPointsIndex].isViaPoint
            : null;

          wayPointsAsDirection.push(
            new Microsoft.Maps.Directions.Waypoint({
              address,
              location,
              isViaPoint,
            })
          );
        }
        if (wayPointsAsDirection.length !== 0) {
          for (
            var wayPointsAsDirectionIndex = 0;
            wayPointsAsDirectionIndex < wayPointsAsDirection.length;
            wayPointsAsDirectionIndex++
          ) {
            directionsManager.addWaypoint(
              wayPointsAsDirection[wayPointsAsDirectionIndex]
            );
          }
          directionsManager.calculateDirections();
        }
      });
    }
  }
  setMapHandlers(mapHandlers, mapReference) {
    if (map[mapReference] && mapHandlers) {
      for (
        var mapHandlerIndex = 0;
        mapHandlerIndex < mapHandlers.length;
        mapHandlerIndex++
      ) {
        var mapHandler = mapHandlers[mapHandlerIndex];
        if (mapHandler.addHandler) {
          Microsoft.Maps.Events.addHandler(
            map[mapReference],
            mapHandler.addHandler,
            function (callback, e) {
              var callbackData = {
                event: e,
                map: map[mapReference],
              };
              this.MakeCallback(callback, callbackData);
            }.bind(this, mapHandler.callback)
          );
        } else {
          Microsoft.Maps.Events.addHandler(
            map[mapReference],
            "click",
            function (callback, e) {
              var callbackData = {
                event: e,
                map: map[mapReference],
              };
              this.MakeCallback(callback, callbackData);
            }.bind(this, mapHandler.callback)
          );
        }
      }
    }
  }

  render() {

    return (
      <div
        id={this.props.id}
        className={classSet("react-bingmaps", this.props.className)}
        style={{ width: this.props.width, height: this.props.height }}
      />
    );
  }
}

export default ReactBingmaps;

ReactBingmaps.propTypes = {
  bingmapKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.arrayOf(PropTypes.number),
  mapTypeId: PropTypes.string,
  navigationBarMode: PropTypes.string,
  supportedMapTypes: PropTypes.arrayOf(PropTypes.string),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoom: PropTypes.number,
  isZoomLocation: PropTypes.bool,
  disableStreetside: PropTypes.bool,
  infoboxesWithPushPins: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.arrayOf(PropTypes.number),
      addHandler: PropTypes.string,
      infoboxOption: PropTypes.object,
      pushPinOption: PropTypes.object,
      infoboxAddHandler: PropTypes.shape({
        type: PropTypes.string,
        callback: PropTypes.func,
      }),
      pushPinAddHandler: PropTypes.shape({
        type: PropTypes.string,
        callback: PropTypes.func,
      }),
    })
  ),
  getLocation: PropTypes.object,
  regularPolygons: PropTypes.arrayOf(
    PropTypes.shape({
      center: PropTypes.arrayOf(PropTypes.number),
      radius: PropTypes.number,
      points: PropTypes.number,
      option: PropTypes.object,
    })
  ),
  boundary: PropTypes.shape({
    location: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    option: PropTypes.object,
    polygonStyle: PropTypes.object,
    search: PropTypes.string,
  }),
  mapOptions: PropTypes.object,
  polyline: PropTypes.shape({
    location: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    option: PropTypes.object,
  }),
  directions: PropTypes.object,
  mapHandlers: PropTypes.arrayOf(PropTypes.object),
  clusterOption: PropTypes.object,
  setListPushpinTitles: PropTypes.func,
};
ReactBingmaps.defaultProps = {
  bingmapKey: undefined,
  center: undefined,
  mapTypeId: undefined,
  navigationBarMode: undefined,
  supportedMapTypes: undefined,
  heading: 0,
  pushPins: undefined,
  disableStreetside: true,
  infoboxes: undefined,
  infoboxesWithPushPins: undefined,
  zoom: undefined,
  isZoomLocation: false,
  getLocation: undefined,
  regularPolygons: undefined,
  boundary: undefined,
  mapOptions: undefined,
  polyline: undefined,
  directions: undefined,
  mapHandlers: undefined,
  clusterOption: undefined,
  setListPushpinTitles: undefined,
};
