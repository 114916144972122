import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./style.scss";

ModalDelete.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
};

ModalDelete.defaultProps = {
  show: false,
  onClose: null,
  text: "Bạn có chắc chắn muốn xóa không?",
  title: "Xóa",
};

/**
 * show (bool)
 * onClose (func): fire when click close
 * onSubmit (func): fire when click submit
 * text (string): show on body
 */
function ModalDelete(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const { show, onClose, onSubmit, text, title } = props;

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleSubmit() {
    if (onSubmit) {
      onSubmit();
    }
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className="ModalDelete font-weight-bold"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title>
          <p className="Bold_13">
            {title}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center bg-light py-6">
        <div className="trash-box bg-white px-9 py-8">
          <i className="far fa-trash-alt" />
        </div>
        <p className="Regular_13 mt-6">{text}</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div className="col-6">
            <a
              className="w-100 btn btn-danger font-weight-bold"
              onClick={() => handleSubmit()}
            >
              {t("Confirm")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDelete;
