import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";
import UserHelper from "general/helpers/UserHelper";
import Utils from "general/utils/Utils";
import store from "libs/storage/store";
import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";

// Route khong can dang nhap
function GuestRoute(props) {
  const isAuth = !Utils.isObjectEmpty(localStorage.getItem(PreferenceKeys.refreshToken)) && !Utils.isObjectEmpty(localStorage.getItem(PreferenceKeys.accessToken));
  return isAuth ? <Redirect to="/" /> : <Route {...props} />;
}
export default GuestRoute;
