import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

KeenSelectOption.propTypes = {
    name: PropTypes.string.isRequired,
    fieldProps: PropTypes.object.isRequired,
    fieldMeta: PropTypes.object.isRequired,
    fieldHelpers: PropTypes.object.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,

    options: PropTypes.array,

    containerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    selectClassName: PropTypes.string,
    onValueChanged: PropTypes.func,
};

KeenSelectOption.defaultProps = {
    label: "",
    disabled: false,
    text: "",
    options: [],
    containerClassName: 'form-group row',
    labelClassName: 'col-xl-3 col-lg-3',
    selectClassName: 'col-lg-6 col-xl-3',
    onValueChanged: null,
};

function KeenSelectOption(props) {
    // MARK: --- Params ---
    const { fieldProps, fieldMeta, fieldHelpers, name, label, disabled, text, options, containerClassName, labelClassName, selectClassName, onValueChanged } = props;
    const showError = false;
    const value = fieldProps.value;
    const { t } = useTranslation();

    // MARK: --- Functions ---
    function handleOptionChanged(e) {
        const selectedValue = !!fieldProps?.multiple ? Array.from(e.target?.selectedOptions, (option) => (option.value)) : e.target.value;
        fieldHelpers.setValue(!!fieldProps?.multiple ? selectedValue : (!isNaN(selectedValue)?parseInt(selectedValue):selectedValue));

        if (onValueChanged) {
            onValueChanged(selectedValue);
        }
    }

    return (
        <div className={` ${containerClassName}`}>
            {label && (<label className={`col-form-label text-left text-lg-right ${labelClassName}`}>{label}</label>)}
            <div className={` ${selectClassName}`}>
                <select
                    id={name}
                    onChange={handleOptionChanged}
                    disabled={disabled}
                    className={`cursor-pointer bg-hover-light form-control custom-select shadow-none ${showError ? 'is-invalid' : ''}`}
                    value={value}
                    multiple={fieldProps?.multiple}
                >
                    {options.map((item, index) => {
                        return (
                            <option
                                key={index}
                                value={item.value}
                            >
                                {t(item.text)}
                            </option>
                        );
                    })}
                </select>
                {/* <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors[name]}</div>
                </div> */}
                {
                    text.length > 0 && (
                        <span className="form-text text-muted">{text}</span>
                    )
                }
            </div>
        </div>
    );
}

export default KeenSelectOption;
