import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import _ from "lodash";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

class BaseSearchBarSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownHeight: 0,
      searchString: "",
      dropdownStyle: null,
      filteredItems: props.listItems,
    };

    this.inputDebounceTimeout = null;
    this.filterItem = this.filterItem.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.calculateDropdownStyle = this.calculateDropdownStyle.bind(this);
    this.toLowerCaseNonAccent = this.toLowerCaseNonAccent.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.dropdownHeight &&
      this.state.dropdownHeight !== this.calculateDropdownHeight()
    ) {
      this.setState({
        dropdownHeight: this.calculateDropdownHeight(),
        dropdownStyle: this.calculateDropdownStyle(),
      });
    }

    if(!_.isEqual(prevProps.listItems, this.props.listItems)) this.setState({
      filteredItems: this.state.searchString.length > 0
      ? this.props.listItems.filter((item) => {
          let filterTextLowerCaseNonAccent = this.toLowerCaseNonAccent(this.state.searchString);
          for (let i = 0; i < this.props.filterFields.length; i++)
            if (this.toLowerCaseNonAccent(item[this.props.filterFields[i]]).includes(filterTextLowerCaseNonAccent))
              return true;
          return false;
        })
      : this.props.listItems,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.handleResize);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        dropdownHeight: 0,
      });
    }
  }

  handleResize(event) {
    this.setState({
      dropdownStyle: this.calculateDropdownStyle(),
    });
  }

  toggleDropdown() {
    if (this.props.isDisabled) {
      return;
    }
    if (this.state.dropdownHeight == 0) {
      this.setState({
        dropdownHeight: this.calculateDropdownHeight(), // + padding
        dropdownStyle: this.calculateDropdownStyle(),
      });
      let input = this.wrapperRef.current.querySelector(`input`);
      if (input) {
        input.focus();
      }
    }
  }
 
  toLowerCaseNonAccent(str) {
    str = str
      .toLowerCase()
      .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
      .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
      .replace(/ì|í|ị|ỉ|ĩ/g, "i")
      .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
      .replace(/ù|ú|ủ|ũ|ụ|ừ|ứ|ử|ữ|ự|ư/g, "u")
      .replace(/đ/g, "d")
      .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // Huyền sắc hỏi ngã nặng
      .replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  }

  filterItem(e) {
    this.setState({
      searchString: e.target.value,
      filteredItems:
        e.target.value.length > 0
          ? this.props.listItems.filter((item) => {
              let filterTextLowerCaseNonAccent = this.toLowerCaseNonAccent(e.target.value);
              for (let i = 0; i < this.props.filterFields.length; i++)
                if (this.toLowerCaseNonAccent(item[this.props.filterFields[i]]).includes(filterTextLowerCaseNonAccent))
                  return true;
              return false;
            })
          : this.props.listItems,
    });
  }

  onSelectItem(item) {
    if (this.props.onSelect) {
      this.props.onSelect(item);
    }
    this.setState({
      dropdownHeight: 0,
    });
  }

  calculateDropdownHeight() {
    let dropdownContent = this.wrapperRef.current.querySelector(
      `.${classes.dropdown_container}`
    );
    return dropdownContent.scrollHeight + 12; // + padding
  }

  calculateDropdownStyle() {
    if (this.wrapperRef.current) {
      let dropdownWantedWidth = 500;
      let selectContainer = this.wrapperRef.current;
      let leftOffset = $(selectContainer).offset().left,
        widthOffset = selectContainer.offsetWidth,
        rightOffset = $(window).width() - leftOffset - widthOffset;

      let left = null,
        right = null,
        width = dropdownWantedWidth;
      if (rightOffset + widthOffset > dropdownWantedWidth) {
        // dropdown trái -> phải
        left = 0;
      } else if (leftOffset + widthOffset > dropdownWantedWidth) {
        // dropdown phải -> trái
        right = 0;
      } else {
        // dropdown căn theo phía nhiều khoảng trống hơn
        width = rightOffset + widthOffset - 15;

        if (rightOffset > leftOffset) {
          left = 0;
        } else {
          right = 0;
        }
      }
      if (
        (Math.abs(width - widthOffset) * 100) / ((width + widthOffset) / 2) <
          10 ||
        width < widthOffset
      ) {
        width = "100%";
      }
      return {
        left,
        right,
        width,
      };
    }
  }

  render() {

    return (
      <div
        className={
          classes.container +
          " " +
          this.props.className +
          (this.props.isDisabled ? " q-disabled" : "")
        }
        style={{
          width:
            this.props.width > 0
              ? this.props.width
              : this.props.width == 0
              ? "100%"
              : null,
          ...(this.props.isDisabled && {
            background: "#F3F6F9",
            cursor: "default",
          }),
        }}
        ref={this.wrapperRef}
      >
        <div className={`input-icon`} onClick={this.toggleDropdown}>
          <input
            className={`form-control`}
            placeholder={
              this.props.placeholder ? this.props.placeholder : "Search"
            }
            onChange={this.filterItem}
            style={{ width: this.props.width > 0 ? this.props.width : ""}}
          />
          <span className="">
            <i className="far fa-search text-muted" />
          </span>
        </div>
        <div
          className={`${classes.dropdown_container} ${
            this.state.dropdownHeight ? "" : "p-0 border-0"
          } ${
            this.props.dropdownClassName ? this.props.dropdownClassName : ""
          } ${this.state.filteredItems?.length == 0 ? "p-0 border-0" : ""}`}
          style={{
            maxHeight: this.state.dropdownHeight,
            ...this.state.dropdownStyle,
          }}
        >
          <div className={classes.select_tree_container}>
            {this.state.filteredItems?.map((item) => (
              <div
                key={Math.random()}
                className={`${classes.item}`}
                onClick={() => this.onSelectItem(item)}
              >
                {this.props.renderItem(item)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

BaseSearchBarSuggestion.propTypes = {
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  width: PropTypes.number,
  listItems: PropTypes.array,
  renderItem: PropTypes.func,
  filterFields: PropTypes.array,
};

BaseSearchBarSuggestion.defaultProps = {
  width: -1,
  placeholder: "",
  listItems: [],
  renderItem: null,
  filterFields: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseSearchBarSuggestion);
