import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppImages } from "general/constants/AppResource";
import FormikControl from "pages/Login/components/inputs/FormikControl";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import authApi from "api/authApi";
import Utils from "general/utils/Utils";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { useHistory } from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import BaseTextField from "general/custom-fields/BaseTextField";
import axiosClient from "api/axiosClient";

ModalResetPassword.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalResetPassword.defaultProps = {
  show: false,
  onClose: null,
};

function ModalResetPassword(props) {
  // MARK: --- Params ---
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.current);
  const { show, onClose, reason } = props;
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const passwordMaxLength = process.env.REACT_APP_PASSWORD_MAX_LENGTH;
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;
  const passwordHaveCapitalLetter = process.env.REACT_APP_PASSWORD_CAPITAL_LETTER;
  const passwordHaveNonCapitalLetter = process.env.REACT_APP_PASSWORD_NON_CAPITAL_LETTER;
  const passwordHaveNumber = process.env.REACT_APP_PASSWORD_NUMBER_LETTER;
  const [passwordRequirement, setPasswordRequirement] = useState(null);
  let initialPasswordErrorText = "Mật khẩu gồm ít nhất "+passwordMinLength +" kí tự";
  const [passwordRequirementErrorText, setPasswordRequirementErrorText] = useState(initialPasswordErrorText);
  let regExp = new RegExp(/^/);

  useEffect(() => {
    if(passwordHaveCapitalLetter == "true"){
      regExp = new RegExp(regExp.source + /(?=.*[A-Z])/.source);
      setPasswordRequirement(regExp);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 kí tự viết hoa";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if(passwordHaveNonCapitalLetter == "true"){
      regExp = new RegExp(regExp.source + /(?=.*[a-z])/.source);
      setPasswordRequirement(regExp);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 kí tự viết thường";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if(passwordHaveNumber == "true"){
      regExp = new RegExp(regExp.source + /(?=.*[0-9])/.source);
      setPasswordRequirement(regExp);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 chữ số";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
  }, []);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }


  const initialValues = {
    account: "",
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(passwordMinLength, t("PasswordTooShort"))
      .max(passwordMaxLength, t("PasswordTooLong"))
      .required(t("enterPass"))
      .matches(passwordRequirement, passwordRequirementErrorText),
  });

  const onSubmit = async (values, props) => {
    console.log("rspassword", values);
    setLoading(true);
    try {
      let res = await authApi.getResetPasswordLink(values);
      if (res) {
        let data = res.data;
        setLoading(false);
        console.log(data);
        if (data.result === "success") {
          ToastHelper.showSuccess(
            "Yêu cầu thành công . Hãy kiểm tra mail của bạn"
          );
          handleClose();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      className=""
      dialogClassName="modal-50w"
      size="sm"
      show={show}
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <Modal.Header className="d-flex justify-content-center py-4">
                <Modal.Title className="">
                  <span className="">{t("forgotPassword")}</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-light pt-4">
                <BaseTextField
                  label={t("userName")}
                  placeholder={t("userNamePlaceHolder")}
                  name="account"
                  fieldProps={formik.getFieldProps("account")}
                  fieldMeta={formik.getFieldMeta("account")}
                  fieldHelper={formik.getFieldHelpers("account")}
                ></BaseTextField>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <div className="row w-100">
                  <div className="col-6">
                    <a
                      className="w-100 btn btn-light  font-weight-bold"
                      onClick={() => handleClose()}
                    >
                      {t("Cancel")}
                    </a>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 btn font-weight-bold text-white d-flex justify-content-center"
                      type="submit button"
                      style={{ backgroundColor: "#2AB448" }}
                      disabled={!formik.isValid || loading}
                    >
                      {loading === false ? null : (
                        <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
                      )}
                      <p className="font-weight-bold text-white mb-0">
                        {t("Gửi")}
                      </p>
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ModalResetPassword;
