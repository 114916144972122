import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import './index.scss'


AreaFilter.propTypes = {
    area: PropTypes.string,
    options: PropTypes.array,
    setCurrentArea: PropTypes.func,
}

AreaFilter.defaultProps = {
    area: 'AreaName',
    options: [],
    setCurrentArea: null,
}


function AreaFilter(props) {
    const { t } = useTranslation();
    const { label, area, options, setCurrentArea } = props

    const [openDropdown, setOpenDropdown] = useState(false);

    const toggleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }


    return (
        <>
        {
            options.length > 0 && (
            <div className='AreaFilter d-flex flex-row align-items-center Regular_13 mx-3'>
                { label }
                <div className='Bold_13 dropdown-group bg-white border rounded cursor-pointer p-3 ml-3'  onClick={toggleDropdown}  onMouseLeave={() => setOpenDropdown(false)}>
                    {t(area)} <i className="fal fa-angle-down"/>
                    {
                        openDropdown &&
                        <div className='dropdown d-flex flex-column bg-white border'>
                            {
                                options.map( (area, index) => {
                                    return (
                                        <div 
                                            className='dropdown-option Normal_12 text-nowrap p-3'
                                            align='left'
                                            onClick={()=>{
                                                setCurrentArea(area)
                                            }}
                                            key={index}
                                        >
                                            {area.name}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            )
        }
        </>
    )
}

export default AreaFilter;