import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

import './index.scss';


AppPagination.propTypes = {
    className: PropTypes.string,
    total: PropTypes.number.isRequired,
    step: PropTypes.array,
    curStep: PropTypes.number,
    curPage: PropTypes.number,
    setPage: PropTypes.func,
    setStep: PropTypes.func,
}

AppPagination.defaultProps = {
    className: '',
    step: [5,10,20],
    curStep: 5,
    curPage: 1,
    setPage: () => {},
    setStep: () => {},
}


/**
 * @param className (string): không bắt buộc
 * @param total (int): tổng số phần tử
 * @param step (array int): mảng số phần tử mỗi trang có thể chọn
 * @param curStep (int): số phần tử mỗi trang đang chọn
 * @param curPage (int): trang hiện tại
 * @function setPage(int) : callback chọn trang
 * @function setStep(int) : callback chọn số phẩn tử mỗi trang
 */
function AppPagination(props) {
    const {t} = useTranslation();

    const total = props.total;
    const step = props.step;
    const curStep = props.curStep;
    const numPage = Math.ceil(total/curStep);
    const curPage = props.curPage;

    var [openDropdown, setOpenDropdown] = useState(false)

    const setPage = (num) => {
        props.setPage(num);
    }
    const setStep = (num) => {
        props.setPage( Math.ceil( Math.min(curPage*curStep,total) / num) );
        props.setStep(num);
    }

    const toggleDropdown = () => {
        setOpenDropdown(!openDropdown)
    }

    useMemo(() => {
        if (curPage*curStep > total) {
            console.log('trim')
            props.setPage( Math.ceil(total / curStep) || 1 );
        }
    }, [total]);


    return (
        <div className={'AppPagination d-flex flex-row flex-wrap justify-content-between rounded-bottom px-5 '+ props.className}>
            <div className='d-flex flex-row align-items-center py-6'>
                {/* CURRENT PAGE ON TOTAL  */}
                <div className='py-2'>
                    <p className='Regular_13'>{t('Display')} {Math.min(curStep*curPage,total)}/{total}</p>
                </div>
                {/* STEP SELECTOR  */}
                <div className='AppPagination_StepBox bg-white d-flex flex-column border rounded p-1 ml-4 justify-content-center' onClick={toggleDropdown}>
                    <p className='Bold_13' style={{color:'#5180FB',width:'38px'}} align='right'>{curStep}<i className="fa fa-angle-down pr-1"></i></p>
                    {
                        openDropdown &&
                        <div className='dropdown d-flex flex-column-reverse bg-white border w-100'>
                            {
                                step.map((item, index) => {
                                    return (
                                        <div key={index} className='py-1' align='center' onClick={()=>{setStep(item)}}>
                                            <p className='Normal_12'>{item}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>

            {/* BUTTON  */}
            <div className='d-flex flex-row flex-wrap'>
                <div className={'AppPagination_Control rounded mx-1 my-4 ' + (curPage>1?"":"disable")} onClick={()=>{setPage(1)}}>
                    <p className='Normal_12'><i className="fa fa-angle-double-left"></i></p>
                </div>
                <div className={'AppPagination_Control rounded mx-1 my-4 ' + (curPage>1?"":"disable")} onClick={()=>{setPage(Math.max(1,curPage-1))}}>
                    <p className='Normal_12'><i className="fa fa-angle-left"></i></p>
                </div>

                {(curPage>numPage-2 && numPage>3) &&
                    <div className='AppPagination_3dots rounded mx-1 my-4'>
                        <p className='Normal_12'>...</p>
                    </div>}

                {
                    (curPage==numPage && numPage>2) &&
                    <div className='AppPagination_Num rounded mx-1 my-4' onClick={()=>{setPage(curPage-2)}}>
                        <p className='Normal_12'>{curPage-2}</p>
                    </div>
                }
                {
                    (curPage-1>0) &&
                    <div className='AppPagination_Num rounded mx-1 my-4' onClick={()=>{setPage(curPage-1)}}>
                        <p className='Normal_12'>{curPage-1}</p>
                    </div>
                }
                    <div className='AppPagination_Num rounded mx-1 my-4 active'>
                        <p className='Normal_12'>{curPage}</p>
                    </div>
                {
                    (curPage<numPage) &&
                    <div className='AppPagination_Num rounded mx-1 my-4' onClick={()=>{setPage(curPage+1)}}>
                        <p className='Normal_12'>{curPage+1}</p>
                    </div>
                }
                {
                    (curPage==1 && numPage>2) &&
                    <div className='AppPagination_Num rounded mx-1 my-4' onClick={()=>{setPage(curPage+2)}}>
                        <p className='Normal_12'>{curPage+2}</p>
                    </div>
                }

                {(curPage<numPage-1 && numPage>3) &&
                    <div className='AppPagination_3dots rounded mx-1 my-4'>
                        <p className='Normal_12'>...</p>
                    </div>}
                
                <div className={'AppPagination_Control rounded mx-1 my-4 ' + (curPage<numPage?"":"disable")} onClick={()=>{setPage(Math.min((curPage+1),numPage))}}>
                    <p className='Normal_12'><i className="fa fa-angle-right"></i></p>
                </div>
                <div className={'AppPagination_Control rounded mx-1 my-4 ' + (curPage<numPage?"":"disable")} onClick={()=>{setPage(numPage)}}>
                    <p className='Normal_12'><i className="fa fa-angle-double-right"></i></p>
                </div>
            </div>
            {/* END BUTTON  */}
        </div>
    )
}

export default AppPagination;
