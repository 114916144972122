import React, { useEffect, useState } from "react";
import moment from "moment";
import { IdleTimeOutModal } from "./IdleTimeOutModal";
import { useSelector } from "react-redux";
import Global from "general/Global";
const IdleTimeOutHandler = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isLogout, setLogout] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  let timeout = process.env.REACT_APP_IDLE_TIMEOUT;
  let timer = undefined;
  const events = ["click", "load", "keydown"];
  const eventHandler = (eventType) => {
    console.log(eventType);
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment().toISOString());
      if (timer) {
        props.onActive();
        startTimer();
      }
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime")
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime))
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : timeout;
        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff._milliseconds < timeOutInterval) {
            startTimer();
            props.onActive();
          } else {
            if (Global.accessToken && Global.accessToken.length > 0) {
              props.onIdle();
              setShowModal(true);
            }
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : timeout
    );
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    // setLogout(false);
  };
  const handleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    // setLogout(true);
    // props.onLogout();
    setShowModal(false);
  };

  return (
    <div>
      <IdleTimeOutModal
        showModal={showModal}
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default IdleTimeOutHandler;
