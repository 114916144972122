import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";
import dashboardApi from "api/dashboardApi";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classnames from "classnames/bind";
import "./styles.scss";
export default function BulletinsCarousel(props) {
  // MARK: --- Params ---
  const BULLETIN_REPEAT_TYPE = {
    None: "Một lần",
    Day: "Hàng ngày",
    Week: "Hàng tuần",
    Month: "Hàng tháng",
  };

  const BULLETIN_PRIORITY = {
    NORMAL: "Thấp",
    MEDIUM: "Trung bình",
    HIGH: "Cao",
    EMERGENCY: "Khẩn cấp",
  };
  const { dates, currentAreaId } = props;
  const { t } = useTranslation();
  const [bulletins, setBulletins] = useState({
    id: 245,
    userId: 49,
    description: "hungdaik test",
    type: "MP3",
    startDate: "2022-09-13",
    endDate: "2022-09-14",
    mode: "NOW",
    priority: "NORMAL",
    repeatType: "None",
    startTime: ["11:22:00"],
    endTime: ["11:26:22"],
    categoryId: 45,
  });
  const [page, setPage] = useState(0);
  const [totalBulletins, setTotalBulletins] = useState(1);

  // MARK: --- Function ---
  async function getBulletins(params) {
    if (params && params.areaId > 0) {
      const res = await dashboardApi.getBulletins(params);
      // console.log(res?.data)
      if (res?.data.count > 0) {
        setBulletins(res?.data.eBulletins);
      }
      setTotalBulletins(res?.data.total);
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    getBulletins({
      areaId: currentAreaId,
      startDate: moment().subtract(6, "day").format("YYYY/MM/DD"),
      endDate: moment().add(1, "day").format("YYYY/MM/DD"),
      limit: 1,
      page: page,
    });
  }, []);
  useEffect(() => {
    getBulletins({
      areaId: currentAreaId,
      startDate: dates[0].format("YYYY/MM/DD"),
      endDate: dates[1].clone().add(1, "days").format("YYYY/MM/DD"),
      limit: 1,
      page: page,
    });
  }, [page]);
  useEffect(() => {
    setPage(0);
    getBulletins({
      areaId: currentAreaId,
      startDate: dates[0].format("YYYY/MM/DD"),
      endDate: dates[1].clone().add(1, "days").format("YYYY/MM/DD"),
      limit: 1,
      page: 0,
    });
  }, [dates, currentAreaId]);

  // useEffect(() =>{console.log(bulletins)}, [bulletins])
  return (
    <>
      {totalBulletins > 0 && (
        <div className="Card m-0 border-0 d-flex flex-column rounded bg-white h-100">
          <div className="Card-body flex-grow-1 d-flex flex-column">
            <div className="Card-title ml-5 mr-5">
              <div className="d-flex">
                <div className="mr-auto ">{t("Bulletin")}</div>
                <button className="button__2">
                  <i
                    className="bi bi-chevron-left"
                    onClick={() => {
                      page > 0 ? setPage(page - 1) : "";
                    }}
                  ></i>
                </button>
                <button className="button__2">
                  <i
                    className="bi bi-chevron-right"
                    onClick={() => {
                      page + 1 < totalBulletins ? setPage(page + 1) : "";
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <div className="Card-body flex-grow-1 d-flex flex-column ml-5 mr-5 h-100">
              <div className="Card-title">
                <p
                  className="text-primary"
                  style={{
                    fontSize: 16,
                    marginLeft: -5,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    lineClamp: 2,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {bulletins[0]?.title}
                </p>
              </div>
              <div
                className="Card-body flex-grow-1 "
                style={{
                  marginTop: "20px",
                  height: 100,
                  overflowY: "scroll",
                  fontSize: "14px",
                  color: "#877F9B",
                  fontWeight: "500",
                  textJustify: "inter-word",
                }}
              >
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Mô tả: </b>
                  {bulletins[0]?.description}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Loại: </b>
                  {bulletins[0]?.type}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Người tạo: </b>
                  {bulletins[0]?.creator}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Ngày bắt đầu: </b>
                  {bulletins[0]?.startDate}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Ngày kết thúc: </b>
                  {bulletins[0]?.endDate}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Thời gian bắt đầu: </b>
                  {bulletins[0]?.startTime[0]}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Thời gian kết thúc: </b>
                  {bulletins[0]?.endTime[0]}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Mã lĩnh vực: </b>
                  {bulletins[0]?.categoryId}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Chế độ phát: </b>
                  {BULLETIN_REPEAT_TYPE[bulletins[0]?.repeatType]}
                </p>
                <p className="mb-1">
                  <i
                    className="fas fa-circle text-primary mr-1"
                    style={{ fontSize: 6, verticalAlign: "middle" }}
                  />
                  <b className="mx-1 text-primary">Mức ưu tiên: </b>
                  {BULLETIN_PRIORITY[bulletins[0]?.priority]}
                </p>
              </div>
            </div>
          </div>
          <div
            className="bg-white w-100"
            style={{ height: 50, backgroundColor: "red" }}
          >
            <p
              className="mt-2 ml-5"
              style={{
                fontSize: "14px",
                color: "#acacbb",
                fontWeight: "500",
              }}
            >
              Bài số {1 + page}/{totalBulletins}
            </p>
            <ProgressBar
              className="ml-5 mr-5"
              now={totalBulletins > 0 ? ((1 + page) / totalBulletins) * 100 : 0}
            ></ProgressBar>
          </div>
        </div>
      )}
      {totalBulletins == 0 && (
        <div className="bg-white h-100 d-flex flex-row align-items-center justify-content-center rounded">
          <p>Không có bản tin</p>
        </div>
      )}
    </>
  );
}
