import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import categoryApi from "api/categoryApi";
import Global from "general/Global";

const thuckGetCategories = createAsyncThunk(
    'category/get',
    async (params) => {
        return await categoryApi.getCategory(params);
    }
);

const categorySlice = createSlice({
    name: "category",
    initialState: {
        loadingState: false,
        q: "",
        sortType: "asc",
        orderBy: "name",
        orderType: "asc",
        categories: [],
        total: 0,
        limit: Global.gDefaultPagination,
        page: 0,
        loadingState: true,
    },
    reducers: {
        setSortType(state, { payload }) {
            state.sortType = payload;
        },
        setPage(state, { payload }) {
            state.page = payload;
        },
        setStep(state, { payload }) {
            state.limit = payload;
        },
        setOrderBy(state, { payload }) {
            state.orderBy = payload;
        },
        setOrderType(state, { payload }) {
            state.orderType = payload;
        },
        check(state, { payload }) {
            state.categories = state.categories.map(item => ({
                ...item,
                isCheck: item.id == payload ? true : item.isCheck
            }));
        },
        unCheck(state, { payload }) {
            state.categories = state.categories.map(item => ({
                ...item,
                isCheck: item.id == payload ? false : item.isCheck
            }));
        },
        checkAll(state) {
            state.categories = state.categories.map(item => ({
                ...item,
                isCheck: true
            }))
        },
        unCheckAll(state) {
            state.categories = state.categories.map(item => ({
                ...item,
                isCheck: false
            }))
        },
        setSearchText(state, action) {
            state.q = action.payload;
        }
    },
    extraReducers: {
        [thuckGetCategories.pending]: (state, action) =>{
            state.loadingState = true;
        },
        [thuckGetCategories.rejected]: (state, action) =>{
            state.loadingState = false;
        },
        [thuckGetCategories.fulfilled]: (state, action) => {
            state.loadingState = false;
            if (action.payload.data.result == "success") {
                state.categories = action.payload.data.categories.map(item => ({
                    ...item,
                    isCheck: false
                }));
                state.total = action.payload.data.total;
            }
        },
        [thuckGetCategories.pending]: (state, action) => {
            state.loadingState = true;
        },
        [thuckGetCategories.rejected]: (state, action) => {
            state.loadingState = false;
        },
    }
});

const { actions, reducer } = categorySlice;
const {
    setSortType,
    setPage,
    setStep,
    check,
    unCheck,
    checkAll,
    unCheckAll,
    setOrderType,
    setOrderBy,
    setSearchText
} = actions;

export default reducer;

export {
    thuckGetCategories,
    setSortType,
    setPage,
    setStep,
    check,
    unCheck,
    checkAll,
    unCheckAll,
    setOrderType,
    setOrderBy,
    setSearchText
}
