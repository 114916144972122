import React, {useEffect, useRef, useState} from 'react';
import {formatDuration} from "../../../../general/utils/Utils";
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import _ from "lodash";

export default function AudioPlayer({url}) {
  let audioRef = useRef();
  // const [currentTime, setCurrentTime] = useState(0);
  // const [totalTime, setTotalTime] = useState(0);

  // useEffect(() => {
  //   if (audioRef.current) {
  //     audioRef.current.onloadedmetadata = function () {
  //       setTotalTime(audioRef.current.duration);
  //     };
  //     audioRef.current.ontimeupdate = function(){
  //       setTimeout(()=>{
  //         setCurrentTime(audioRef.current.currentTime);
  //       }, 1000);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
      // setCurrentTime(0);
      if (url) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [url]);

  // useEffect(() => {
  //   if (audioRef.current) {
  //     audioRef.current.currentTime = currentTime;
  //   }
  // }, [currentTime]);


  return <div className="d-flex align-items-center">
    <audio ref={audioRef} controls className="w-100">
      <source src={url}></source>
    </audio>
    {/*<span className="text-nowrap" style={{minWidth: 40}}>{formatDuration(currentTime)}</span>*/}
    {/*<Slider*/}
    {/*  step={1}*/}
    {/*  railStyle={{background: "rgba(158, 165, 189,0.4)"}}*/}
    {/*  trackStyle={{background: "#5180FB"}}*/}
    {/*  // handleRender={Handler}*/}
    {/*  min={0}*/}
    {/*  max={totalTime}*/}
    {/*  value={currentTime}*/}
    {/*  onChange={(nextValues) => {*/}
    {/*    setCurrentTime(nextValues);*/}
    {/*  }}*/}
    {/*/>*/}
    {/*<span className="text-nowrap" style={{minWidth: 40}}>{formatDuration(totalTime)}</span>*/}
  </div>
}

const Handler = () => <div
  style={{width: 16, height: 16, border: "1px solid #5180FB"}}
  className="bg-white d-flex justify-content-center align-items-center"
>
  <i className="fal fa-pause"/>
</div>
