import React from 'react';
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError';
import "./CheckBox.scss";

const CheckBox = (props) => {
    const { label, name, disabled, className, ...rest } = props;
    return (
        <div className={`login-form-control login-checkbox d-flex align-items-center ${className}`} style={disabled == true ? {pointerEvents:"none"} : null }>
            <Field type='checkbox' id={name} name={name} disabled={disabled} {...rest} />
            <label className='ml-3 mb-0 font-weight-bold' htmlFor={name} style={{fontSize:"13px", color: disabled ? "#9EA5BD" : "#4A5677"}}>{label}</label>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default CheckBox