import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter} from "reactstrap";
import styled from "styled-components";
import {BULLETIN_WEEK_DAYS} from "../../../../general/constants/Types";

const PrimaryButton = styled.button`
  background-color: #5180FB !important;
  padding: 12px 10px;
  border: 1px solid #3465E6 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-weight: 600;
  line-height: 16px;
  color: #FFFFFF;

  i {
    color: white;
    margin-right: 8px;
  }
`;

const WeekdayPickerDiv = styled.div`
  padding: 12px 10px;
  color: #5180FB;
  user-select: none;
  cursor: pointer;

  i {
    margin-right: 4px;
  }
`
export default function WeekdayPicker({show, disableAll, className, weekDays = [], onWeekdayChange}) {
  // input label
  const [selectedDateStr, setSelectedDateStr] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    // set input label
    setSelectedDateStr(weekDays.length ? BULLETIN_WEEK_DAYS.reduce(function (selectedWeekDay, weekDay) {
      if (weekDays.includes(weekDay.value)) {
        selectedWeekDay.push(weekDay.title);
      }
      return selectedWeekDay;
    }, []).join(',') : "Ngày trong tuần");
    setSelectedDates(weekDays);
  }, [weekDays]);

  const toggleModal = () => {
    if (disableAll) return;
    if (showModal) {
      setSelectedDates(weekDays);
    }
    setShowModal(!showModal);
  }

  return <>
    <WeekdayPickerDiv
      className={`form-control text-truncate align-items-center ${className} ${show ? 'd-flex' : 'd-none'}`}
      title={selectedDateStr}
      onClick={toggleModal}
      style={{
        background: disableAll ? '#F3F6F9' : 'white',
        cursor: disableAll ? 'default' : 'pointer',
      }}
    >
      <i className="far fa-calendar-day text-reset"/>
      <span>{selectedDateStr}</span>
    </WeekdayPickerDiv>
    <Modal
      style={{maxWidth: 350}}
      centered={true}
      isOpen={showModal}
      toggle={toggleModal}
    >
      <ModalBody className="p-0">
        <div className="text-center font-weight-boldest border-bottom" style={{padding: 20}}>
          Chọn Ngày Trong Tuần
        </div>
        <div className="d-flex flex-wrap" style={{padding: "0 20px"}}>
          {BULLETIN_WEEK_DAYS.map(({value, title}) => {
            let activeIndex = selectedDates.indexOf(value),
              active = activeIndex !== -1;
            return <div
              key={value}
              className="w-50"
              style={{
                padding: "10px 8px 10px 0"
              }}>
              <label className="custom-checkbox">
                <input
                  type='checkbox'
                  value={value}
                  checked={active}
                  onChange={() => {
                    let currentSelectedDays = [...selectedDates]
                    if (active) {
                      currentSelectedDays.splice(activeIndex, 1)
                    } else {
                      currentSelectedDays.push(value);
                    }
                    setSelectedDates(currentSelectedDays)
                  }}
                />
                <span className="checkmark" style={{top: -2}}></span>
                <span style={{
                  marginLeft: 8,
                }}>{title}</span>
              </label>
            </div>
          })}
        </div>
      </ModalBody>
      <ModalFooter style={{padding: "10px 20px"}}>
        <div className="d-flex m-0 w-100">

          <div style={{paddingLeft: 0, paddingRight: 8}} className="col">
            <button
              onClick={toggleModal}
              style={{height: 40}}
              className="btn btn-secondary border w-100"
            >
              Hủy
            </button>
          </div>

          <div style={{paddingLeft: 8, paddingRight: 0}} className="col">
            <PrimaryButton
              onClick={() => {
                let currentSelectedDays = [...selectedDates].sort((a, b) => a - b);
                // swap vi tri chu nhat xuong cuoi
                if (currentSelectedDays.length > 1 && currentSelectedDays?.[0] === 1) {
                  currentSelectedDays.push(currentSelectedDays.shift());
                }
                onWeekdayChange(currentSelectedDays);
                toggleModal();
              }}
              style={{height: 40}}
              className={"w-100"}>
              Áp Dụng
            </PrimaryButton>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  </>
}
