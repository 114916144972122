import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import feedbackApi from "api/feedbackApi";
import Global from "general/Global";

const thunkGetFeedback = createAsyncThunk(
    "feedback/get",
    async (params) => {
        return await feedbackApi.getFeedback(params);
    }
)

const feedbackSlice = createSlice({
    name: 'feedbacks',
    initialState: {
        loadingState: true,
        total: 0,
        feedbacks: [],
        orderBy: "id",
        orderType: "asc",
        status: "all",
        page: 0,
        limit: Global.gDefaultPagination
    },
    reducers: {
        setStatus(state, { payload }) {
            state.status = payload;
        },
        setPage(state, { payload }) {
            state.page = payload;
        },
        setLimit(state, { payload }) {
            state.limit = payload;
        },
        setOrderBy(state, { payload }) {
            state.orderBy = payload;
        },
        setOrderType(state, { payload }) {
            state.orderType = payload;
        },
    },
    extraReducers: {
        [thunkGetFeedback.pending]: (state, action) => {
            state.loadingState = true;
        },
        [thunkGetFeedback.rejected]: (state, action) => {
            state.loadingState = false;
        },
        [thunkGetFeedback.fulfilled]: (state, action) => {
            state.loadingState = false;
            if (action.payload.data.result == "success") {
                state.feedbacks = action.payload.data.feedbacks;
                state.total = action.payload.data.total;
            }
        },
    }
})

const { actions, reducer } = feedbackSlice;

const {
    setStatus,
    setSearchValue,
    setPage,
    setLimit,
    setOrderBy,
    setOrderType
} = actions;

export default reducer;

export {
    thunkGetFeedback,
    setStatus,
    setSearchValue,
    setPage,
    setLimit,
    setOrderBy,
    setOrderType
}
