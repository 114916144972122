import React, { useRef, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { FieldHelperProps, FieldMetaProps, FieldInputProps } from "formik";
import "./SelectProviderDropdown.scss";

/**
 * @template S
 * @param {{fieldProps:FieldInputProps,fieldHelpers:FieldHelperProps,fieldMeta:FieldMetaProps,name:string,label:string
 * ,text:string,options:S[],onItemClick:(any:S)=>void,renderDropdownItem:(any:S)=>React.ComponentElement
 * type:React.HTMLInputTypeAttribute}} props
 */
export default function SelectProviderDropdown(props) {
  // MARK: --- Params ---
  const {
    className,
    fieldProps,
    fieldMeta,
    fieldHelpers,
    name,
    label,
    options,
    onItemClick,
    renderDropdownItem,
    renderValue,
  } = props;
  const value = fieldProps.value;
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // MARK: --- Functions ---

  return (
    <div className={`${className}`}>
      {label && (
        <label className="" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="d-flex align-items-center">
        <Dropdown
          isOpen={dropdownVisible}
          toggle={() => {
            setDropdownVisible(!dropdownVisible);
          }}
          className="flex-grow-1 d-flex"
        >
          <DropdownToggle
            caret={false}
            className={`p-0 btn btn-white flex-grow-1 ${
              dropdownVisible ? "border-primary" : "border"
            }`}
          >
            <div
              className={`w-100 rounded bg-white Regular_13 d-flex justify-content-between align-items-center`}
              style={{
                height: "calc(1.5em + 1.3rem + 2px)",
                padding: "0.65rem 1rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                backgroundClip: "padding-box",
              }}
              id={name}
            >
              <div>{renderValue ? renderValue(value) : value}</div>
              <i className="far fa-angle-down pr-1 opacity-40"></i>
            </div>
          </DropdownToggle>
          <DropdownMenu
            className="w-100 px-0 py-1 m-0 q-hide-scrollbar"
            style={{ overflowY: "scroll", maxHeight: "300px" }}
          >
            {options.map((item, index) => {
              return (
                <DropdownItem
                  key={index}
                  className="p-0"
                  toggle={false}
                  onClick={(e) => {
                    e.preventDefault();
                    _.isFunction(onItemClick) && onItemClick(item);
                    setDropdownVisible(false);
                  }}
                >
                  {renderDropdownItem(item)}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
}
