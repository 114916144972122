import { AppIcons } from "general/constants/AppResource";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "../../components/loginForm/LoginForm";
import "./styles.scss";
function LoginScreen(props) {
  const { t, i18n } = useTranslation();
  const [showOptionModal, setShowOptionModal] = useState(false);
  return (
    <div className="login-page">
      <div
        className="container h-100 d-flex flex-column"
        style={{ minHeight: "100vh" }}
      >
        <header style={{}}>
          <div className="login-header-wrapper">
            <div className="logo">
              <img src={AppIcons.icLogoLogin} alt="logo" />
            </div>
            <div className="login-title">{t("loginTitle")}</div>
          </div>
        </header>
        <main style={{}}>
          <div className="login-form">
            <LoginForm redirectLogin={props.location?.state?.from} />
            <div className="login-form-divider" />
            {/* <button
              className="login-config-button"
              onClick={() => setShowOptionModal(true)}
            >
              {t("option")}
            </button>
            <div className="login-config-desc">{t("changeServer")}</div> */}
            {/* <SettingModal
              showOptionModal={showOptionModal}
              onToggle={(toggle) => {
                setShowOptionModal(toggle);
              }}
            /> */}
          </div>
          <div className="login-sound">
            {/*<img src={imgLoginSound} alt="sound" />*/}
          </div>
        </main>
      </div>
    </div>
  );
}

export default LoginScreen;
