import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { useTranslation } from "react-i18next";
import Empty from "../Empty";
import { AppIcons, AppImages } from "general/constants/AppResource";
import Loading from "components/Loading";
import { propTypes } from "react-bootstrap/esm/Image";

DataTable.propTypes = {
  cols: PropTypes.array,
  headItems: PropTypes.array,
  dataItems: PropTypes.array,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onRefresh: PropTypes.func,
};

DataTable.defaultProps = {
  cols: [],
  headItems: [],
  dataItems: [],
  isLoading: false,
  className: "bg-light",
};

/**
 * - className: `string`
 * - cols = `float`[ ] : width of each col in percent
 * - headItems = `component` [ ]
 * - dataItems = {
 * - ____ isRowCheck: `bool`,
 * - ____ rowClick: `function`,
 * - ____ data: {
 * - ____________ comp: `component`
 * - ____________ col: `string`
 * - ________ } [ ]
 * - } [ ]
 * - isLoading = `bool` show loading circle
 */
function DataTable(props) {
  // MARK: --- Params ---
  const { headItems, dataItems, className, cols, step, onRefresh, isLoading } =
    props;
  const { t } = useTranslation();

  return (
    <div>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center py-10 bg-white">
          <Loading message={`${t("Loading")}...`} />
        </div>
      ) : dataItems.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center py-10 bg-white">
          <Empty
            iconEmpty={AppIcons.icEmptyPack}
            description={t("NoDataToDisplay")}
            onClickButton={onRefresh}
          />
        </div>
      ) : (
        <div
          className={`DataTable d-block overflow-auto ${className}`}
          style={{
            minHeight:
              step > dataItems.length
                ? `${dataItems.length * 46.5 + 46.5}px`
                : `${step * 46.5 + 46.5}px`,
          }}
        >
          <table className="table bg-white m-0">
            <colgroup>
              {cols?.map((item, index) => {
                return (
                  <col
                    key={index}
                    style={{
                      width: `${item}%`,
                    }}
                  />
                );
              })}
            </colgroup>

            <thead>
              <tr>
                {headItems.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="border-bottom"
                      style={{ verticalAlign: "middle" }}
                    >
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {dataItems.length > 0 &&
                dataItems.map((item, index) => {
                  return (
                    <tr
                      key={Math.random()}
                      className={item.rowClick && (item.isRowCheck == true ? "" : "row-hover")}
                      style={{
                        backgroundColor: item.isRowCheck
                          ? item.isRowCheck == true
                            ? "#DBE3EF"
                            : index % 2
                            ? ""
                            : "#F6F7FB"
                          : index % 2
                          ? ""
                          : "#F6F7FB",
                        cursor: item.rowClick ? "pointer" : "",
                      }}
                      // onClick={item.rowClick}
                    >
                      {item.data.map((subItem, subIndex) => {
                        const { comp, onClick } = subItem;
                        return (
                          <td
                            key={subIndex}
                            style={{ verticalAlign: "middle" }}
                            onClick={onClick ? onClick : null}
                          >
                            {comp}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default DataTable;
