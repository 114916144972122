import { AppIcons } from "general/constants/AppResource";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoginForm from "../../components/loginForm/LoginForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import "./styles.scss";

function ResetPassword(props) {
  const { t, i18n } = useTranslation();
  const [showOptionModal, setShowOptionModal] = useState(false);
  let {resetToken} = useParams();
  return (
    <div className="login-page">
      <div
        className="container h-100 d-flex flex-column"
        style={{ minHeight: "100vh" }}
      >
        <header style={{}}>
          <div className="login-header-wrapper">
            <div className="logo">
              <img src={AppIcons.icLogoLogin} alt="logo" />
            </div>
            <div className="login-title">{t("loginTitle")}</div>
          </div>
        </header>
        <main style={{}}>
          <div className="login-form">
            <ResetPasswordForm redirectLogin={props.location?.state?.from} resetToken={resetToken}/>                   
          </div>     
        </main>
      </div>
    </div>
  );
}

export default ResetPassword;
