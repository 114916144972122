import PreferenceKeys from "general/constants/PreferenceKeys";
import Global from "general/Global";
import UserHelper from "general/helpers/UserHelper";
import Utils from "general/utils/Utils";
import store from "libs/storage/store";
import React from "react";
import { Redirect, Route } from "react-router-dom";

// Route can dang nhap
function TwoFactorAuthRoute(props) {
  const isAuth = !Utils.isObjectEmpty(localStorage.getItem(PreferenceKeys.refreshToken));
  const isTwofaAccessTokenAprroved = !Utils.isObjectEmpty(localStorage.getItem(PreferenceKeys.twofaAccessToken));
  // debugger
  if(isAuth){
    return <Redirect to='/dashboard' />
  }
  if(isTwofaAccessTokenAprroved){
    return <Route {...props} />
  }
  else{
     return <Redirect to='/auth/sign-in' />
  }
}
export default TwoFactorAuthRoute;
