import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import LoginScreen from "./screens/SignIn";
import TwoFactorAuthScreen from "./screens/TwoFactorAuth";
import GuestRoute from "routes/GuestRoute";
import PrivateRoute from "routes/PrivateRoute";
import TwoFactorAuthRoute from "routes/TwoFactorAuthRoute";
import ResetPassword from "./screens/ResetPassword";
import ForgotPassword from "./screens/ForgotPassword";

function AuthPage(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <GuestRoute exact path={`${match.url}/sign-in`} component={LoginScreen} />
      <GuestRoute exact path={`${match.url}/forgot-password`} component={ForgotPassword} />
      {/* <GuestRoute exact path={`${match.url}/reset-password`} component={ResetPassword} /> */}
      <TwoFactorAuthRoute
        exact
        path={`${match.url}/2fa-verify`}
        component={TwoFactorAuthScreen}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default AuthPage;
