import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading";
import SearchBarNoFormik from "../SearchBarNoFormik";

import "./styles.scss";
import { AppIcons } from "general/constants/AppResource";

CardDetailHaveButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  clickAdd: PropTypes.func,
  additionalHeader: PropTypes.element,
  isLoading: PropTypes.bool,
  buttonTitle: PropTypes.string,
};

CardDetailHaveButton.defaultProps = {
  className: "",
  title: "",
  clickAdd: null,
  isLoading: false,
  buttonTitle: "",
  // twoFactor : "",
};

/**
 * - className (string)
 * - title (string)
 * - headerRight (element) : component after search and button
 * - buttonText (string) default: 'Add'. If empty wont show button
 * - additionalHeader (element): component below header
 *
 */
function CardDetailHaveButton(props) {
  const { className, title, clickAdd, isLoading, twoFactor } = props;
  const { t } = useTranslation();

  function onClick() {
    if (clickAdd) clickAdd();
  }

  return (
    <div
      className="d-flex flex-column border mt-6"
      // style={{ zIndex: "5" }}
    >
      {/* CARD HEADER  */}
      <div className="CardHeader bg-white d-flex align-items-center px-6">
        <p className="CardTitle Bold_13 py-5 m-0">
          {title}
        </p>
      </div>

      {/* Card body */}
      <div className="bg-light p-3 border border-left-0 border-right-0">
        <div className="bg-white w-100 d-flex align-items-center justify-content-center py-5 border">
          {props.children}
        </div>
      </div>
      <div className="bg-light p-3">
        <div
          className="btn w-100 d-flex align-items-center justify-content-center border "
          onMouseDown={isLoading === false ? props.onClick : null}
          style={
            isLoading === true
              ? { backgroundColor: "grey" }
              : twoFactor
              ? { backgroundColor: "#F64E60" }
              : { backgroundColor: "#5180FB" }
          }
        >
          {isLoading === false ? null : (
            <span className="spinner-border spinner-border-sm text-light mr-2"></span>
          )}
          <span
            className="font-weight-bolder font-size-base line-height-lg"
            style={{ color: "white" }}
          >
            {props.buttonTitle}
          </span>
        </div>
        {/* {props.button} */}
      </div>
    </div>
  );
}

export default CardDetailHaveButton;
