import axiosClient from "./axiosClient";
import qs from "qs";

const prefix = "bulletin-board";
const bulletinBoardApi = {
  getBulletinBoard: (areaId, params) => {
    params.areaId = areaId;
    const url = `/bulletin-board/devices`;
    return axiosClient.get(url, {params});
  },

  getBulletinBoardById(id) {
    const url = `bulletin-board/device/${id}`;
    return axiosClient.get(url);
  },

  updateBulletinBoard: (params) => {
    const url = `/bulletin-board/device/${params.id}`;
    return axiosClient.put(url, params);
  },

  deleteBulletinBoard: (params) => {
    const url = `/bulletin-board/devices`;
    return axiosClient.delete(url, {data: params});
  },

  // editBulletinBoard: (params) => {
  //   const url = `/bulletin-board/device/${params.ids}`;
  //   // debugger
  //   return axiosClient.put(url, params);
  // },

  createBulletin(data) {
    const url = prefix + `/`;
    return axiosClient.post(url, data);
  },

  getBulletins(areaId, params) {
    return axiosClient.get(prefix, {
      params: {
        areaId,
        ...params
      }
    });
  },
  getEBulletinData(id) {
    const url = prefix + `/${id}`;
    return axiosClient.get(url);
  },

  approve(bulletinId, approve, reason = null) {
    const url = prefix + `/approve`;
    return axiosClient.put(url, {
      bulletinId,
      approve,
      reason,
    });
  },

  deleteBulletins(bulletinIds) {
    return axiosClient.delete(prefix, {
      data: {
        bulletins: bulletinIds,
      }
    });
  },

  stopBulletins(bulletinIds) {
    const url = prefix + `/stop`;
    return axiosClient.put(url, {
      bulletins: bulletinIds,
    });
  },

  stopPlayingBulletins(bulletinIds) {
    const url = prefix + `/stop-playing`;
    return axiosClient.put(url, {
      historyIds: bulletinIds,
    });
  },

  deleteHistoryBulletins(bulletinHistoryIds) {
    const url = prefix + `/histories`;
    return axiosClient.delete(url, {
      data: {
        historyIds: bulletinHistoryIds,
      }
    });
  },

  getPlayingBulletins(areaId, params) {
    const url = prefix + `/histories`;
    return axiosClient.get(url, {
      params: {
        statuses: ["PLAYING"],
        areaId,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    });
  },

  getPlayedBulletins(areaId, params) {
    const url = prefix + `/histories`;
    return axiosClient.get(url, {
      params: {
        statuses: ["PLAYED", "STOPPED"],
        areaId,
        ...params
      },
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    });
  },
};

export default bulletinBoardApi;
