import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import Global from "general/Global";

import useSort from "hooks/useSort";

import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";

import "./index.scss";

import {thunkGetAreas} from "pages/AreaManager/areaSlice";
import {useHistory} from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import ConfirmModal from "../../pages/BulletinManager/components/ConfirmModal";
import trashIcon from "../../assets/images/trash-round-bg.svg";
import moment from "moment";
import _ from "lodash";
import {BULLETIN_WEEK_DAYS} from "../../general/constants/Types";
import bulletinApi from "../../api/bulletinApi";
import bulletinBoardApi from "../../api/bulletinBoardApi";

/**
 * Props:
 * * getEBulletinsAPI (`func`): hàm nhận query-string từ bên trong để gọi api
 * * searchText (`string`): string to search on
 *
 * Example:
 * ```js
 * import stationApi from '...';
 *
 * <DevicesTable
 getEBulletinsAPI={(params) => stationApi.getStationDevices(station.id, params)}
 searchText={searchText}
 />
 * ```
 */
EBulletinTable.propTypes = {
  selectedItems: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
};

EBulletinTable.defaultProps = {
  selectedItems: [],
  onSelectedItemsChange: () => {
  },
};

function EBulletinTable(props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    getEBulletinsAPI,
    searchText,
    currentAreaId,
    tableName,
    selectedItems: selectedBulletins,
    onSelectedItemsChange: setSelectedBulletins,
    isBoardBulletin = false,
  } = props;
  const history = useHistory();
  const confirmModalRef = useRef();
  const areas = useSelector((state) => state.area.allAreas);

  const [isLoading, setIsLoading] = useState(true);
  const [bulletinTotal, setBulletinTotal] = useState(0);
  const [bulletins, setBulletins] = useState([]);
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);

  const {directionIndicator, sortOnClick} = useSort(
    bulletins,
    (sortedArray) => setBulletins(sortedArray)
  );

  // MARK: --- Functions ---

  async function getBulletins() {
    setIsLoading(true);
    const res = await getEBulletinsAPI({
      page: page - 1,
      limit: step,
      q: searchText,
      // orderField: "connection",
      // orderMode: "DESC",
    });
    if (res && res.data.result === "success") {
      setBulletins(res.data.eBulletins);
      setBulletinTotal(res.data.total);
      if (res.data.eBulletins.length === 0 && page > 1) {
        setPage(page - 1);
      }
    } else {
      setBulletins([]);
      setBulletinTotal(0);
    }
    setIsLoading(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (areas.length === 0) {
      dispatch(thunkGetAreas());
    }
  }, []);

  useEffect(() => {
    getBulletins();
  }, [searchText, page, step, currentAreaId, tableName]);

  function handleSetting(bulletinId) {
    if (isBoardBulletin) return history.push(`/bulletins/edit/bulletin-board/${bulletinId}`);
    else history.push(`/bulletins/edit/speaker/${bulletinId}`);
  }

  async function handleDelete(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Xóa bản tin`,
        <div style={{padding: 16}} className="text-center">
          <img src={trashIcon} style={{marginBottom: 20}}/>
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn xóa bản tin
                <span className="text-danger">&nbsp;{bulletin.title}</span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn xoá {selectedBulletins.length} bản tin đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let deleteCall = isBoardBulletin ? bulletinBoardApi.deleteBulletins : bulletinApi.deleteBulletins
      await deleteCall(batchDelete ? selectedBulletins : [bulletin.id])
        .then((data) => {
          if (data.data.status === 0) {
            ToastHelper.showSuccess("Xoá bản tin thành công");
            getBulletins();
            if (batchDelete) {
              setSelectedBulletins([]);
            }
          }
        });
    } catch (e) {
    }
  }

  async function handleStop(bulletin) {
    if (!confirmModalRef.current) return;
    if (selectedBulletins.length === 0 && !bulletin) return;
    let batchDelete = !!selectedBulletins.length;
    try {
      await confirmModalRef.current.show(
        `Dừng bản tin`,
        <div style={{padding: 16}} className="text-center">
          <img src={trashIcon} style={{marginBottom: 20}}/>
          <p className="m-0">
            {!batchDelete ? (
              <>
                Bạn chắc chắn muốn dừng bản tin
                <span className="text-danger">&nbsp;{bulletin.title}</span>
              </>
            ) : (
              <>
                Bạn chắc chắn muốn dừng {selectedBulletins.length} bản tin đã
                chọn?
              </>
            )}
          </p>
        </div>
      );

      let stopCall = isBoardBulletin ? bulletinBoardApi.stopBulletins : bulletinApi.stopBulletins
      await stopCall(batchDelete ? selectedBulletins : [bulletin.id])
        .then((data) => {
          if (data.data.status === 0) {
            ToastHelper.showSuccess("Dừng bản tin thành công");
            getBulletins();
            if (batchDelete) {
              setSelectedBulletins([]);
            }
          }
        });
    } catch (e) {
    }
  }

  return (
    <>
      <DataTable
        className="bulletin-datatable"
        cols={[0, 40, 15, 15, 15, 15, 0, 0]}
        headItems={[
          // Name
          <CheckBox
            checked={selectedBulletins.length == bulletins.length}
            onCheck={() => {
              // setSelectedBulletins(_.unionBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.union(selectedBulletins, _.map(bulletins, "id"))
              );
            }}
            onUncheck={() => {
              // setSelectedBulletins(_.differenceBy(selectedBulletins, bulletins, 'id'));
              setSelectedBulletins(
                _.difference(selectedBulletins, _.map(bulletins, "id"))
              );
            }}
          />,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("title")}
          >
            <p>
              Tin
              {directionIndicator("title")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("startDate")}
          >
            <p>
              Lịch Phát
              {directionIndicator("startDate")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("mode")}
          >
            <p>
              Kiểu Phát
              {directionIndicator("mode")}
            </p>
          </div>,

          // Volume
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("creator")}
          >
            <p>
              Tác Giả
              {directionIndicator("creator")}
            </p>
          </div>,

          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("areaId")}
          >
            <p>
              Khu Vực
              {directionIndicator("areaId")}
            </p>
          </div>,

          // Area
          <div className="w-100" onClick={() => sortOnClick("status")}>
            <p>
              TRẠNG THÁI
              {directionIndicator("status")}
            </p>
          </div>,
          t("Manage"),
        ]}
        // row
        dataItems={bulletins.map((item) => {
          let startDate = moment(item.startDate),
            endDate = item.endDate ? moment(item.endDate) : null;
          let isRowCheck = selectedBulletins.includes(item.id);
          return {
            isRowCheck: isRowCheck,
            rowClick: () => {},
            data: [
              {
                comp: (
                  <CheckBox
                    checked={isRowCheck}
                    onCheck={() =>
                      setSelectedBulletins([...selectedBulletins, item.id])
                    }
                    onUncheck={() => {
                      let currentSelectedBulletins = [...selectedBulletins];
                      let itemIndex = currentSelectedBulletins.indexOf(item.id);
                      if (itemIndex !== -1) {
                        currentSelectedBulletins.splice(itemIndex, 1);
                        setSelectedBulletins(currentSelectedBulletins);
                      }
                    }}
                  />
                ),
              },
              {
                comp: (
                  <div
                    key={item.id}
                    className=""
                  >
                    <div
                      className="Bold_13 text-primary text-emphasis q-max-line-1"
                      title={item.title}
                      style={{marginBottom: 4}}
                    >
                      {item.title}
                    </div>
                    <div className="Regular_13 q-max-line-3">
                      {item.description}
                    </div>
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.id);
                },
              },
              {
                comp: (
                  <div>
                    <div className="Regular_13 text-nowrap">
                      Từ&nbsp;
                      <span style={{color: "#3ACE5A"}}>
                        {startDate.format("DD/MM/YYYY")}
                      </span>
                    </div>
                    {!!endDate && (
                      <div className="text-nowrap">
                        Đến&nbsp;
                        <span className="text-danger">
                          {endDate.format("DD/MM/YYYY")}
                        </span>
                      </div>
                    )}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.id);
                },
              },
              {
                comp: (
                  <div className="Regular_13">
                    {item.mode === "NOW" && "Phát Ngay"}
                    {item.mode === "EMERGENCY" && "Phát Khẩn Cấp"}
                    {item.mode === "FIX" && "Hàng Ngày"}
                    {item.mode === "SCHEDULE" && (
                      <div className="Regular_13 text-nowrap">
                        Lặp lại vào
                        <div style={{color: "#3ACE5A"}}>
                          {item.repeatType === "Week" &&
                            BULLETIN_WEEK_DAYS.reduce(function (
                                selectedWeekDay,
                                weekDay,
                                index
                              ) {
                                if (item.playingDay.includes(weekDay.value)) {
                                  if (weekDay.value === 1) {
                                    //sunday
                                    selectedWeekDay.push(weekDay.title);
                                  } else if (selectedWeekDay.length === 0) {
                                    // "Thứ"
                                    selectedWeekDay.push(weekDay.title);
                                  } else {
                                    selectedWeekDay.push(weekDay.value);
                                  }
                                }
                                return selectedWeekDay;
                              },
                              []).join(", ")}
                          {item.repeatType === "Month" &&
                            item.playingDay.join(", ")}
                        </div>
                      </div>
                    )}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.id);
                },
              },
              {
                comp: <p className="Regular_13 text-nowrap">{item.creator}</p>,
              },
              {
                comp: (
                  <p className="Regular_13 text-nowrap">{item["area.name"] || item?.area?.name}</p>
                ),
              },
              {
                comp: !!item.status && (
                  <div
                    className={`bulletin-status-badge bulletin-status-badge-${item.status.toLowerCase()}`}
                  >
                    {t(item.status)}
                  </div>
                ),
                onClick: () => {
                  handleSetting(item.id);
                },
              },
              {
                comp: (
                  <div className="Manage" style={{width: 80}}>
                    <i
                      className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                      onClick={() => {
                        handleSetting(item.id);
                      }}
                    ></i>
                    <i
                      className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                      onClick={() => handleDelete(item)}
                    ></i>
                    {item.status === "APPROVED" && (
                      <i
                        className="fas fa-stop-circle cursor-pointer text-danger"
                        onClick={() => handleStop(item)}
                      ></i>
                    )}
                  </div>
                ),
              },
            ],
          };
        })}
        step={step}
        onRefresh={() => getBulletins()}
        isLoading={isLoading}
      />

      <AppPagination
        className="bg-white rounded-bottom"
        total={bulletinTotal}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
      <ConfirmModal
        ref={confirmModalRef}
        confirmButtonClass={"btn btn-danger"}
        confirmButtonText={"Xác Nhận"}
      />
    </>
  );
}

export default EBulletinTable;
