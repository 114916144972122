import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const IdleTimeOutModal = ({
  showModal,
  handleContinue,
  handleLogout,
  remainingTime,
}) => {
  return (
    <Modal show={showModal} onHide={handleContinue}>
      <Modal.Header className="d-flex justify-content-between">
        <Modal.Title>
          <p className="Bold_13">Phiên hết hạn</p>
        </Modal.Title>
        <i 
          className="fas fa-times cursor-pointer text-dark"
          onClick={handleContinue}
        />
      </Modal.Header>
      <Modal.Body>
        <p className="Regular_13 text-capitalize">Phiên làm việc hiện tại đã hết hạn!</p>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="danger" onClick={handleLogout}>
                Logout
            </Button> */}
        <Button variant="primary" onClick={handleContinue}>
          Tiếp tục
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
