import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import GuestRoute from "routes/GuestRoute";
import PrivateRoute from "routes/PrivateRoute";
import AccountOverview from "./screens/AccountOverview";
import AccountManagerHomeScreen from './screens/AccountManagerHomeScreen'
import EditAccount from "./screens/EditAccount";


function AccountManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={match.url} component={AccountManagerHomeScreen} />
      <PrivateRoute exact path={`${match.url}/account-overview`} component={AccountOverview} />
      <PrivateRoute exact path={`${match.url}/:id`} component={EditAccount} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default AccountManager;
