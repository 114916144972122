import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import BulletinCreateScreen from "./screens/BulletinCreate";
import BulletinManagerScreen from "./screens/BulletinManager";
import BulletinScheduleScreen from "./screens/BulletinSchedule";
import RadioHistoryScreen from "./screens/BulletinHistory/radioBulletinHistory";
import eBulletinHistoryScreen from "./screens/BulletinHistory/eBulletinHistory";
import BulletinBoardScreen from "./screens/BulletinBoard";

function BulletinManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={BulletinManagerScreen} />
      <Route
        exact
        path={`${match.url}/edit/speaker`}
        component={BulletinCreateScreen}
      />

      <Route
        exact
        path={`${match.url}/edit/bulletin-board`}
        component={BulletinBoardScreen}
      />
      <Route
        exact
        path={`${match.url}/schedule`}
        component={BulletinScheduleScreen}
      />
      <Route
        exact
        path={`${match.url}/history/radio`}
        component={eBulletinHistoryScreen}
      />
      <Route
        exact
        path={`${match.url}/history/bulletin-board`}
        component={RadioHistoryScreen}
      />
      <Route
        exact
        path={`${match.url}/edit/speaker/:eBulletinId`}
        render={(props) => (
          <BulletinCreateScreen
            key={props.match.params.eBulletinId}
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.url}/edit/bulletin-board/:eBulletinId`}
        render={(props) => (
          <BulletinBoardScreen
            key={props.match.params.eBulletinId}
            {...props}
          />
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
}

export default BulletinManager;
